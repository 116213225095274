import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface SwapDeploymentManagerInput {
  oldDeploymentManagerId: string;
  newDeploymentManagerId: string;
}

export async function swapDeploymentManager(input: SwapDeploymentManagerInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation swapDeploymentManager (
        $input: SwapDeploymentManagerInput!
        ){
            swapDeploymentManager(
            swapDeploymentManagerInput: $input
          ) 
      }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
