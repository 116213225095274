import ReactApexChart from "react-apexcharts";
import React from "react";
import { ResponsiveContainer } from "recharts";

const IssueReportedChart = (props: any) => {
  const { issueReportedResult } = props;
  const data =issueReportedResult.map((e:any) =>(e.count) ) ?? []
   const name=issueReportedResult.map((e:any) =>(e.name) ) ?? []
 const  series = [{
    data: data
  }]
 const options: any = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        dataLabels: {
              position: 'bottom'
              },
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',

    },
    xaxis: {
      categories: name,
      labels: {
        style: {
          colors: 'var(--kt-dark)'
        }
      }

    },
    fill: {
      colors: ['#70C057','#F44336', '#E91E63', '#9C27B0' ,]
    },
    yaxis: {
      labels: {
        style: {
          colors: 'var(--kt-dark)'
        }
      }
    },
   stroke: {
        width: 1,
         colors: ['#fff']
       }
  
  }


  return (
    <>
      <div id="chart">
      <ResponsiveContainer 
            width={"100%"}
            height={400}
            className="text-center"
          >
        <ReactApexChart options={options}  series={series} type="bar" height={350} />
     </ResponsiveContainer>
      </div>
    </>
  );
};
export default IssueReportedChart;