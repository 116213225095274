import React from "react";
import { PieChart, Pie, Legend, Cell,Tooltip } from "recharts";
import { ResponsiveContainer } from "recharts";


const MarketingServicesChart = (props: any) => {
  const { marketingServicesResult } = props;
 
const data =Array.from(marketingServicesResult).map((e:any) => (
 { name:e.name,
  count:e.count}
))
const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor='middle'
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <div>
      <div className="row d-flex justify-content-center text-center">
          <ResponsiveContainer
            width={"100%"}
            height={400}
            className="text-center"
          >
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={110}
                fill="#8884d8"
                dataKey="count"
              >
                {data.map((entry: any, index: any) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              <Tooltip />
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                className="align-bottom"
              />
            </PieChart>
          </ResponsiveContainer>
          </div>
      </div>
    </>
  );
};
export default MarketingServicesChart;