import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface UpdateSettingInput {
  settingKey: string;
  settingValue: string;
}

export async function updateSetting(input: UpdateSettingInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateSetting (
          $input: UpdateSettingInput!
          ){
              updateSetting(
                updateSettingInput: $input
            ) {
                id
                settingKey
                settingValue
                createdById
                createdAt
                updatedById
                updatedAt
                createdBy {
                  id
                  firstName
                }
                updatedBy {
                  id
                  firstName
                }
            }
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getAllSettings() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
                query {
                    getAllSettings {
                        id
                        settingKey
                        settingValue
                        createdById
                        createdAt
                        updatedById
                        updatedAt
                        createdBy {
                          id
                          firstName
                        }
                        updatedBy {
                          id
                          firstName
                        }
                    }
                  }
                `,
        variables: {},
      },
    });
    return data?.data?.data?.getAllSettings;
  }
