import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { ErrorMessage, Formik } from 'formik';
import Select from 'react-select';
import Moment from 'moment';
import * as yup from 'yup';

const validationSchema = yup.object({
    machineServices: yup.array().min(1, "Machine services done is a required field"),
    // operations: yup.array().min(1, "Operations done is a required field"),
    // comment: yup.string().required("Comment is a required"),
    diagnosis: yup.array().min(1, "Diagnosis is a required field"),
    waterPressure: yup.string().required("Water pressure is a required"),
});
export interface IJobOrder {
    services_other: string;
    operation_other: string;
    diagnosis_other: string;
    operations: string[];
    machineServices: string[];
    replaceNewTubeDoors1mmShorter?: boolean;
    blenderId?: string;
    boardId?: string;
    board?: string;
    blender?: string;
    machineId?: string;
    machine?: string;
    travelingStartTime: string,
    travelingEndTime: string,
    workStartTime: string,
    comment: string;
    diagnosis: string[]
    waterPressure: string
    replaceFilter: false
    replaceSanitizer: false
    customerSatisfaction: string | null;
    staffEnthusiasm: string | null;
    cleanliness: string | null;
    trafficRate: string | null;
}

export const JobOrder = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ticket, setTicket] = React.useState<any>({});
    const [loading, setLoading] = useState(false);
    const [machineServicesDB, setMachineServicesDB] = useState<any>([]);
    const [operationDB, setOperationDB] = useState([]);
    const [resultImages, setResultImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [hasError, setHasError] = useState(false)
    const [onWay, setOnWay] = useState(false)
    const [startWork, setStartWork] = useState(false)
    const [blenderDB, setBlenderDB] = useState<any>([]);
    const [boardDB, setBoardDB] = useState<any>([]);
    const [machineDB, setMachineDB] = useState<any>([]);
    const [diagnosisDB, setDiagnosisDB] = useState<any>([]);
    const [travelTime, setTravelTime] = useState<any>(0);
    const [running, setRunning] = useState(false);
    const [workTime, setWorkTime] = useState<any>(0);
    const [workRunning, setWorkRunning] = useState(false);
    const [satisfactionDB] = useState([
        {
            id: "HIGH",
            name: "High",
        },
        {
            id: "MEDIUM",
            name: "Medium",
        },
        {
            id: "LOW",
            name: "Low",
        },
    ]);
    const [cleanlinessDB] = useState([
        {
            id: "GREAT",
            name: "Great",
        },
        {
            id: "MEDIUM",
            name: "Medium",
        },
        {
            id: "BAD",
            name: "Bad",
        },
    ]);
    const [trafficRateDB] = useState([
        {
            id: "HIGH",
            name: "High",
        },
        {
            id: "MEDIUM",
            name: "Medium",
        },
        {
            id: "LOW",
            name: "Low",
        },
    ]);
    const [enthusiasmDB] = useState([
        {
            id: "HIGH",
            name: "High",
        },
        {
            id: "MEDIUM",
            name: "Medium",
        },
        {
            id: "LOW",
            name: "Low",
        },
    ]);
    const splittedRoute = location.pathname.split("/").reverse()[0];
    if (!splittedRoute) {
        navigate("/error/404");
    }
    // Fetching ticket data
    const API_URL = process.env.REACT_APP_FETCH_API_URL;
    var requestOptions: any = {
        method: 'GET',
        redirect: 'follow'
    };
    const fetchData = async () => {
        try {
            fetch(`${API_URL}/api/tickets/get-ticket-by-id/${splittedRoute}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const ticketRecord = JSON.parse(result);
                    const ticketResult = ticketRecord["data"] ?? {};
                    if (Object.keys(ticketResult).length === 0) {
                        navigate("/error/404");
                    } else {
                        setTicket(ticketResult);
                        if (ticketResult.travelingStartTime !== null) {
                            let travelEndTime = Moment()
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss");
                            let travelStartTime = Moment.utc(ticketResult.travelingStartTime)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            if (ticketResult.travelingEndTime !== null) {
                                travelEndTime = Moment.utc(ticketResult.travelingEndTime)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")
                            }
                            let diff = Moment(travelEndTime).diff(Moment(travelStartTime), "seconds");
                            setTravelTime(diff)
                            if (ticketResult.travelingEndTime === null) {
                                setRunning(true)
                                setOnWay(true)
                            }
                        }

                        if (ticketResult.workStartTime !== null) {
                            let endWork = Moment()
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss");
                            let startWork = Moment.utc(ticketResult.workStartTime)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            if (ticketResult.workEndTime !== null) {
                                endWork = Moment(ticketResult.workEndTime)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")
                            }
                            let diff = Moment(endWork).diff(Moment(startWork), "seconds");

                            setWorkTime(diff)
                            if (ticketResult.status !== "Completed") {
                                setWorkRunning(true)
                                setStartWork(true)
                            }
                        }

                    }

                })
                .catch(error => {
                    navigate("/error/404")
                    console.log('error', error)
                });

            try {
                const urls = [
                    `${API_URL}/api/tickets/get-machine-service`,
                    `${API_URL}/api/tickets/get-operations`
                ];
                const requests = urls.map(url => fetch(url).then(res => res.json()));
                const toMembers = (responses: any) => responses.map((response: any) => response);
                Promise.all(requests).then(toMembers).then(members => {
                    const machineServiceResults = members[0]['data']
                    machineServiceResults.push({
                        id: "other",
                        name: "Other",
                    });
                    setMachineServicesDB(machineServiceResults);
                    const operationResults = members[1]['data']
                    operationResults.push({
                        id: "other",
                        name: "Other",
                    });
                    setOperationDB(operationResults);


                    fetch(`${API_URL}/api/tickets/get-blenders`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            const blendersRecord = JSON.parse(result);
                            const blendersResults = blendersRecord["data"] ?? [];
                            setBlenderDB(blendersResults);
                        })
                        .catch(error => console.log('error', error));

                    fetch(`${API_URL}/api/tickets/get-boards`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            const boardsRecord = JSON.parse(result);
                            const boardsResults = boardsRecord["data"] ?? [];
                            setBoardDB(boardsResults);
                        })
                        .catch(error => console.log('error', error));

                    fetch(`${API_URL}/api/tickets/get-replace-machines`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            const replaceMachinesRecord = JSON.parse(result);
                            const machinesResults = replaceMachinesRecord["data"] ?? [];
                            setMachineDB(machinesResults);
                        })
                        .catch(error => console.log('error', error));

                    fetch(`${API_URL}/api/tickets/get-diagnosis`, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            const diagnosisRecord = JSON.parse(result);
                            const diagnosisResults = diagnosisRecord["data"] ?? [];
                            diagnosisResults.push({
                                id: "other",
                                name: "Other",
                            })
                            setDiagnosisDB(diagnosisResults);
                        })
                        .catch(error => console.log('error', error));
                });

            } catch (error) {
                console.log(error);
            }

        } catch (e) {
            navigate("/tickets/list");
        }
    };

    useEffect(() => {
        let interval: any;
        if (running) {
            interval = setInterval(() => {
                setTravelTime((prevTime: any) => prevTime + 1);
            }, 1000);
        } else if (!running) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [running]);

    useEffect(() => {
        let interval: any;
        if (workRunning) {
            interval = setInterval(() => {
                setWorkTime((prevTime: any) => prevTime + 1);
            }, 1000);
        } else if (!workRunning) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [workRunning]);

    const travelTimer = () => {
        const getSeconds = `0${(travelTime % 60)}`.slice(-2)
        const minutes: any = `${Math.floor(travelTime / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(travelTime / 3600)}`.slice(-2)
        return `${getHours} : ${getMinutes} : ${getSeconds}`
    }
    const workTimer = () => {
        const getSeconds = `0${(workTime % 60)}`.slice(-2)
        const minutes: any = `${Math.floor(workTime / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(workTime / 3600)}`.slice(-2)
        return `${getHours} : ${getMinutes} : ${getSeconds}`
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const handleUpdateStatus = (status: string) => {
        if (status === 'ON_MY_WAY') {
            setOnWay(true)
            setRunning(true)
        } else if (status === 'START_WORK') {
            setStartWork(true)
            setRunning(false)
        }

        const $input = {
            id: ticket.id,
            jobType: status,
            date: Moment().utc().format("YYYY-MM-DD HH:mm:ss")
        }
        let raw = JSON.stringify($input);
        let requestQueryOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${API_URL}/api/tickets/update-job-status-time`, requestQueryOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                if (res.isSuccess === true) {
                    fetchData()
                }
            })
            .catch(error => {
                fetchData()
                console.log('error', error)
            });
    }

    // Saving image array upload on select file
    const getBase64 = (file: any) => {
        return new Promise((resolve) => {
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                const baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };
    const onSelectFile = (event: any) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray: any = Array.from(selectedFiles);
        for (let i = 0; i < selectedFilesArray.length; i++) {
            const name = selectedFilesArray[i].name;
            const type = selectedFilesArray[i].type;

            getBase64(selectedFilesArray[i])
                .then((result) => {
                    const imagesArray: any = [
                        { name: name, type: type, content: result },
                    ];
                    const resultArray: any = [{ name: name, content: result }];
                    setSelectedImages((previousImages) =>
                        previousImages.concat(imagesArray)
                    );
                    setResultImages((previousImages) =>
                        previousImages.concat(resultArray)
                    );
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
        event.target.value = "";
    };
    function deleteHandler(image: any) {
        setSelectedImages(
            selectedImages.filter((e: any) => e.content !== image.content)
        );
        setResultImages(
            resultImages.filter((e: any) => e.content !== image.content)
        );
        URL.revokeObjectURL(image.content);
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {ticket.status === 'Completed' ? (
                <>
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="d-flex flex-column flex-center flex-column-fluid">
                            <div className="d-flex flex-column flex-center text-center p-10">
                                <div className="card card-flush w-md-450px w-lg-650px py-5">
                                    <div className="card-body py-15 py-lg-20">
                                        <h1>Ticket is closed.</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) :
                (
                    <>
                        {Object.keys(ticket).length !== 0 && (
                            <div className='p-8'>
                                <div className="mx-xl-4 mt-9">
                                    <div className="card">
                                        <div className="cursor-pointer border-bottom-0">
                                            <h3 className="fw-semibold m-0 p-4 px-6">
                                                Job Order - {ticket.uuid ?? ""}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="mt-1 row g-6 g-xl-8">
                                        <div className="col-sm-6 col-xl-4">
                                            <div className="card h-100">
                                                <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                                                    <div className="row mb-5">
                                                        <label className="col-lg-6 fs-4 fw-semibold text-muted">
                                                            Ticket Type
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                                                            <span className="text-dark fs-4">
                                                                {_.capitalize(_.lowerCase(ticket.ticketType))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <label className="col-lg-6  fs-4 fw-semibold text-muted">
                                                            Status
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                                                            <span className="text-dark fs-4">{ticket.status ?? ""}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <label className="col-lg-6 fs-4 fw-semibold text-muted">
                                                            Location
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                                                            <span className="text-dark fs-4">
                                                                {ticket.machine?.location?.name ?? ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col-lg-6  fs-4 fw-semibold text-muted">
                                                            Address
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                                                            <span className="text-dark fs-4">{ticket.machine?.location?.address ?? ""}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-xl-4">
                                            <div className="card h-100">
                                                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                                                    <label className=" fs-4 fw-semibold text-muted">
                                                        Issue Reported
                                                    </label>
                                                    <div className="separator separator-dashed my-3"></div>
                                                    <div className=" mt-2 fv-row">
                                                        {ticket.issuesReported?.length > 0
                                                            ? ticket.issuesReported?.map((element: any) => {
                                                                return (
                                                                    <li className="fs-5 text-dark">{element.name}</li>
                                                                );
                                                            })
                                                            : ""}
                                                        {ticket.issueReportedOtherInput !== "" && (
                                                            <li className="fs-5 text-dark">
                                                                {ticket.issueReportedOtherInput}
                                                            </li>
                                                        )}
                                                        {ticket.issuesReported?.length === 0 &&
                                                            ticket.issueReportedOtherInput === "" && (
                                                                <>
                                                                    <div>
                                                                        <span className="fs-6">No records found</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {ticket.ticketType === "Reactive Maintanance" ||
                                            ticket.ticketType === "Preventive Maintanance" ||
                                            ticket.ticketType === "Lab" ||
                                            ticket?.ticketType === null ?
                                            <div className="col-sm-6 col-xl-4">
                                                <div className="card h-100">
                                                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                                                        <label className="fs-4 fw-semibold text-muted">
                                                            Machine Services Needed
                                                        </label>
                                                        <div className="separator separator-dashed my-3"></div>
                                                        <div className="mt-2 fv-row">
                                                            {ticket.machineServices?.length > 0
                                                                ? ticket.machineServices?.map((element: any) => {
                                                                    return (
                                                                        <li className="fs-5 text-dark">
                                                                            {element.name}
                                                                        </li>
                                                                    );
                                                                })
                                                                : ""}
                                                            {ticket.machineServiceOtherInput !== "" && (
                                                                <li className="fs-5 text-dark">
                                                                    {ticket.machineServiceOtherInput}
                                                                </li>
                                                            )}
                                                            {ticket.machineServices?.length === 0 &&
                                                                ticket.machineServiceOtherInput === "" && (
                                                                    <>
                                                                        <div>
                                                                            <span className="fs-6">No records found</span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                        {ticket.ticketType === "Reactive Maintanance" ||
                                            ticket.ticketType === "Preventive Maintanance" ||
                                            ticket.ticketType === "Lab" ||
                                            ticket?.ticketType === null ?
                                            <div className="col-sm-6 col-xl-4">
                                                <div className="card h-100">
                                                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                                                        <label className=" fs-4 fw-semibold text-muted">
                                                            Operations Needed
                                                        </label>
                                                        <div className="separator separator-dashed my-3"></div>
                                                        <div className=" mt-2 fv-row">
                                                            {ticket.operations?.length > 0
                                                                ? ticket.operations?.map((element: any) => {
                                                                    return (
                                                                        <li className="fs-5 text-dark">
                                                                            {element.name}
                                                                        </li>
                                                                    );
                                                                })
                                                                : ""}
                                                            {ticket.operationOtherInput !== "" && (
                                                                <li className="fs-5 text-dark">
                                                                    {ticket.operationOtherInput}
                                                                </li>
                                                            )}
                                                            {ticket.operations?.length === 0 &&
                                                                ticket.operationOtherInput === "" && (
                                                                    <>
                                                                        <div>
                                                                            <span className="fs-6">No records found</span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                    </div>

                                </div>
                                <div className="card mx-xl-4 mt-9">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            services_other: "",
                                            diagnosis_other: "",
                                            operation_other: "",
                                            operations: [],
                                            machineServices: [],
                                            replaceNewTubeDoors1mmShorter: false,
                                            blenderId: "",
                                            boardId: "",
                                            board: "",
                                            blender: "",
                                            machine: "",
                                            machineId: "",
                                            travelingStartTime: "",
                                            travelingEndTime: "",
                                            workStartTime: "",
                                            diagnosis: [],
                                            comment: "",
                                            waterPressure: "",
                                            replaceFilter: false,
                                            replaceSanitizer: false,
                                            customerSatisfaction: "",
                                            staffEnthusiasm: "",
                                            cleanliness: "",
                                            trafficRate: "",
                                        } as IJobOrder}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            setHasError(true)
                                            if (values.machineServices.includes('other')) {
                                                if (values.services_other === "") {
                                                    setHasError(true);
                                                    return;
                                                }
                                            }
                                            if (values.operations.includes('other')) {
                                                if (values.operation_other === "") {
                                                    setHasError(true);
                                                    return;
                                                }
                                            }
                                            if (values.diagnosis.includes('other')) {
                                                if (values.diagnosis_other === "") {
                                                    setHasError(true);
                                                    return;
                                                }
                                            }
                                            if (
                                                values.machineServices?.includes(
                                                    "8a8b4c94-c806-4bba-a06c-469dd2028513"
                                                ) &&
                                                values.blenderId === ""
                                            ) {
                                                setHasError(true);
                                                return
                                            }
                                            if (
                                                (values.machineServices?.includes(
                                                    "35768bc1-ff02-428e-9669-3969818b32c0"
                                                ) ||
                                                    values.machineServices?.includes(
                                                        "dbc96680-1526-4707-886f-d51f0ba28c0c"
                                                    )) &&
                                                values.boardId === ""
                                            ) {
                                                setHasError(true);
                                                return
                                            }
                                            if (
                                                values.machineServices?.includes(
                                                    "a52bb333-aa9c-4a2a-b72b-8ef020bcc93e"
                                                ) &&
                                                values.machineId === ""
                                            ) {
                                                setHasError(true);
                                                return
                                            }
                                            setLoading(true);
                                            const $inputData: any = {
                                                id: ticket.id,
                                                machineServices: values.machineServices.filter(
                                                    (a) => a !== "other"
                                                ),
                                                diagnosis: values.diagnosis.filter(
                                                    (a) => a !== "other"
                                                ),
                                                operations: values.operations.filter((a) => a !== "other"),
                                                machineServiceOtherInput: values.services_other,
                                                diagnosisOtherInput: values.diagnosis_other,
                                                operationOtherInput: values.operation_other,
                                                blenderId: null,
                                                boardId: null,
                                                machineId: null,
                                                replaceNewTubeDoors1mmShorter: null,
                                                comment: values.comment,
                                                waterPressure: parseInt(values.waterPressure),
                                                replaceFilter: values.replaceFilter,
                                                replaceSanitizer: values.replaceSanitizer,
                                                customerSatisfaction: null,
                                                cleanliness: null,
                                                trafficRate: null,
                                                staffEnthusiasm: null,
                                            };
                                            if (values.customerSatisfaction) {
                                                $inputData["customerSatisfaction"] =
                                                    values.customerSatisfaction;
                                            }
                                            if (values.cleanliness) {
                                                $inputData["cleanliness"] = values.cleanliness;
                                            }
                                            if (values.trafficRate) {
                                                $inputData["trafficRate"] = values.trafficRate;
                                            }
                                            if (values.staffEnthusiasm) {
                                                $inputData["staffEnthusiasm"] = values.staffEnthusiasm;
                                            }
                                            if (resultImages && resultImages.length > 0) {
                                                $inputData["media"] = resultImages;
                                            }
                                            if (
                                                values.machineServices?.includes(
                                                    "8a8b4c94-c806-4bba-a06c-469dd2028513"
                                                )
                                            ) {
                                                $inputData.blenderId = values.blenderId;
                                            }

                                            if (
                                                values.machineServices?.includes(
                                                    "35768bc1-ff02-428e-9669-3969818b32c0"
                                                ) ||
                                                values.machineServices?.includes(
                                                    "dbc96680-1526-4707-886f-d51f0ba28c0c"
                                                )
                                            ) {
                                                $inputData.boardId = values.boardId;
                                            }
                                            if (
                                                values.machineServices?.includes(
                                                    "a52bb333-aa9c-4a2a-b72b-8ef020bcc93e"
                                                )
                                            ) {
                                                $inputData.machineId = values.machineId;
                                            }

                                            $inputData.replaceNewTubeDoors1mmShorter = false;
                                            if (
                                                values.machineServices?.includes(
                                                    "0c2a9460-338e-4c22-b0c2-2197a6c28cfc"
                                                )
                                            ) {
                                                $inputData.replaceNewTubeDoors1mmShorter =
                                                    values.replaceNewTubeDoors1mmShorter ?? false;
                                            }
                                            let raw = JSON.stringify($inputData);
                                            let requestQueryOptions: any = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: raw,
                                                redirect: 'follow'
                                            };
                                            fetch(`${API_URL}/api/tickets/update-job-status`, requestQueryOptions)
                                                .then(response => response.text())
                                                .then(result => {
                                                    const res = JSON.parse(result)
                                                    if (res.isSuccess === true) {
                                                        // toast.success("Job status updated successfully");
                                                        resetForm();
                                                        $("input[type=checkbox]").prop("checked", function () {
                                                            $(this).prop("checked", false);
                                                        });
                                                        setWorkRunning(false)
                                                        setSelectedImages([]);
                                                        setLoading(false);
                                                        fetchData()
                                                    }
                                                })
                                                .catch(error => {
                                                    fetchData()
                                                    console.log('error', error)
                                                });
                                        }}
                                    >
                                        {({
                                            handleChange,
                                            handleSubmit,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                                            <form onSubmit={handleSubmit} autoComplete="off">
                                                <div className="row">
                                                    <div className="col-xl-6 col-12 p-2 pb-0 pe-xl-5 ps-xl-5">
                                                        {ticket.travelingStartTime === null && onWay === false &&
                                                            <button
                                                                type='button'
                                                                onClick={() => handleUpdateStatus('ON_MY_WAY')}
                                                                className="btn btn-light text-dark me-2"
                                                            >
                                                                On My Way
                                                            </button>
                                                        }
                                                        {ticket.travelingStartTime !== null &&
                                                            <div className="btn btn-light text-dark me-3">
                                                                <div>Traveling Time</div>
                                                                <div>{travelTimer()}</div>
                                                            </div>
                                                        }
                                                        {ticket.travelingStartTime !== null &&
                                                            onWay === true && startWork === false &&
                                                            <>
                                                                <button
                                                                    type='button'
                                                                    onClick={() => handleUpdateStatus('START_WORK')}
                                                                    className="btn btn-light text-dark me-2"
                                                                >
                                                                    Start Work
                                                                </button>
                                                            </>
                                                        }
                                                        {ticket.workStartTime !== null &&
                                                            <>
                                                                <div className="btn btn-light text-dark me-3">
                                                                    <div>Work Time</div>
                                                                    <div>{workTimer()}</div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {startWork === true && ticket.status !== "Completed" && (<>
                                                    <div className="row mt-3">
                                                        {ticket.ticketType === "Reactive Maintanance" ||
                                                            ticket.ticketType === "Preventive Maintanance" ||
                                                            ticket.ticketType === "Lab" ||
                                                            ticket?.ticketType === null ? (
                                                            <>
                                                                <div className="col-xl-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                                                                    <div className="accordion">
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="diagnosis"
                                                                                data-bs-toggle="tooltip"
                                                                                title="Click here to add diagnosis done"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#diagnosis-needed"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="diagnosis-needed"
                                                                                >
                                                                                    <span className='required'>Diagnosis</span>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="diagnosis-needed"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="diagnosis"
                                                                            >
                                                                                <div className="accordion-body pb-1 p-3">
                                                                                    <div className="row">
                                                                                        {Array.from(diagnosisDB).map(
                                                                                            (value: any, index) => {
                                                                                                return (
                                                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                                                        <label
                                                                                                            key={index}
                                                                                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                                                                                        >
                                                                                                            <input
                                                                                                                id="diagnosis"
                                                                                                                className="form-check-input mb-2 me-1"
                                                                                                                type="checkbox"
                                                                                                                name={value.id}
                                                                                                                value={value.id}
                                                                                                                defaultChecked={values.diagnosis?.includes(
                                                                                                                    value.id
                                                                                                                )}
                                                                                                                onChange={(e) => {
                                                                                                                    const { checked, name } =
                                                                                                                        e.target;
                                                                                                                    if (checked) {
                                                                                                                        setFieldValue(
                                                                                                                            "diagnosis",
                                                                                                                            [
                                                                                                                                ...values.diagnosis,
                                                                                                                                name,
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        setFieldValue(
                                                                                                                            "machineServices",
                                                                                                                            values.diagnosis.filter(
                                                                                                                                (e: any) => e !== name
                                                                                                                            )
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            <span className="ms-2 mb-1 fs-5 form-check-label">
                                                                                                                {value.name}
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>

                                                                                    {values.diagnosis?.includes("other") ||
                                                                                        values.diagnosis_other !== "" ? (
                                                                                        <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                                                                            <label className="form-label fs-4 required fw-bold">
                                                                                                Other Diagnosis
                                                                                            </label>
                                                                                            <input
                                                                                                id="diagnosis_other"
                                                                                                name="diagnosis_other"
                                                                                                className="form-control"
                                                                                                value={values.diagnosis_other}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                            {hasError === true &&
                                                                                                values.diagnosis_other === '' && (
                                                                                                    <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                                                        Other diagnosis is a required field
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                        <ErrorMessage name="diagnosis" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                                                                    <div className="accordion">
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="machine-services"
                                                                                data-bs-toggle="tooltip"
                                                                                title="Click here to add machine services done"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#machine-services-needed"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="machine-services-needed"
                                                                                >
                                                                                    <span className='required'>Machine Services Done</span>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="machine-services-needed"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="machine-services"
                                                                            >
                                                                                <div className="accordion-body pb-1 p-3">
                                                                                    <div className="row">
                                                                                        {Array.from(machineServicesDB).map(
                                                                                            (value: any, index) => {
                                                                                                return (
                                                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                                                        <label
                                                                                                            key={index}
                                                                                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                                                                                        >
                                                                                                            <input
                                                                                                                id="machineServices"
                                                                                                                className="form-check-input mb-2 me-1"
                                                                                                                type="checkbox"
                                                                                                                name={value.id}
                                                                                                                value={value.id}
                                                                                                                defaultChecked={values.machineServices?.includes(
                                                                                                                    value.id
                                                                                                                )}
                                                                                                                onChange={(e) => {
                                                                                                                    const { checked, name } =
                                                                                                                        e.target;
                                                                                                                    if (checked) {
                                                                                                                        setFieldValue(
                                                                                                                            "machineServices",
                                                                                                                            [
                                                                                                                                ...values.machineServices,
                                                                                                                                name,
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        setFieldValue(
                                                                                                                            "machineServices",
                                                                                                                            values.machineServices.filter(
                                                                                                                                (e) => e !== name
                                                                                                                            )
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            <span className="ms-2 mb-1 fs-5 form-check-label">
                                                                                                                {value.name}
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>

                                                                                    {values.machineServices?.includes("other") ||
                                                                                        values.services_other !== "" ? (
                                                                                        <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                                                                            <label className="form-label fs-4 required fw-bold">
                                                                                                Other Machine Services
                                                                                            </label>
                                                                                            <input
                                                                                                id="services_other"
                                                                                                name="services_other"
                                                                                                className="form-control"
                                                                                                value={values.services_other}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                            {hasError === true &&
                                                                                                values.services_other === '' && (
                                                                                                    <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                                                        Other machine services is a required field
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                        <ErrorMessage name="machineServices" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                                                                    <div className="accordion">
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                data-bs-toggle="tooltip"
                                                                                title="Click here to add operations done"
                                                                                id="operations"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#operations-needed"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="operations-needed"
                                                                                >
                                                                                    <span>Operations Done</span>
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="operations-needed"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="operations"
                                                                            >
                                                                                <div className="accordion-body pb-1 p-3">
                                                                                    <div className="row">
                                                                                        {Array.from(operationDB).map(
                                                                                            (value: any, index) => {
                                                                                                return (
                                                                                                    <div className="col-lg-6 col-md-6 col-12">
                                                                                                        <label
                                                                                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                                                                                            key={index}
                                                                                                        >
                                                                                                            <input
                                                                                                                id="operations"
                                                                                                                type="checkbox"
                                                                                                                name={value.id}
                                                                                                                defaultChecked={values.operations?.includes(
                                                                                                                    value.id
                                                                                                                )}
                                                                                                                className="form-check-input mb-2 me-1"
                                                                                                                value={value.id}
                                                                                                                onChange={(e) => {
                                                                                                                    const { checked, name } =
                                                                                                                        e.target;
                                                                                                                    if (checked) {
                                                                                                                        setFieldValue(
                                                                                                                            "operations",
                                                                                                                            [
                                                                                                                                ...values.operations,
                                                                                                                                name,
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        setFieldValue(
                                                                                                                            "operations",
                                                                                                                            values.operations.filter(
                                                                                                                                (e) => e !== name
                                                                                                                            )
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                            <span className="ms-1 mb-2 fs-5 form-check-label">
                                                                                                                {value.name}
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>

                                                                                    {values.operations?.includes("other") ||
                                                                                        values.operation_other !== "" ? (
                                                                                        <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                                                                            <label className="form-label fs-4 required fw-bold">
                                                                                                Other Operations
                                                                                            </label>
                                                                                            <input
                                                                                                id="operation_other"
                                                                                                name="operation_other"
                                                                                                className="form-control"
                                                                                                value={values.operation_other}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                            {hasError === true &&
                                                                                                values.operation_other === '' && (
                                                                                                    <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                                                        Other operations is a required field
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                            <label className="form-label fs-4 fw-bold">
                                                                Comment
                                                            </label>
                                                            <textarea
                                                                name="comment"
                                                                id="comment"
                                                                className="form-control"
                                                                value={values.comment}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                            <label className="form-label fs-4 required fw-bold">
                                                                Water Pressure
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                min={0}
                                                                value={values.waterPressure}
                                                                name="waterPressure"
                                                                id="waterPressure"
                                                                onChange={handleChange}
                                                            />
                                                            <div className="text-danger text-align-top-center ms-1 mt-2">
                                                                <ErrorMessage name="waterPressure" />
                                                            </div>
                                                        </div>
                                                        {values.machineServices?.includes(
                                                            "8a8b4c94-c806-4bba-a06c-469dd2028513"
                                                        ) ? (
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                                <label className="form-label required fs-4 fw-bold">
                                                                    Blender
                                                                </label>
                                                                <Select
                                                                    className="react-select"
                                                                    classNamePrefix="my-react-select"
                                                                    name="blenderId"
                                                                    placeholder=""
                                                                    options={Array.from(blenderDB).map((value: any) => {
                                                                        return {
                                                                            value: value.id,
                                                                            label: value.title,
                                                                        };
                                                                    })}
                                                                    defaultValue={{
                                                                        label: _.capitalize(_.lowerCase(values.blender)),
                                                                        value: values.blenderId,
                                                                    }}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue("blenderId", e.value);
                                                                    }}
                                                                />
                                                                {hasError === true && values.machineServices?.includes(
                                                                    "8a8b4c94-c806-4bba-a06c-469dd2028513"
                                                                ) &&
                                                                    values.blenderId === '' && (
                                                                        <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                            Blender is a required field
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {values.machineServices?.includes(
                                                            "35768bc1-ff02-428e-9669-3969818b32c0"
                                                        ) ||
                                                            values.machineServices?.includes(
                                                                "dbc96680-1526-4707-886f-d51f0ba28c0c"
                                                            ) ? (
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                                <label className="form-label required fs-4 fw-bold">
                                                                    Board
                                                                </label>
                                                                <Select
                                                                    className="react-select"
                                                                    classNamePrefix="my-react-select"
                                                                    name="boardId"
                                                                    placeholder=""
                                                                    defaultValue={{
                                                                        label: _.capitalize(_.lowerCase(values.board)),
                                                                        value: values.boardId,
                                                                    }}
                                                                    options={Array.from(boardDB).map((value: any) => {
                                                                        return {
                                                                            value: value.id,
                                                                            label: value.title,
                                                                        };
                                                                    })}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue("boardId", e.value);
                                                                    }}
                                                                />
                                                                {hasError === true && (values.machineServices?.includes(
                                                                    "dbc96680-1526-4707-886f-d51f0ba28c0c"
                                                                ) || values.machineServices?.includes(
                                                                    "35768bc1-ff02-428e-9669-3969818b32c0"
                                                                )) && values.boardId === '' && (
                                                                        <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                            Board is a required field
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {values.machineServices?.includes(
                                                            "a52bb333-aa9c-4a2a-b72b-8ef020bcc93e"
                                                        ) ? (
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                                <label className="form-label required fs-4 fw-bold">
                                                                    Machine
                                                                </label>
                                                                <Select
                                                                    className="react-select"
                                                                    classNamePrefix="my-react-select"
                                                                    name="machineId"
                                                                    placeholder=""
                                                                    defaultValue={{
                                                                        label: _.capitalize(_.lowerCase(values.machine)),
                                                                        value: values.machineId,
                                                                    }}
                                                                    options={Array.from(machineDB).map((value: any) => {
                                                                        return {
                                                                            value: value.id,
                                                                            label: `${value.location_name ?? ""} (${value?.serial_number ?? ""
                                                                                })`,
                                                                        };
                                                                    })}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue("machineId", e.value);
                                                                    }}
                                                                />
                                                                {hasError === true && values.machineServices?.includes(
                                                                    "a52bb333-aa9c-4a2a-b72b-8ef020bcc93e"
                                                                ) &&
                                                                    values.machineId === '' && (
                                                                        <div className="text-danger text-align-top-center ms-2 mt-2">
                                                                            Machine is a required field
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {values.machineServices?.includes(
                                                            "0c2a9460-338e-4c22-b0c2-2197a6c28cfc"
                                                        ) ? (
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                                                                <label className="form-label fs-4 fw-bold d-block">
                                                                    Is tube door -1mm shorter ?
                                                                </label>
                                                                <div className="form-check form-switch form-check-custom form-check-solid w-100">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        defaultChecked={
                                                                            values.replaceNewTubeDoors1mmShorter
                                                                        }
                                                                        onChange={(event) => {
                                                                            setFieldValue(
                                                                                "replaceNewTubeDoors1mmShorter",
                                                                                event.target.checked
                                                                            );
                                                                        }}
                                                                        name="replaceNewTubeDoors1mmShorter"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                            <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                                                                <input
                                                                    id="replaceFilter"
                                                                    type="checkbox"
                                                                    name="replaceFilter"
                                                                    className="form-check-input"
                                                                    value="replaceFilter"
                                                                    checked={values.replaceFilter}
                                                                    onChange={handleChange}
                                                                />
                                                                <span
                                                                    className="form-label fw-bold mt-2 ms-3 fs-3"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Replaced Filter
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                                            <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                                                                <input
                                                                    id="replaceSanitizer"
                                                                    type="checkbox"
                                                                    name="replaceSanitizer"
                                                                    className="form-check-input"
                                                                    value="replaceSanitizer"
                                                                    checked={values.replaceSanitizer}
                                                                    onChange={handleChange}
                                                                />
                                                                <span
                                                                    className="form-label fw-bold mt-2 ms-3 fs-3"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Replaced Sanitizer
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                                                            <label
                                                                className="form-label fs-4 mb-0 text-dark  btn btn-bg-light fs-4 w-100 p-3 m-0 border text-start ps-6"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                Click here to upload images & videos
                                                                <input
                                                                    id="upload"
                                                                    type="file"
                                                                    accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                                                                    name="uploadImageVideo"
                                                                    className="form-control d-none"
                                                                    onChange={onSelectFile}
                                                                />
                                                            </label>
                                                            {selectedImages.length > 0 && (
                                                                <>
                                                                    {selectedImages &&
                                                                        selectedImages.map((image, index) => {
                                                                            return (
                                                                                <div
                                                                                    key={image}
                                                                                    className="img-box mt-3 ms-2 justify-content-center"
                                                                                >
                                                                                    {image["type"] === "image/png" ||
                                                                                        image["type"] === "image/jpeg" ||
                                                                                        image["type"] === "image/webp" ||
                                                                                        image["type"] === "image/jpg" ? (
                                                                                        <img
                                                                                            src={image["content"]}
                                                                                            key={index}
                                                                                            width="150"
                                                                                            alt="Upload"
                                                                                            className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                                                                        />
                                                                                    ) : (
                                                                                        <video
                                                                                            controls
                                                                                            src={image["content"]}
                                                                                            className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                                                                            width="170"
                                                                                        />
                                                                                    )}
                                                                                    <button
                                                                                        type='button'
                                                                                        onClick={() => deleteHandler(image)}
                                                                                        className="close-btn img-btn text-gray-700 text-hover-gray-800"
                                                                                    >
                                                                                        <i className="fa fa-close fs-5 pt-1" />
                                                                                    </button>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                                                    Customer Satisfaction
                                                                </label>
                                                                {Array.from(satisfactionDB).map(
                                                                    (value: any, id) => (
                                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                                                                            <label className="ms-2 fs-5">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="customerSatisfaction"
                                                                                    value={value.name}
                                                                                    checked={
                                                                                        values.customerSatisfaction === value.name
                                                                                    }
                                                                                    onChange={() =>
                                                                                        setFieldValue(
                                                                                            "customerSatisfaction",
                                                                                            value.name
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <span className="form-check-label fs-5 mb-0 ms-2">
                                                                                    {value.name}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>

                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                                                    Cleanliness
                                                                </label>
                                                                {Array.from(cleanlinessDB).map(
                                                                    (value: any, id) => (
                                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                                                                            <label className="ms-2 fs-5" key={id}>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="cleanliness"
                                                                                    value={value.name}
                                                                                    checked={
                                                                                        values.cleanliness === value.name
                                                                                    }
                                                                                    onChange={() =>
                                                                                        setFieldValue(
                                                                                            "cleanliness",
                                                                                            value.name
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <span className="form-check-label fs-5 mb-0 ms-2">
                                                                                    {value.name}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>

                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                                                    Traffic Rate
                                                                </label>
                                                                {Array.from(trafficRateDB).map(
                                                                    (value: any, id) => (
                                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                                                                            <label className="ms-2 fs-5" key={id}>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="trafficRate"
                                                                                    value={value.name}
                                                                                    checked={
                                                                                        values.trafficRate === value.name
                                                                                    }
                                                                                    onChange={() =>
                                                                                        setFieldValue(
                                                                                            "trafficRate",
                                                                                            value.name
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <span className="form-check-label fs-5 mb-0 ms-2">
                                                                                    {value.name}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                                                    Staff Enthusiasm
                                                                </label>
                                                                {Array.from(enthusiasmDB).map((value: any, id) => (
                                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                                                                        <label className="ms-2 fs-5" key={id}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="staffEnthusiasm"
                                                                                value={value.name}
                                                                                checked={
                                                                                    values.staffEnthusiasm === value.name
                                                                                }
                                                                                onChange={() =>
                                                                                    setFieldValue("staffEnthusiasm", value.name)
                                                                                }
                                                                            />
                                                                            <span className="form-check-label fs-5 mb-0 ms-2">
                                                                                {value.name}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    <div className="row">
                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary me-4 fs-4 w-100"
                                                            >
                                                                {loading && (
                                                                    <span
                                                                        className="indicator-progress"
                                                                        style={{ display: "block" }}
                                                                    >
                                                                        Please Wait..
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                                    </span>
                                                                )}
                                                                {!loading && (
                                                                    <span className="indicator-label">Finish Work</span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div></>)}
                                            </form>
                                        </div>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </>
                )}
        </>
    )
}
