import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import { createCustomer, getDistributor } from "../../models/_customer";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const validationSchema = yup.object({
  customerSecondContact: yup.boolean(),
  accountType: yup.string().required("Account type is required"),
  isVip: yup.string().required("Is vip  is required"),
  customerSecondContactName: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  customerSecondContactPhoneNumber: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  customerSecondContactEmail: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
  }),
  customerSecondContactNameRole: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  distributorId: yup.string().required("Distributor is required"),
  distributor_other: yup.string().when("distributorId", {
    is: "other",
    then: yup.string().required("Other distributor is required"),
  }),
  name: yup.string().required("Customer name is required"),
  customerCompanyName: yup.string().required("Company name is required"),
  customerContactName: yup.string().required("Contact name is required"),
  customerContactPhoneNumber: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact phone is required"),
  customerContactEmail: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email"),
  customerContactNameRole: yup
    .string()
    .required("Contact name role is required"),
  contactPhoneType: yup.string().required("Contact phone type is required"),
  secondContactPhoneType: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),
});

const CreateCustomer = () => {
  const navigate = useNavigate();
  const resetDistributorId = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [distributorDB, setDistributorDB] = useState([]);
  const accountTypeDB = [
    {
      id: "CHAIN",
      name: "Chain",
    },
    {
      id: "INDIVIDUAL",
      name: "Individual",
    },
  ];
  const contactTypeDB = [
    {
      id: "PERSONAL",
      name: "Personal",
    },
    {
      id: "OFFICE",
      name: "Office",
    },
  ];
  const isVipDB = [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No"
    }
  ]

  const fetchData = async () => {
    try {
      const distributorRecord = await getDistributor();
      const distributorIdResults: any = distributorRecord
        ? distributorRecord
        : [];
      distributorIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      distributorIdResults.push({
        id: "other",
        name: "Other",
      });

      setDistributorDB(distributorIdResults);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="customerParentAccount page-heading d-flex text-dark fw-bold fs-1  my-0">
            Create Customer<i className="fas fa-info-circle fs-4  ms-2 mt-2"></i>
            </h1>

            <Tooltip anchorSelect=".customerParentAccount" >
              In this screen, log the information of the company that purchased the machine
            </Tooltip>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Customers</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            customerCompanyName: "",
            customerContactName: "",
            customerContactPhoneNumber: "",
            customerContactEmail: "",
            customerContactNameRole: "",
            accountType: "",
            contactPhoneType: "",
            distributorId: "",
            customerSecondContactName: "",
            customerSecondContactPhoneNumber: "",
            customerSecondContactEmail: "",
            customerSecondContactNameRole: "",
            customerSecondContact: false,
            secondContactPhoneType: "",
            distributor_other: "",
            isActive: true,
            isVip: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const $inputData: any = {
              name: values.name,
              customerCompanyName: values.customerCompanyName,
              customerContactName: values.customerContactName,
              customerContactPhoneNumber: values.customerContactPhoneNumber,
              customerContactEmail: values.customerContactEmail,
              customerContactNameRole: values.customerContactNameRole,
              accountType: values.accountType,
              typeOfContactPhoneNumber: values.contactPhoneType,
              distributorId: values.distributorId,
              distributorOtherInput: values.distributor_other,
              customerSecondContact: values.customerSecondContact,
              customerSecondContactName: values.customerSecondContactName,
              customerSecondContactPhoneNumber:
                values.customerSecondContactPhoneNumber,
              customerSecondContactEmail: values.customerSecondContactEmail,
              customerSecondContactNameRole:
                values.customerSecondContactNameRole,
              isActive: values.isActive,
              isVip: values.isVip,
            };
            if (values.secondContactPhoneType) {
              $inputData["typeOfSecondContactPhoneNumber"] =
                values.secondContactPhoneType;
            }

            if (values.distributorId === "other") {
              $inputData.distributorId = "";
            }
            const result = await createCustomer($inputData);
            if (result?.data?.data?.createCustomer) {
              toast.success("Customer created successfully");
              resetDistributorId.current.setValue("");
              setLoading(false);
              resetForm();
              navigate("/customers/list");
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="customerName fs-4 required fw-bold">
                      Customer Name

                    </label>
                    <i className="customerName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".customerName" className="tooltip-dashboard">
                      Enter the name you would like to use for the customer in our system.
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="companyName form-label fs-4 required fw-bold">
                      Company Name
                    </label>
                    <i className="companyName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".companyName" className="tooltip-dashboard">
                      Legal name of the company- Can be the same as customer name.
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerCompanyName) &&
                          touched.customerCompanyName,
                      })}
                      name="customerCompanyName"
                      id="customerCompanyName"
                      value={values.customerCompanyName}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerCompanyName" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactName form-label fs-4 required fw-bold">
                      Contact Name
                    </label>
                    <i className="contactName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactName" className="tooltip-dashboard">
                      The phone number of the person who will serve as our point of contact at the company level
                    </Tooltip>
                    <input
                      name="customerContactName"
                      id="customerContactName"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactName) &&
                          touched.customerContactName,
                      })}
                      value={values.customerContactName}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerContactName" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="contactPhoneType form-label fs-4 required fw-bold ps-1">
                      Contact Phone Type
                    </label>
                    <i className="contactPhoneType fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactPhoneType" className="tooltip-dashboard">
                      Specify if this phone number is for work or personal use.
                    </Tooltip>
                    {Array.from(contactTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="contactPhoneType"
                            className="form-check-input"
                            type="radio"
                            name="contactPhoneType"
                            value={value.id}
                            checked={values.contactPhoneType === value.id}
                            onChange={() =>
                              setFieldValue("contactPhoneType", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="contactPhoneType" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactPhone form-label fs-4 required fw-bold">
                      Contact Phone
                    </label>
                    <i className="contactPhone fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactPhone" className="tooltip-dashboard">
                      Enter the phone number of the contact person
                    </Tooltip>
                    <input
                      id="customerContactPhoneNumber"
                      name="customerContactPhoneNumber"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactPhoneNumber) &&
                          touched.customerContactPhoneNumber,
                      })}
                      value={values.customerContactPhoneNumber}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerContactPhoneNumber" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactEmail form-label fs-4 required fw-bold">
                      Contact Email
                    </label>
                    <i className="contactEmail fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactEmail" className="tooltip-dashboard">
                      Enter the email address of the contact person
                    </Tooltip>
                    <input
                      id="customerContactEmail"
                      name="customerContactEmail"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactEmail) &&
                          touched.customerContactEmail,
                      })}
                      value={values.customerContactEmail}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerContactEmail" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="distributor form-label fs-4 required fw-bold">
                      Distributor
                    </label>
                    <i className="distributor fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".distributor" className="tooltip-dashboard">
                      Select the company distributing our products to the customer. For direct distribution, choose 'Smoodi.
                    </Tooltip>
                    <Select
                      ref={resetDistributorId}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.distributorId) &&
                          touched.distributorId,
                      })}
                      classNamePrefix="my-react-select"
                      name="distributorId"
                      placeholder=""
                      onChange={(event: any) => {
                        setFieldValue("distributorId", event.value);
                      }}
                      options={Array.from(distributorDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="distributorId" />
                    </div>
                  </div>
                  {values.distributorId === "other" ? (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Other distributor
                      </label>
                      <input
                        id="distributor_other"
                        name="distributor_other"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.distributor_other) &&
                            touched.distributor_other,
                        })}
                        value={values.distributor_other}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="distributor_other" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactNameRole form-label fs-4 required fw-bold">
                      Contact Name Role
                    </label>
                    <i className="contactNameRole fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactNameRole" className="tooltip-dashboard">
                      The job title of the person listed in the 'Contact Name' tab.
                    </Tooltip>
                    <input
                      id="customerContactNameRole"
                      name="customerContactNameRole"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactNameRole) &&
                          touched.customerContactNameRole,
                      })}
                      value={values.customerContactNameRole}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerContactNameRole" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="accountType form-label fs-4 required fw-bold ps-1">
                      Account Type
                    </label>
                    <i className="accountType fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".accountType" className="tooltip-dashboard">
                      If this customer has multiple locations (even if only one has a Smoodi machine), choose 'Chain
                    </Tooltip>
                    {Array.from(accountTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="accountType"
                            className="form-check-input"
                            type="radio"
                            name="accountType"
                            value={value.id}
                            checked={values.accountType === value.id}
                            onChange={() =>
                              setFieldValue("accountType", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="accountType" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="form-label fs-4 required fw-bold ps-1">
                      Is VIP
                    </label>
                    {Array.from(isVipDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="isVip"
                            className="form-check-input"
                            type="radio"
                            name="isVip"
                            value={value.id}
                            checked={values.isVip === value.id}
                            onChange={() =>
                              setFieldValue("isVip", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="isVip" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2 mb-8">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
                      <input
                        id="customerSecondContact"
                        type="checkbox"
                        name="customerSecondContact"
                        className="form-check-input"
                        value="customerSecondContact"
                        checked={values.customerSecondContact}
                        onChange={handleChange}
                      />
                      <span
                        className="addSecondContact form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add Second Contact
                      </span>
                      <i className="addSecondContact fas fa-info-circle fs-4 mb-2 ps-1"></i>
                      <Tooltip anchorSelect=".addSecondContact" className="tooltip-dashboard">
                        Use this option to add an additional contact person's details
                      </Tooltip>
                      {/* <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add second contact"
                      ></i> */}
                    </label>
                  </div>
                </div>

                {values.customerSecondContact === true ? (
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name
                      </label>
                      <input
                        name="customerSecondContactName"
                        id="customerSecondContactName"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactName) &&
                            touched.customerSecondContactName,
                        })}
                        value={values.customerSecondContactName}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactName" />
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Phone
                      </label>
                      <input
                        id="customerSecondContactPhoneNumber"
                        name="customerSecondContactPhoneNumber"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactPhoneNumber) &&
                            touched.customerSecondContactPhoneNumber,
                        })}
                        value={values.customerSecondContactPhoneNumber}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactPhoneNumber" />
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label required fs-4 fw-bold">
                        Contact Email
                      </label>
                      <input
                        id="customerSecondContactEmail"
                        name="customerSecondContactEmail"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactEmail) &&
                            touched.customerSecondContactEmail,
                        })}
                        value={values.customerSecondContactEmail}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactEmail" />
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name Role
                      </label>
                      <input
                        id="customerSecondContactNameRole"
                        name="customerSecondContactNameRole"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactNameRole) &&
                            touched.customerSecondContactNameRole,
                        })}
                        value={values.customerSecondContactNameRole}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactNameRole" />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                      <label className="form-label fs-4 required fw-bold ps-1">
                        Contact Phone Type
                      </label>
                      {Array.from(contactTypeDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="secondContactPhoneType"
                              className="form-check-input"
                              type="radio"
                              name="secondContactPhoneType"
                              value={value.id}
                              checked={
                                values.secondContactPhoneType === value.id
                              }
                              onChange={() =>
                                setFieldValue(
                                  "secondContactPhoneType",
                                  value.id
                                )
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="secondContactPhoneType" />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 px-xl-5 p-2 mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD CUSTOMER</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateCustomer;
