import * as Yup from "yup";

export interface ICreateTicket {
  serviceType: string;
  ticketType: string;
  assignToId: string;
  machineId: string;
  ticketSourceId: string;
  reportedAt: Date;
  priority: string;
  closeTicket: boolean;
  machineServices: string[];

  issuesReported: string[];
  operations: string[];
  marketingOrOthers: string[];
  marketingMaterials: string[];
  closingDateAndTime: Date;
  howTicketClosed: string | null;
  comment: string;
  chatDescription: string;
  timeSpentOnTask: string;
  travelTime: string;
  cleanliness: string | null;
  trafficRate: string | null;
  customerSatisfaction: string | null;
  staffEnthusiasm: string | null;

  services_other: string;
  issueReported_other: string;
  operation_other: string;
  marketing_other: string;
  reporterType: string;
  reportedById: string;
  reporterName: string;
  reporterEmail: string;
  reporterPhone: string;
}

const createTicketSchemas = [
  Yup.object({
    serviceType: Yup.string().required("Service type is required"),
    ticketType: Yup.string().required("Ticket type is required"),
    // assignToId: Yup.string().required("Assign to is required"),
    machineId: Yup.string().required("Machine is required"),
    ticketSourceId: Yup.string().required("Ticket source is required"),
    reporterName: Yup.string().when('reporterType', {
      is: 'EXTERNAL',
      then : Yup.string().required('Reporter name is required')
    }),
    reporterEmail: Yup.string().when('reporterType', {
      is: 'EXTERNAL',
      then : Yup.string().email('Please enter valid email').optional()
    }),
    // reporterPhone: Yup.string().when('reporterType', {
    //   is: 'EXTERNAL',
    //   then : Yup.string().required('Reporter phone is required')
    // }),
    reportedById: Yup.string().when('reporterType', {
      is: 'INTERNAL',
      then : Yup.string().required('Reported by is required')
    }),
  }),


  // Yup.object({
  //   issuesReported: Yup.array().min(1, "Issue reported is required"),
  // }),
  Yup.object({
    issuesReported :
      Yup.array().when('ticketType', {
      is: (ticketType:any) => ticketType !== 'MARKETING_OTHER' ,
      then: Yup.array().min(1, "Issue reported is required"),
      otherwise: Yup.array(),
    }),
  }),

  Yup.object({
    closeTicket: Yup.boolean(),
    howTicketClosed: Yup.string().when("closeTicket", {
      is: true,
      then: Yup.string().required("Service type is required"),
    }),
    timeSpentOnTask: Yup.string().when("closeTicket", {
      is: true,
      then: Yup.string().required("Time spend on task is required"),
    }),
    travelTime: Yup.string().when("howTicketClosed", {
      is: "ON_SITE",
      then: Yup.string().required("Travel time is required"),
    }),
  }),
];

const inits: ICreateTicket = {
  serviceType: "",
  ticketType: "",
  assignToId: "",
  machineId: "",
  priority: "HIGH",
  ticketSourceId: "",
  reportedAt: new Date(),
  closeTicket: false,
  machineServices: [],
  issuesReported: [],
  operations: [],
  marketingOrOthers: [],
  howTicketClosed: "",
  comment: "",
  chatDescription: "",
  timeSpentOnTask: "",
  travelTime: "",
  closingDateAndTime: new Date(),
  marketingMaterials: [],
  cleanliness: "",
  trafficRate: "",
  customerSatisfaction: "",
  staffEnthusiasm: "",

  services_other: "",
  issueReported_other: "",
  operation_other: "",
  marketing_other: "",
  reporterType: 'INTERNAL',
  reportedById: "",
  reporterName: "",
  reporterEmail: "",
  reporterPhone: ""
};

export { createTicketSchemas, inits };
