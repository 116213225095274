import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


 export async function getTicketSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query ticketSummary(  
                $filter :TicketFilterInput!
                ) {
                    ticketSummary(filter: $filter) {
                        ticketType
                        totalTickets
                        closedTickets
                        pendingTickets
                        inprocessTickets
                        closedIn72Hrs
                        openSince3Days
                      }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.ticketSummary;
  }

  export async function getTicketDetailSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  ticketDetailSummary(  
                $filter :TicketFilterInput!
                ) {
                    ticketDetailSummary(filter: $filter) {
                        issueReported {
                            id
                            name
                            count
                          }
                          machineServicesDone {
                            id
                            name
                            count
                          }
                          operationsDone {
                            id
                            name
                            count
                          }
                          marketings {
                            id
                            name
                            count
                          }
                      }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.ticketDetailSummary;
  }