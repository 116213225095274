/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useAuth } from "../../../../app/modules/auth";

import { toAbsoluteUrl } from "../../../helpers";
import Modal from "react-responsive-modal";
import React from "react";
import { changePassword } from "../../../../_smoodi/models/_user";
import { ErrorMessage, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import * as yup from "yup";

const validationSchema = yup.object({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup.string().required("New password is required"),
  confirmNewPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-40px me-5">
              <img
                alt="Logo"
                className="bg-gray-400 rounded-circle"
                src={toAbsoluteUrl(
                  "/media/icons/duotune/communication/com015.png"
                )}
              />
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-2 border-white h-15px w-15px"></div>
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {currentUser?.firstName}
              </div>
              <a
                href="#"
                className="fw-bold text-muted text-hover-primary fs-7"
              >
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>
        <div className="menu-item px-5">
          <a
            onClick={() => {
              setOpen(true);
            }}
            className="menu-link px-5"
          >
            Change Password
          </a>
        </div>
        <div className="menu-item px-5">
          <a onClick={logout} className="menu-link px-5">
            Sign Out
          </a>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        role="modal"
        center
      >
        <div className="modal-header p-2 pb-4 mb-4">
          <h4 className="modal-title fs-1">Change Password</h4>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setLoading(true);
              const result = await changePassword(
                values.oldPassword,
                values.newPassword,
                values.confirmNewPassword
              );
              if (result?.data?.data?.changePassword) {
                toast.success("Password updated successfully");
                setLoading(false);
                resetForm();
                setOpen(false);
              } else {
                setSubmitting(false);
                setLoading(false);
                const messages = result?.data?.errors.map(
                  (e: any) => e.message
                );
                toast.error(messages.join(","));
              }
            }}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Old password
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.oldPassword) && touched.oldPassword,
                      })}
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      value={values.oldPassword}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="oldPassword" />
                    </div>
                  </div>
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      New Password
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.newPassword) && touched.newPassword,
                      })}
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="newPassword" />
                    </div>
                  </div>
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 required   fw-bold">
                      Confirm Password
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.confirmNewPassword) &&
                          touched.confirmNewPassword,
                      })}
                      type="password"
                      name="confirmNewPassword"
                      id="confirmNewPassword"
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="confirmNewPassword" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">UPDATE</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Modal>
    </>
  );
};

export { HeaderUserMenu };
