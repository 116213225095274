import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateDiagnosisInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function createDiagnosis(input: CreateDiagnosisInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createDiagnosis (
            $input: CreateDiagnosisInput!
          ){
            createDiagnosis(
                createDiagnosisInput: $input
              )  {
                id
                name
                createdAt
                updatedAt
                isDefault
                priority
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getDiagnosisList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getAllDiagnosis($where:DiagnosisResolver_GetAllDiagnosis_FilterInputType
                  ) {
                    getAllDiagnosis (where:$where) {
                      id
                      name
                      createdAt
                      updatedAt
                      isDefault
                      priority
                    }
                  }
                `,
      variables: { where },
    },
  });
  return data?.data?.data?.getAllDiagnosis;
}

interface UpdateDiagnosisInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function updateDiagnosis(input: UpdateDiagnosisInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateDiagnosis (
              $input: UpdateDiagnosisInput!
            ){
                updateDiagnosis(
                    updateDiagnosisInput: $input
                )  {
                    id
                    name
                    createdAt
                    updatedAt
                    isDefault
                    priority
                }
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
