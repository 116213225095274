import * as XLSX from "xlsx-js-style";

export const DownloadExcel = (
  dataSource: any,
  sheetName: string,
  fileName: string
) => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataSource);
  for (var i in ws) {
    if (typeof ws[i] != "object") continue;
    let cell = XLSX.utils.decode_cell(i);

    ws[i].s = {
      font: {
        sz: "10.5",
      },
      alignment: {
        vertical: "start",
        horizontal: "start",
      },
    };
    if (cell.r === 0) {
      ws[i].s = {
        font: { bold: true, sz: "10.5" },
      };
      ws[i].s.fill = {
        patternType: "solid",
        fgColor: { rgb: "b2b2b2" },
        bgColor: { rgb: "b2b2b2" },
      };
    }
  }
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
