/**
 * @generated SignedSource<<8e724911ff1ebaea043c3f31601a282f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AnalyticDashboard_getQuickSightDashboardURLQuery$variables = {};
export type AnalyticDashboard_getQuickSightDashboardURLQuery$data = {
  readonly getQuickSightDashboardURL: string;
};
export type AnalyticDashboard_getQuickSightDashboardURLQuery = {
  response: AnalyticDashboard_getQuickSightDashboardURLQuery$data;
  variables: AnalyticDashboard_getQuickSightDashboardURLQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "getQuickSightDashboardURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnalyticDashboard_getQuickSightDashboardURLQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AnalyticDashboard_getQuickSightDashboardURLQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5d20779d2519ee7839e8d600962b80a6",
    "id": null,
    "metadata": {},
    "name": "AnalyticDashboard_getQuickSightDashboardURLQuery",
    "operationKind": "query",
    "text": "query AnalyticDashboard_getQuickSightDashboardURLQuery {\n  getQuickSightDashboardURL\n}\n"
  }
};
})();

(node as any).hash = "057784931b3e0c565cf8c1399868d581";

export default node;
