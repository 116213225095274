import React, {useRef, useEffect, useState} from "react";
import * as d3 from "d3";

// Define the interface for data
interface BarChartProps {
  data: {
    year: string;
    totalBlends: string; // Keep this as string for initial data, convert later
  }[];
}

const YearWiseBarChart: React.FC<BarChartProps> = ({data}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState<{width: number; height: number}>(
    {
      width: 0,
      height: 0,
    }
  );

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentWidth = svgRef.current.parentElement?.clientWidth ?? 1500;
        setDimensions({
          width: parentWidth - 60, // Adjust for margins
          height: 400,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial dimensions

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current) return;

    const {width, height} = dimensions;
    const margin = {top: 40, right: 10, bottom: 70, left: 60};

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Convert totalBlends to numbers and define the x and y scales
    const parsedData = data.map((d) => ({
      year: d.year,
      totalBlends: parseFloat(d.totalBlends), // Convert string to number
    }));

    const x = d3
      .scaleBand()
      .domain(parsedData.map((d) => d.year))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(parsedData, (d) => d.totalBlends) ?? 0])
      .nice()
      .range([height, 0]);

    // Add the X axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle") // Center-align the text
      .style("font-size", "12px");

    // Add the Y axis
    svg.append("g").call(d3.axisLeft(y));

    // Add X axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 20)
      .attr("text-anchor", "middle")
      .text("Year")
      .style("font-size", "12px");

    // Add Y axis label
    svg
      .append("text")
      .attr("x", -(height / 2))
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Total Blends")
      .style("font-size", "12px");

    // Add chart title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .text("Yearly Total Blends")
      .style("font-size", "16px")
      .style("font-weight", "bold");

    // Create tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "5px")
      .style("border-radius", "3px")
      .style("opacity", 0);

    // Add bars with interactivity
    svg
      .selectAll(".bar")
      .data(parsedData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.year) ?? 0)
      .attr("y", (d) => y(d.totalBlends))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(d.totalBlends))
      .attr("fill", "#70c057")
      .on("mouseover", function (event, d) {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Year: ${d.year}<br>Total Blends: ${d.totalBlends}`)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", function () {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    // Add total Blends values as text inside the bars
    svg
      .selectAll(".label")
      .data(parsedData)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => (x(d.year) ?? 0) + x.bandwidth() / 2) // Center the text
      .attr("y", (d) => {
        const barHeight = height - y(d.totalBlends);
        return barHeight > 20 ? y(d.totalBlends) + 15 : y(d.totalBlends) - 5;
      })
      .attr("text-anchor", "middle")
      .text((d) => Math.round(d.totalBlends)) // Remove decimal points
      .style("font-size", "12px")
      .style("fill", (d) =>
        height - y(d.totalBlends) > 20 ? "white" : "black"
      );

    // Add legend
    const legend = svg
      .append("g")
      .attr("transform", `translate(0, ${height + 51})`);

    const legendItem = legend
      .selectAll(".legend-item")
      .data(["Total Blends"])
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => `translate(${i * 150}, 0)`);

    legendItem
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 18)
      .attr("height", 8)
      .attr("fill", "#70c057");

    legendItem
      .append("text")
      .attr("x", 25)
      .attr("y", 4)
      .attr("dy", ".35em")
      .text("Total Blends")
      .style("font-size", "12px");

    return () => {
      // Cleanup tooltip on component unmount
      d3.select(".tooltip").remove();
    };
  }, [data, dimensions]);

  return <svg ref={svgRef} style={{width: "100%", height: "auto"}} />;
};

export default YearWiseBarChart;
