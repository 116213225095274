import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getDailyConsumption(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getDailyConsumption($filter:GetDailyConsumptionFilterInput!) {
          getDailyConsumption (filter:$filter) {
            day
            averageBlends
          }
        }
      `,
      variables: {filter},
    },
  });

  return data?.data?.data?.getDailyConsumption;
}

export async function getMonthlyConsumption(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getMonthlyConsumption($filter:GetMonthlyConsumptionFilterInput!) {
          getMonthlyConsumption (filter:$filter) {
            month
            totalBlends
          }
        }
      `,
      variables: {filter},
    },
  });

  return data?.data?.data?.getMonthlyConsumption;
}

export async function getFlavorConsumptionByTimeOfDay(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getFlavorConsumptionByTimeOfDay($filter:GetFlavorConsumptionByTimeOfDayFilterInput!) {
          getFlavorConsumptionByTimeOfDay (filter:$filter) {
            hourOfTheDay
            name
            totalConsumption
            flavorColorCode
          }
        }
      `,
      variables: {filter},
    },
  });

  return data?.data?.data?.getFlavorConsumptionByTimeOfDay;
}

export async function getConsumptionByFlavor(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getConsumptionByFlavor($filter: GetConsumptionByFlavorFilterInput!
        ) {getConsumptionByFlavor (filter:$filter) {
            flavor
            consumptionRate,
            flavorColorCode
          }
        }
      `,
      variables: {filter},
    },
  });
  return data?.data?.data?.getConsumptionByFlavor;
}

export async function getAnnualConsumption(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getAnnualConsumption($filter: GetAnnualConsumptionFilterInput!) {
          getAnnualConsumption (filter:$filter) {
            year
            totalBlends
          }
        }
      `,
      variables: {filter},
    },
  });
  return data?.data?.data?.getAnnualConsumption;
}

export async function getMonthlyConsumptionByMachine(filter: {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getMonthlyConsumptionByMachine($filter: GetMonthlyConsumptionByMachineFilterInput!) {
          getMonthlyConsumptionByMachine (filter:$filter) {
            machine
            month
            consumption
          }
        }
      `,
      variables: {filter},
    },
  });
  return data?.data?.data?.getMonthlyConsumptionByMachine;
}
