import Iframe from "react-iframe";

function Manual() {
  return (
    <div>
      <Iframe
        url={`https://drive.google.com/file/d/1XYRAdWVsqF73vgr11Pqp8-CWXyT1ITAU/preview`}
        height="750px"
        className="w-100"
        display="block"
        position="relative"
      />
    </div>
  );
}

export default Manual;
