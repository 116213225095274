const Columns = ({ 
    columnData, 
    selectedColumns, 
    handleColumnDataSelect, 
    handleColumnDataSubmit 
  }: any) => {
  return (
    <>
        <div className="btn-group w-100">
            <button className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Columns</button>
            <div className="dropdown-menu dropdown-menu-right p-4 fs-6" id="colsDrop">
                <h3 className="modal-title mb-4 text-gray-600">Show Columns</h3>
                {columnData.map((value: any) => {
                    return (
                    <label className="form-check form-check-custom form-check-sm align-items-center mt-1 fw-semibold" key={value.id}>
                        <input
                            type="checkbox" className="toggle-vis form-check-input cursor-pointer me-2"
                            data-column={value.id} data-key={value.key}
                            defaultChecked
                            checked={selectedColumns.includes(value.key)}
                            value={value.key}
                            onClick={handleColumnDataSelect}
                        />
                        {value.label}
                    </label>  
                    );
                })}
                <button onClick={handleColumnDataSubmit} type="button" className="columnBtn btn btn-primary btn-sm me-4 fs-6 mt-6 updateDisplayColumns">Submit</button>
            </div>
        </div>
    </>
  );
  };
  export default Columns;