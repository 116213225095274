import React, { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Modal from "react-responsive-modal";
import {
  getOperationsList,
  updateOperations,
} from "../../../models/_operations";
import toast, { Toaster } from "react-hot-toast";
import { KTSVG } from "../../../../_metronic/helpers";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";

export default class OperationList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableRef: createRef(),
      resetIsDefault: createRef(),
      filterLoading: false,
      editOpen: false,
      addLoading: false,
      currentlyOpenRecord: {},
      operationListObj: {},
      name: "",
      priority: "",
      isDefault: "",
      filterIsDefault: true,
      isDefaultDB: [
        {
          id: true,
          name: "True",
        },
        {
          id: false,
          name: "False",
        },
      ],
    };
  }
  async componentWillMount() {}
  async componentDidUpdate() {}

  async componentDidMount() {
    var _ = this;
    $("#operationTable").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.editOpen(record);
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    $(document).ready(function () {
      $("#operationTable").DataTable().destroy();
      let table = $("#operationTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "name",
            render: function (data, type, row) {
              return row.name;
            },
          },
          {
            name: "priority",
            render: function (data, type, row) {
              return row.priority;
            },
          },
          {
            name: "isDefault",
            render: function (data, type, row) {
              return row.isDefault;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-operation") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit operation"><button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }

              return returnData;
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
      if (permissions?.includes("update-operation") === false) {
        table.column(4).visible(false);
      }
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getFilterRecords = async () => {};
  getData = async (isReset = false) => {
    let filter: any = {};
    if (this.state.filterIsDefault === true) {
      filter["is_default"] = { eq: true };
    } else if (this.state.filterIsDefault === false) {
      filter["is_default"] = { eq: false };
    }
    const records = await getOperationsList(filter);
    const rows = [];
    const operationListObj: any = {};
    let counter = 0;
    for (const record of records) {
      counter++;
      const tempRecord = {
        id: counter,
        recordId: record.id,
        name: record.name,
        priority: record.priority,
        isDefault: record.isDefault,
        createdAt: record.createdAt,
        record: record,
      };
      rows.push(tempRecord);
      operationListObj[record.id] = tempRecord;
    }

    this.setState({
      operationListObj: operationListObj,
    });
    return rows;
  };
  //edit ticket open
  editOpen = (recordId: any) => {
    const record = this.state.operationListObj[recordId];
    this.setState({
      name: record.name,
      priority: record.priority,
      isDefault: record.isDefault,
      currentlyOpenRecord: record.record,
      editOpen: true,
    });
  };
  editClose = () => {
    this.setState({
      currentlyOpenRecord: "",
      name: "",
      priority: "",
      isDefault: false,
      editOpen: false,
      loading: false,
    });
  };

  submitEdit = async (record: any) => {
    let hasErrors = false;
    if (this.state.name === "") {
      hasErrors = true;
      toast.error("Name is required!");
    }
    if (this.state.priority === "") {
      hasErrors = true;
      toast.error("Priority is required!");
    }
    if (this.state.isDefault === "") {
      hasErrors = true;
      toast.error("Is default is required!");
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const updateData = {
      id: record.id,
      name: this.state.name,
      priority: parseInt(this.state.priority),
      isDefault: this.state.isDefault,
    };
    const result = await updateOperations(updateData);

    if (result?.data?.data?.updateoperation) {
      toast.success("Operation updated successfully");
      this.setState({
        loading: false,
      });
      this.editClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      this.setState({
        loading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };
  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    this.state.resetIsDefault.current.setValue("");

    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      filterLoading: false,
      filterIsDefault: "",
    });
    return false;
  };

  render() {
    return (
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Operations List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Operations List
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#operation-filter"
                  aria-expanded="false"
                  aria-controls="operation-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                    Filter
                </button>
              </div>
            </div>
            <div className="collapse" id="operation-filter" aria-labelledby="operation-filter">
            <div className="row mt-9">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">Is Default</label>
                <Select
                  ref={this.state.resetIsDefault}
                  className="react-select"
                  classNamePrefix="my-react-select"
                  name="filterIsDefault"
                  placeholder=""
                  defaultValue={{ label: "True", value: true }}
                  onChange={(event: any) => {
                    this.setState({
                      filterIsDefault: event.value,
                    });
                  }}
                  options={Array.from(this.state.isDefaultDB).map(
                    (value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    }
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.searchData}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.addLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.addLoading && (
                    <span className="indicator-label">ADD FILTER</span>
                  )}
                </button>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.removeSearch}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.filterLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.filterLoading && (
                    <span className="indicator-label">RESET FILTER</span>
                  )}
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="operationTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Priority
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Default
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={this.state.editOpen}
            onClose={this.editClose}
            role="modal"
            center
            classNames={{
              root: "content-wrapper",
            }}
          >
            <div className="modal-header p-2 pb-4 mb-4">
              <h4 className="modal-title fs-1">Edit Operation</h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label fs-4 required fw-bold">
                    Name
                  </label>
                  <input
                    className="form-control"
                    name="name"
                    id="name"
                    autoComplete="off"
                    defaultValue={this.state.name}
                    onChange={(event: any) => {
                      this.setState({
                        name: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label d-block fs-4 required fw-bold">
                    Priority
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="priority"
                    name="priority"
                    autoComplete="off"
                    defaultValue={this.state.priority}
                    onChange={(e) =>
                      this.setState({
                        priority: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label d-block fs-4 ps-1 pb-2 required fw-bold">
                    Is Default
                  </label>
                  {Array.from(this.state.isDefaultDB).map(
                    (value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            id="isDefault"
                            name="isDefault"
                            value={value.id}
                            defaultChecked={value.id === this.state.isDefault}
                            onChange={(e: any) => {
                              if (e.target.value === "true") {
                                this.setState({
                                  isDefault: true,
                                });
                              } else {
                                this.setState({
                                  isDefault: false,
                                });
                              }
                            }}
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer mt-6 p-0 justify-content-start">
              <button
                type="submit"
                onClick={() => {
                  return this.submitEdit(this.state.currentlyOpenRecord);
                }}
                className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
              >
                {this.state.loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please Wait..
                    <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                  </span>
                )}
                {!this.state.loading && (
                  <span className="indicator-label">UPDATE</span>
                )}
              </button>
            </div>
          </Modal>
          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>
      </div>
    );
  }
}
