import {FC, useState, Suspense} from "react";
import Modal from "react-responsive-modal";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import {useLazyLoadQuery} from "react-relay";
import toast, {Toaster} from "react-hot-toast";
import {LocationHistory_getLocationQuery} from "./ViewLocation/__generated__/LocationHistory_getLocationQuery.graphql";
import {getLocationById} from "./ViewLocation/LocationHistory";
import {locationBusinessHourValidationSchema} from "./CreateLocationHelper";
import "react-datetime/css/react-datetime.css";
import {updateLocationBusinessHours} from "../../models/_location";
import moment from "moment";
import { BusinessHourForm } from "./BusinessHourForm";

type Props = {
  currentLocationId: string;
  handleLocationBusinessHoursClose: () => void;
  updateLocationBusinessHoursModal: boolean;
  refreshTable?: any;
};

export const UpdateLocationBusinessHours: FC<Props> = ({
  currentLocationId,
  updateLocationBusinessHoursModal,
  handleLocationBusinessHoursClose,
  refreshTable,
}) => {
  const navigate = useNavigate();
  if (!currentLocationId) {
    navigate("/locations/list");
  }
  const locationRecord = useLazyLoadQuery<LocationHistory_getLocationQuery>(
    getLocationById,
    {
      id: currentLocationId ?? "",
    },
    {
      fetchPolicy: "network-only",
    }
  );
  const locations: any = locationRecord?.getLocation ?? [];
  const Time = locations.businessHours;
  const locationBusinessHours = Time.map((a: any, index: any) => {
    return {
      dayOfWeek: a.dayOfWeek || index,
      openTime: a.isOpen
        ? moment(a.openTime, "HH:mm:ss").format("hh:mm A")
        : undefined,
      closeTime: a.isOpen
        ? moment(a.closeTime, "HH:mm:ss").format("hh:mm A")
        : undefined,
      isOpen: a.isOpen || false,
    };
  });
  if (locations == null) {
    navigate("/locations/list");
  }
  const [loading, setLoading] = useState(false);
  return (
    <Suspense>
      <Modal
        open={updateLocationBusinessHoursModal}
        onClose={() => handleLocationBusinessHoursClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            businessHours: locationBusinessHours,
          }}
          validationSchema={locationBusinessHourValidationSchema}
          onSubmit={async (values, {resetForm, setSubmitting}) => {
            setLoading(true);
            const businessHourData: any = [];
            const businessHourValues = values?.businessHours.filter((item: any) => item !== undefined);
            [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day, index) => {
              const current = businessHourValues.filter(
                (x: any) => x.dayOfWeek === index
              );
              if (current && current?.length > 0 && current[0]?.isOpen) {
                businessHourData.push(current[0]);
              } else {
                businessHourData.push({
                  isOpen: false,
                  dayOfWeek: index,
                  openTime: null,
                  closeTime: null,
                });
              }
              return true;
            });
            const updateData: any = {
              locationId: locations.id,
              businessHours: businessHourData,
            };
            const result = await updateLocationBusinessHours(updateData);
            if (result?.data?.data?.updateLocationBusinessHours) {
              toast.success("Location Business Hours updated successfully");
              setLoading(false);
              handleLocationBusinessHoursClose();
              resetForm();
              if (typeof refreshTable !== "undefined") {
                refreshTable();
              }
            } else {
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">
                  Update Business Hours - {locations?.uuid ?? ""}
                </h4>
              </div>
              <div className="modal-body">
                <BusinessHourForm values={values} setFieldValue={setFieldValue}/>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{display: "block"}}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && <span className="indicator-label">UPDATE</span>}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
