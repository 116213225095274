import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateMarketingMaterialInput {
  name: string;
  priority: number;
}
export async function CreateMarketingMaterial(
  input: CreateMarketingMaterialInput
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createMarketingMaterial (
            $input: CreateMarketingMaterialInput!
          ){
            createMarketingMaterial(
                createMarketingMaterialInput: $input
              )  {
                id
                name
                createdAt
                updatedAt
                priority
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getMarketingMaterials() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                  query {
                    getMarketingMaterials{
                        id
                        name
                        createdAt
                        updatedAt
                        priority
                      }
                    }
                  `,
      variables: {},
    },
  });
  return data?.data?.data?.getMarketingMaterials;
}

interface UpdateMarketingMaterialInput {
  name: string;
  priority: number;
}
export async function UpdateMarketingMaterial(input: UpdateMarketingMaterialInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              mutation updateMarketingMaterial (
                $input: UpdateMarketingMaterialInput!
              ){
                updateMarketingMaterial(
                      updateMarketingMaterialInput: $input
                  )  {
                    id
                    name
                    createdAt
                    updatedAt
                    priority
                  }
              }
              `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
