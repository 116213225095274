import React ,{FC , Suspense}from 'react'
import Modal from 'react-responsive-modal';
import QRCodeCanvas from "qrcode.react";
import { KTSVG } from "../../../_metronic/helpers";

type Props ={
    qrcodeModal:boolean
    handleCloseQrcode:() => void
    currentMachineId:any 
}

export const  QrcodeModal:FC<Props> =({
    qrcodeModal,
    handleCloseQrcode,
    currentMachineId,
})  =>{
  
  const queryUrl = `${process.env.REACT_APP_FEEDBACK_URL}?currentMachineId=${currentMachineId}`
  const  downloadQRCode = async () => {
        let canvas = document.getElementById("qrCode") as HTMLCanvasElement;
        if (!canvas) throw new Error("<canvas> not found in DOM");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
     
  return (
    <div>
       <Suspense>
          <Modal
            open={qrcodeModal}
            onClose={() => handleCloseQrcode()}
            role="modal"
            center
            classNames={{
                root: "content-wrapper",
            }}
            >
             <div className="modal-header p-2 pb-4 mb-4">
                      <h4 className="modal-title fs-1">QR CODE</h4>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-xl-12col-lg-6 col-md-6 col-12 mt-2 pt-2">
                          <QRCodeCanvas
                            className=""
                            id="qrCode"
                            level="H"
                            size={260}
                            value={queryUrl}
                          />
                        </div>
                      </div>
                      <div className="row my-5">
                        <div className="col-lg-12 col-md-12 col-12 mt-2 pt-2">
                          <button
                            className="btn btn-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3  text-lg-center text-md-center text-xxl-center text-xl-center"
                            onClick={() => downloadQRCode()}
                          >
                            <KTSVG
                              path="/media/icons/duotune/files/fil021.svg"
                              className="svg-icon-4 me-2"
                            />
                            Download QR Code
                          </button>
                        </div>
                      </div>
                    </div> 
            </Modal>
            </Suspense>
    </div>
  )
}

export default  QrcodeModal
