import React, {useRef, useEffect, useState} from "react";
import * as d3 from "d3";

// Define the interface for data
interface BarChartProps {
  data: {
    day: string;
    averageBlends: number;
  }[];
  isExportMode?: boolean;
}

const DayWiseBarChart: React.FC<BarChartProps> = ({
  data,
  isExportMode = false,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState<{width: number; height: number}>(
    {
      width: 0,
      height: 0,
    }
  );

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentWidth = isExportMode
          ? 680
          : svgRef.current.parentElement?.clientWidth ?? 660;
        setDimensions({
          width: parentWidth - 60, // Adjust for margins
          height: 400,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial dimensions

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current) return;

    const {width, height} = dimensions;
    const margin = {top: 40, right: -20, bottom: 70, left: 60};

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the x and y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.day))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.averageBlends) ?? 0])
      .nice()
      .range([height, 0]);

    const colorMapping: {[key: string]: string} = {
      "Average Blends": "#70c057",
    };

    // Define the color scale using the specified colors
    const colorScale = d3
      .scaleOrdinal<string>()
      .domain(data.map((d) => d.day))
      .range(Object.values(colorMapping));

    // Add the X axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "12px");

    // Add X axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 17)
      .attr("text-anchor", "middle")
      .text("Days of the Week")
      .style("font-size", "12px");

    // Add the Y axis
    svg.append("g").call(d3.axisLeft(y));

    // Add Y axis label
    svg
      .append("text")
      .attr("x", -(height / 2))
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Average Blends")
      .style("font-size", "12px");

    // Add chart title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .text("Average Blends Per Day")
      .style("font-size", "16px")
      .style("font-weight", "bold");

    // Create tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "5px")
      .style("border-radius", "3px")
      .style("opacity", 0)
      .style("pointer-events", "none")
      .style("box-shadow", "0px 0px 6px rgba(0, 0, 0, 0.1)");

    // Add bars with interactivity
    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.day) ?? 0)
      .attr("y", (d) => y(d.averageBlends))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(d.averageBlends))
      .attr("fill", (d) => colorScale(d.day))
      .on("mouseover", function (event, d) {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Day: ${d.day}<br>Average Blends: ${d.averageBlends}`)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", function (event, d) {
        d3.select(this).attr("fill", colorScale(d.day));
        tooltip.transition().duration(500).style("opacity", 0);
      });

    // Add text values inside the bars
    svg
      .selectAll(".label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => (x(d.day) ?? 0) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.averageBlends) + 15) // Position inside the bar
      .attr("text-anchor", "middle")
      .text((d) => Math.round(d.averageBlends)) // Display rounded values
      .style("fill", "white") // Make text visible on colored bars
      .style("font-size", "12px")
      .style("font-weight", "bold");

    // Add legend
    const legend = svg
      .append("g")
      .attr("transform", `translate(0, ${height + 51})`);

    const legendItem = legend
      .selectAll(".legend-item")
      .data(Object.keys(colorMapping))
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => `translate(${i * 150}, 0)`);

    legendItem
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 18)
      .attr("height", 8)
      .attr("fill", (d) => colorMapping[d]);

    legendItem
      .append("text")
      .attr("x", 25)
      .attr("y", 4)
      .attr("dy", ".35em")
      .text((d) => d)
      .style("font-size", "12px");

    return () => {
      d3.select(".tooltip").remove();
    };
  }, [data, dimensions]);

  return <svg ref={svgRef} style={{width: "100%", height: "auto"}} />;
};

export default DayWiseBarChart;
