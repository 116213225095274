/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Suspense } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import DashboardTable from "./DashboardTable";
import { DashboardCard } from "./DashboardCard";

const DashboardPage: FC = () => (
  <>
    <Suspense>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Dashboard
            </h1>
          </div>
        </div>
      </div>
      <DashboardCard />
      <DashboardTable />
    </Suspense>
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
