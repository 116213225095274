import React from "react";
import Moment from "moment";
import _ from "lodash";
import { Link } from 'react-router-dom';

const TicketsOverview = (props: { tickets: any }) => {
  const { tickets } = props;
  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Tickets</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total tickets : {tickets.length ? tickets.length : 0}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Ticket ID</th>
                      <th className="fs-5 text-muted">Ticket Type</th>
                      <th className="fs-5 text-muted">Machine</th>
                      <th className="fs-5 text-muted">Ticket Source</th>
                      <th className="fs-5 text-muted">Service Type</th>
                      <th className="fs-5 text-muted">Comment</th>
                      <th className="fs-5 text-muted">Priority</th>
                      <th className="fs-5 text-muted">Created By</th>
                      <th className="fs-5 text-muted">Reported At</th>
                      <th className="fs-5 text-muted">Status</th>
                      <th className="fs-5 text-muted">End Time</th>
                      <th className="fs-5 text-muted">Assign To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length > 0 ? (
                      Array.from(tickets).map((ticket: any, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view ticket"
                                to={`/tickets/list/viewticket/${ticket.id}`}
                              >
                                {ticket.uuid ?? ""}
                              </Link>
                            </td>
                            <td>
                              {_.capitalize(
                                _.lowerCase(ticket.ticketType ?? "")
                              )}
                            </td>
                            <td>{ticket.machine?.name ?? ""}</td>
                            <td>{ticket.ticketSource?.name ?? ""}</td>
                            <td>
                              {_.capitalize(
                                _.lowerCase(ticket.serviceType ?? "")
                              )}
                            </td>
                            <td>{ticket.comment ?? ""}</td>
                            <td>
                              {_.capitalize(_.lowerCase(ticket.priority ?? ""))}
                            </td>
                            <td>{ticket.createdBy?.firstName ?? ""}</td>
                            <td>
                              {ticket.reportedAt ? Moment.unix(ticket.reportedAt / 1000)
                                .local()
                                .format("YYYY-MM-DD HH:mm") : ""}
                            </td>
                            <td>
                              {_.capitalize(_.lowerCase(ticket.status ?? ""))}
                            </td>
                            <td>
                              {ticket.closingDateAndTime ? Moment.unix(ticket.closingDateAndTime / 1000)
                                .local()
                                .format("YYYY-MM-DD HH:mm") : ""}
                            </td>
                            <td>{ticket.assignTo?.firstName ?? ""}</td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td
                            align="center"
                            className="text-center align-align-items-center justify-content-center"
                            colSpan={11}
                          >
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsOverview;
