import React,{Suspense} from "react";
import Moment from "moment";
import _ from "lodash";

const CustomerOverview = (props: { customer: any }) => {
  const { customer } = props;

  return (
   <Suspense>

    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9">Customer Overview</h3>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Type Of Contact Phone Number
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(
                      _.lowerCase(customer.typeOfContactPhoneNumber ?? "")
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Customer Contact Name Role
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {customer.customerContactNameRole}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Account Type
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(_.lowerCase(customer.accountType))}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Distributor
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {customer.distributor?.name ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Customer Status
                </label>
                <div className="col-lg-6 col-md-6 fv-row ps-0">
                  <span className="fw-bold fs-6 ms-2">
                    {customer.isActive === true ? (
                      <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                        Active
                      </span>
                    ) : (
                      <span className="badge badge-light-danger fw-bolder me-auto px-4 py-2">
                        Inactive
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                 Is VIP
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {customer.isVip === true ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  CreatedAt
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {Moment.utc(customer.createdAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
              </div>

              <div className="row">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Created By
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {customer.createdBy?.firstName ?? ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
            <h4 className="fw-bolder">Second Contact</h4>
              <div className="separator separator-dashed my-4"></div>
              {customer.customerSecondContact === true ? (
                <>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Name
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {customer.customerSecondContactName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {customer.customerSecondContactPhoneNumber}
                      </span>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Type Of Second Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {_.capitalize(
                          _.lowerCase(customer.typeOfSecondContactPhoneNumber)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Email
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {customer.customerSecondContactEmail}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Name Role
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {customer.customerSecondContactNameRole}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="fs-6 text-muted">
                  No records found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
   
   </Suspense>
  );
};

export default CustomerOverview;
