import React, {useEffect, useRef} from "react";
import * as d3 from "d3";

// Define the data type
interface TableChartProps {
  data: {
    machine: string;
    month: string;
    consumption: string;
  }[];
}

// Define a type for table rows
interface TableRow {
  machine: string;
  [month: string]: string;
}

const MonthlyConsumptionByMachine: React.FC<TableChartProps> = ({data}) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tableRef.current) return;

    // Extract unique machines and months
    const machines = Array.from(new Set(data.map((d) => d.machine)));
    const months = Array.from(new Set(data.map((d) => d.month)));

    // Transform the data
    const tableData: TableRow[] = machines.map((machine) => {
      const row: TableRow = {machine};
      months.forEach((month) => {
        const entry = data.find(
          (d) => d.machine === machine && d.month === month
        );
        row[month] = entry ? entry.consumption : ""; // Use empty string if no data
      });
      return row;
    });

    // Calculate totals
    const totals: TableRow = {machine: "Total"};
    months.forEach((month) => {
      const monthTotal = tableData.reduce((sum, row) => {
        const value = parseFloat(row[month] || "0");
        return sum + value;
      }, 0);
      totals[month] = monthTotal.toFixed(0); // Format the total to no decimal places
    });

    // Clear previous content
    const tableContainer = d3.select(tableRef.current);
    tableContainer.selectAll("*").remove();

    // Create table
    const table = tableContainer
      .append("table")
      .style("width", "100%")
      .style("border-collapse", "collapse");

    const thead = table.append("thead");
    const tbody = table.append("tbody");

    // Add table headers
    thead
      .append("tr")
      .selectAll("th")
      .data(["machine", ...months])
      .enter()
      .append("th")
      .text((d) => d)
      .style("border", "1px solid #ddd")
      .style("padding", "12px")
      .style("background-color", "#f4f4f4")
      .style("text-align", "center")
      .style("font-weight", "bold");

    // Add table rows
    const rows = tbody
      .selectAll("tr")
      .data([...tableData, totals]) // Add the totals row to the data
      .enter()
      .append("tr");

    rows
      .selectAll("td")
      .data((row) => ["machine", ...months].map((header) => row[header] || ""))
      .enter()
      .append("td")
      .text((d) => d)
      .style("border", "1px solid #ddd")
      .style("padding", "8px")
      .style("text-align", "center");

    // Adjust container height and scrollbar
    d3.select(tableRef.current)
      .style("overflow-y", "auto")
      .style("max-height", "600px"); // Increased height for vertical scrollbar
  }, [data]);

  return (
    <div
      ref={tableRef}
      style={{
        maxWidth: "100%",
        overflowY: "auto",
        maxHeight: "600px", // Increased height for vertical scrollbar
      }}
    ></div>
  );
};

export default MonthlyConsumptionByMachine;
