import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import "react-responsive-modal/styles.css";
import { createBusinessModel } from "../../../models/_businessModel";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  isActive: yup.string().required("Is active is required"),
});

function CreateBusinessModel() {
  const [loading, setLoading] = useState(false);
  const isActiveDB = [
      { id: true, name: "Yes" },
      { id: false, name: "No" }
  ];
  return (
    <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Business Model
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">
                Create Business Model
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            name: "",
            isActive: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const $inputData: any = {
              name: values.name,
              isActive: values.isActive,
            };
            const result = await createBusinessModel($inputData);
            if (result?.data?.data?.createBusinessModel) {
              toast.success("Business model created successfully");
              setSubmitting(false);
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="form-label fs-4 required fw-bold ps-1">
                      Is Active
                    </label>
                    {Array.from(isActiveDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="isActive"
                            className="form-check-input"
                            type="radio"
                            name="isActive"
                            value={value.id}
                            checked={values.isActive === value.id}
                            onChange={() =>
                              setFieldValue("isActive", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="isActive" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 px-xl-5 p-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD BUSINESS MODEL</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </div>
  )
}

export default CreateBusinessModel;