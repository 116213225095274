import { Component } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { getPermissions, getRoles, updatePermission } from "../../models/_user";
import Modal from "react-responsive-modal";
import toast, { Toaster } from "react-hot-toast";

export default class RoleList extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      roleList: [],
      roleListObj: {},
      editOpen: false,
      loading: false,
      permissionDB: [],
      permissions: [],
      currentPermission: [],
      currentlyOpenRecord: {},
      roleId: "",
      permissionCurrent: [],
      setPermission: [],
      currentName: "",
    };
  }

  async componentWillMount() {}

  async componentDidMount() {
    var _ = this;
    $("#roleTable").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.editOpen(record);
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  async componentDidUpdate() {}

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    $(document).ready(function () {
      const getPermissions: any = localStorage.getItem("permissions");
      const permissions = getPermissions || [];
      $("#roleTable").DataTable().destroy();

      let table = $("#roleTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "name",
            render: function (data, type, row) {
              return row.name;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-role") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit role"><button class="btn btn-secondary btn editBtn m-1 p-2 px-4" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              return returnData;
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        // order: [[0, "desc"]],
        destroy: true,
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
      if (permissions?.includes("update-role") === false) {
        table.column(2).visible(false);
      }
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getData = async () => {
    const roleListObj: any = {};
    const records = await getRoles();
    const rows = [];
    let counter = 0;
    const permissionRecord = await getPermissions();
    const permissionResults: any = permissionRecord ? permissionRecord : [];

    for (const record of records) {
      counter++;
      const tempRecord = {
        id: counter,
        recordId: record.id,
        name: record.name,
        // permission: permissionCurrent,
        record: record,
      };
      rows.push(tempRecord);
      roleListObj[record.id] = tempRecord;
    }
    this.setState({
      roleList: rows,
      roleListObj: roleListObj,
      permissionDB: permissionResults,
    });
    return rows;
  };
  //edit Ticket Open
  editOpen = (recordId: any) => {
    const record = this.state.roleListObj[recordId];
    const permissionCurrent =
      record.record.permissions?.map((a: any) => a.id) ?? [];
    this.setState({
      roleId: record.record.id,
      currentName: record.name,
      permissions: permissionCurrent,
      currentPermission: permissionCurrent,
      currentlyOpenRecord: record.record,
      editOpen: true,
    });
  };
  editClose = () => {
    this.setState({
      recordId: "",
      currentName: "",
      permissions: "",
      currentlyOpenRecord: {},
      editOpen: false,
    });
  };
  submitPermission = async (record: any) => {
    let hasErrors = false;
    if (this.state.permissions.length === 0) {
      hasErrors = true;
      toast.error("Permissions is required!");
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const result = await updatePermission(
      this.state.roleId,
      this.state.permissions
    );

    if (result?.data?.data?.updateRolePermissions) {
      toast.success("Permission updated successfully");
      this.setState({
        loading: false,
      });
      this.editClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      this.setState({
        loading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Roles List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Roles List</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="roleTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr.No
                        </th>

                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {/*permission update */}
                <Modal
                  open={this.state.editOpen}
                  onClose={this.editClose}
                  role="modal"
                  center
                  classNames={{
                    root: "content-wrapper",
                  }}
                >
                  <div className="modal-header p-2 pb-4">
                    <h4 className="modal-title fs-1">
                      Edit Role - {this.state.currentName}
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12 p-2 pe-xl-5 ps-xl-4 pb-2 mt-2">
                        <div className="row">
                          <div className="form-label fs-4 required fw-bold">
                            Permissions
                          </div>
                          {Array.from(this.state.permissionDB).map(
                            (value: any, index) => {
                              return (
                                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                  <label
                                    className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                    key={index}
                                  >
                                    <input
                                      id="permissions"
                                      type="checkbox"
                                      name={value.id}
                                      value={value.id}
                                      defaultChecked={this.state.currentPermission?.includes(
                                        value.id
                                      )}
                                      className="form-check-input mb-2 me-1"
                                      onChange={(e: any) => {
                                        const value = e.target.value;
                                        const index =
                                          this.state.permissions.indexOf(value);

                                        if (index > -1) {
                                          this.setState({
                                            permissions: [
                                              ...this.state.permissions.slice(
                                                0,
                                                index
                                              ),
                                              ...this.state.permissions.slice(
                                                index + 1
                                              ),
                                            ],
                                          });
                                        } else {
                                          this.setState({
                                            permissions: [
                                              ...this.state.permissions,
                                              ...[value],
                                            ],
                                          });
                                        }
                                      }}
                                    />
                                    <span className="ms-1 mb-2 fs-5 form-check-label">
                                      {value.name}
                                    </span>
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer p-0 justify-content-start">
                    <button
                      type="submit"
                      onClick={() => {
                        return this.submitPermission(
                          this.state.currentlyOpenRecord
                        );
                      }}
                      className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                    >
                      {this.state.loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!this.state.loading && (
                        <span className="indicator-label">SUBMIT</span>
                      )}
                    </button>
                  </div>
                </Modal>
                {/* toast message */}
                <Toaster
                  position="bottom-left"
                  reverseOrder={false}
                  gutter={8}
                  containerClassName=""
                  containerStyle={{}}
                  toastOptions={{
                    className: "",
                    duration: 3000,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
