/**
 * @generated SignedSource<<cea020db9414636b7378c7506bbe1ebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardTable_PilotMachinesQuery$variables = {};
export type DashboardTable_PilotMachinesQuery$data = {
  readonly pilotMachines: ReadonlyArray<{
    readonly createdAt: any | null;
    readonly deploymentDate: string;
    readonly deploymentType: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly id: string;
    readonly isActive: boolean;
    readonly location: {
      readonly deploymentManager: {
        readonly firstName: string;
        readonly id: string;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly region: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null;
    readonly macAddress: string | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly trialEndDate: string | null;
    readonly updatedAt: any | null;
    readonly uuid: string | null;
  }>;
};
export type DashboardTable_PilotMachinesQuery = {
  response: DashboardTable_PilotMachinesQuery$data;
  variables: DashboardTable_PilotMachinesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "pilotMachines",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "macAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deploymentDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trialEndDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserObjectType",
            "kind": "LinkedField",
            "name": "deploymentManager",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RegionObjectType",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DeploymentTypeObjectType",
        "kind": "LinkedField",
        "name": "deploymentType",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardTable_PilotMachinesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardTable_PilotMachinesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9e629f31ca79338dc9dfc51fd5eb1ee0",
    "id": null,
    "metadata": {},
    "name": "DashboardTable_PilotMachinesQuery",
    "operationKind": "query",
    "text": "query DashboardTable_PilotMachinesQuery {\n  pilotMachines {\n    id\n    uuid\n    serialNumber\n    name\n    macAddress\n    deploymentDate\n    createdAt\n    updatedAt\n    isActive\n    trialEndDate\n    location {\n      id\n      name\n      deploymentManager {\n        id\n        firstName\n      }\n      region {\n        id\n        name\n      }\n    }\n    deploymentType {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14a478eac95c18a87f2b8902cd748fb8";

export default node;
