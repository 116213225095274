import moment from "moment";
import * as yup from "yup";

export interface BusinessHour {
  isOpen: boolean | false;
  dayOfWeek: number;
  openTime: string | null;
  closeTime: string | null;
}

export interface ICreateLocation {
  name: string;
  customerId: string;
  regionId: string;
  locationTypeId: string;
  filterProviderId: string;
  marketSegmentId: string;
  businessModelId: string;
  gasSupplierId:string;
  deploymentManagerId: string;
  targetLaunchDate: Date;
  samplingRecommendedDays?: string;
  samplingRecommendedTime?: string;
  noOfBlenders: 1;
  noOfFreezers: 1;
  isActive: true;
  distributorOther: string;
  address: string;
  credentials: string;

  customerSecondContact: boolean;
  customerSecondContactName: string;
  customerSecondContactPhoneNumber: string;
  customerSecondContactEmail: string;
  customerSecondContactNameRole: string;
  secondContactPhoneType: string | null;

  itContact: boolean;
  itContactName: string;
  itContactPhoneNumber: string;
  itContactEmail: string;
  itContactNameRole: string;
  itContactPhoneType: string | null;

  serviceContact: boolean;
  serviceContactName: string;
  serviceContactPhoneNumber: string;
  serviceContactEmail: string;
  serviceContactNameRole: string;
  serviceContactPhoneType: string | null;

  storeManagerContact: boolean;
  storeManagerContactName: string;
  storeManagerContactPhoneNumber: string;
  storeManagerContactEmail: string;
  typeOfStoreManagerContactPhoneNumber: string | null;
  locationBusinessHours: boolean;
  businessHours: BusinessHour[];
}

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const businessHourValidation = yup.array().of(
  yup.object({
    isOpen: yup.boolean().nullable(),
    openTime: yup.string().nullable().when('isOpen', {
      is: true,
      then: yup.string()
        .nullable()
        .required('Open time is required')
        .test('is-valid-time', 'Open time must be a valid time', (value) => moment(value, 'hh:mm A', true).isValid()),
      otherwise: yup.string().nullable().notRequired()
    }),
    closeTime: yup.string().nullable().when('isOpen', {
      is: true,
      then: yup.string()
        .nullable()
        .required('Close time is required')
        .test('is-valid-time', 'Close time must be a valid time', (value) => moment(value, 'hh:mm A', true).isValid())
        .test('is-close-after-open', 'Close time must be greater than open time', function(value) {
          const { openTime } = this.parent;
          if (openTime && value) {
            return moment(value, 'hh:mm A').isAfter(moment(openTime, 'hh:mm A'));
          }
          return true;
        }),
      otherwise: yup.string().nullable().notRequired()
    })
  }).nullable()
).nullable();

const locationValidationSchema = yup.object({
  name: yup.string().required("Location name is required"),
  noOfBlenders: yup.number().required("Total blenders is required"),
  noOfFreezers: yup.number().required("Total freezers is required"),
  businessModelId:  yup.string().required("Business model is required"),
  gasSupplierId: yup.string().when(['marketSegmentId'], {
    is: (marketSegmentId: any) => marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47',
    then: yup.string().required("Gas Supplier is required"),
  }),
  customerSecondContact: yup.boolean(),
  secondContactPhoneType: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),
  customerSecondContactName: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  customerSecondContactPhoneNumber: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  customerSecondContactEmail: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  customerSecondContactNameRole: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),

  itContact: yup.boolean(),
  itContactName: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  itContactPhoneNumber: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  itContactEmail: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  itContactNameRole: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  itContactPhoneType: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  serviceContact: yup.boolean(),
  serviceContactName: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  serviceContactPhoneNumber: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  serviceContactEmail: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  serviceContactNameRole: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  serviceContactPhoneType: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  storeManagerContact: yup.boolean(),
  storeManagerContactName: yup.string().when("storeManagerContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  storeManagerContactPhoneNumber: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  storeManagerContactEmail: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  typeOfStoreManagerContactPhoneNumber: yup
    .string()
    .when("storeManagerContact", {
      is: true,
      then: yup.string().required("Contact phone type is required"),
    }),
  businessHours: businessHourValidation,
});

const locationBusinessHourValidationSchema = yup.object({
  businessHours: businessHourValidation,
});

const inits: ICreateLocation = {
  name: "",
  customerId: "",
  regionId: "",
  locationTypeId: "",
  filterProviderId: "",
  marketSegmentId: "",
  businessModelId: "",
  gasSupplierId:"",
  deploymentManagerId: "",
  targetLaunchDate: new Date(),
  samplingRecommendedDays: "",
  samplingRecommendedTime: "",
  noOfBlenders: 1,
  noOfFreezers: 1,
  isActive: true,
  distributorOther: "",
  address: "",
  credentials: "",

  customerSecondContact: false,
  customerSecondContactName: "",
  customerSecondContactPhoneNumber: "",
  customerSecondContactEmail: "",
  customerSecondContactNameRole: "",
  secondContactPhoneType: "",

  itContact: false,
  itContactName: "",
  itContactPhoneNumber: "",
  itContactEmail: "",
  itContactNameRole: "",
  itContactPhoneType: "",

  serviceContact: false,
  serviceContactName: "",
  serviceContactPhoneNumber: "",
  serviceContactEmail: "",
  serviceContactNameRole: "",
  serviceContactPhoneType: "",

  storeManagerContact: false,
  storeManagerContactName: "",
  storeManagerContactPhoneNumber: "",
  storeManagerContactEmail: "",
  typeOfStoreManagerContactPhoneNumber: "",
  locationBusinessHours: false,
  businessHours: []
};

export {
  locationValidationSchema,
  inits,
  locationBusinessHourValidationSchema
};
