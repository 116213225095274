/* eslint-disable jsx-a11y/anchor-is-valid */
import Moment from "moment";
import * as _ from "lodash";

const TicketActivity = (props: { ticket: any }) => {
  const { ticket } = props;
  const History = ticket.history ?? [];

  return (
    <div className="card">
      <h3 className="card-title align-items-start flex-column">
        <div className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0">
          Activities
        </div>
      </h3>

      <div className="card-body pt-5 p-3">
        {History.map((value: any) => {
          return (
            <div className="timeline-label pb-9">
              <div className="timeline-item">
                <div className="timeline-label fw-semibold text-gray-800 fs-6">
                  {Moment.utc(value.createdAt)
                    .local()
                    .format("YYYY-MM-DD HH:mm")}
                </div>
                <div className="timeline-badge">
                  {value.action === "CREATE_SUB_TASK" ||
                  value.action === "CREATE" ? (
                    <i className="fa fa-genderless text-danger fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "UPDATE_SUB_TASK" ||
                  value.action === "UPDATE" ? (
                    <i className="fa fa-genderless text-info fs-1"></i>
                  ) : (
                    " "
                  )}
                  {value.action === "SUB_TASK_STATUS_CHANGE" ||
                  value.action === "STATUS_CHANGE" ? (
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "SUB_TASK_ASSIGNEE_CHANGE" ||
                  value.action === "ASSIGNEE_CHANGE" ? (
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "ASSIGNEE_ADDED" ||
                  value.action === "ASSIGNEE_ADDED" ? (
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "DELETE" ||
                  value.action === "ASSIGNEE_REMOVED" ? (
                    <i className="fa fa-genderless text-danger fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "DELETE" ||
                  value.action === "JOB_STATUS_CHANGE" ? (
                    <i className="fa fa-genderless text-saffron fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "SUB_TASK_PRIORITY_CHANGE" ||
                  value.action === "PRIORITY_CHANGE" ? (
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  ) : (
                    ""
                  )}
                  {value.action === "UPDATE_SIGNATURE" && (
                    <i className="fa fa-genderless text-success fs-1"></i>
                  )}
                  {value.action === "DELETE" ||
                  value.action === "DELETE_SUB_TASK" ? (
                    <i className="fa fa-genderless text-danger fs-1"></i>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fw-semibold timeline-content">
                  <div className="fw-semibold text-muted ps-3 fs-3">
                    Action : {_.capitalize(_.lowerCase(value.action))}
                  </div>
                  <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                    {value.difference.split("\n").map((i: any, key: any)=> {
                      if(i.trim() !== "")  {
                        return <li key={key}>{i}</li>
                      } 
                        return "";
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { TicketActivity };
