import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { resetPassword } from "../core/_requests";

const initialValues = {
  newPassword: "",
  confirmNewPassword:"",
};

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New password is required")
    .min(3)
    .max(50),
  confirmNewPassword: Yup.string()
    .required("Confirm new password is required")
    .min(3)
    .max(50)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<any>('');
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(true);
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      
      setLoading(true);
      try {
        const result = await resetPassword(token,values.newPassword,values.confirmNewPassword);

        if (result?.data?.data?.resetForgotPassword) {
          resetForm();
          setHasErrors(false);
          setLoading(false);
          navigate('/auth/login?reset_password=true');
        } else {
          setStatus(result?.data?.errors[0].message);
          setSubmitting(false);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setStatus("Password details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);
    const tokenValue = urlParams.get('token')?.trim();
    setToken(tokenValue);
  }, []);

  return (
    <>
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Reset Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Please enter and confirm your new password to complete the reset process.
        </div>
        {/* end::Link */}
      </div>
      
      {/* end::Title */}
      {formik.status ? (
        <div className="mb-lg-10 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        ""
      )}
      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
         New Password
        </label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("newPassword")}
          className={clsx(
            "text-transform form-control",
            {
              "is-invalid": formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              "is-valid": formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className="fv-plugins-message-container text-danger ms-1">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-8">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
         Confirm New Password
        </label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("confirmNewPassword")}
          className={clsx(
            "text-transform form-control",
            {
              "is-invalid": formik.touched.confirmNewPassword && formik.errors.confirmNewPassword,
            },
            {
              "is-valid": formik.touched.confirmNewPassword && !formik.errors.confirmNewPassword,
            }
          )}
        />
        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
          <div className="fv-plugins-message-container text-danger ms-1">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmNewPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={formik.isSubmitting || !formik.isValid}
        >
         

          {!loading && <span className="indicator-label fs-6">SUBMIT</span>}

          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light fs-6"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            CANCEL
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
   </>
  );
}