import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;



export async function getBlendLogs(where: {} = {}, pagination: {} | undefined = undefined ,customFilter:{}={}) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendLogs(
                $where: BlendLogResolver_GetBlendLogs_FilterInputType,
                $paginate: PaginatorArgs
                $customFilter:BlendLogCustomFilterType!
                ) {
                    getBlendLogs(where: $where, paginate: $paginate ,customFilter:$customFilter) {
                        data{
                          id
                        machineId
                        locationId
                        blendDate
                        thickness
                        blendDuration
                        flavor
                        feedback
                        machine {
                          id
                          name
                       }
                        location {
                          id
                          name
                        }
                      }
                      totalPages
                      total
                      perPage
                      page
                      }
              }
              `,
        variables: { where, paginate: pagination ,customFilter },
      },
    });
    return data?.data?.data?.getBlendLogs;
  }
  export async function getBlendSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendSummary(
                $filter :BlendSummaryInput!
                ) {
                  getBlendSummary(filter: $filter) {
                      averageBlend
                      averageUpTime
                      machines {
                        machineId
                        locationId
                        deploymentTypeId
                        deploymentTypeName
                        machineName
                        locationName
                        regionId
                        regionName
                        blendCount
                        hasHeartbeat
                        deploymentManagerId
                        deploymentManagerName
                        distributorId
                        distributorName
                        tickets
                        machineUpTimeInMinutes
                        totalDownTimeThroughTickets
                        lastHeartbeatAt
                        kpi
                        upTimePercentage
                        downTimePercentage
                      }
                      }
              }
              `,
        variables: { filter},
      },
    });
    return data?.data?.data?.getBlendSummary;
  }

  export async function getAllTimeBlendSummary(filter:{} = {} ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getAllTimeBlendSummary(
                $filter : AllTimeBlendSummaryInput!
                ) {
                  getAllTimeBlendSummary(filter: $filter) {
                    averageBlend
                    totalMachines
                    allMachinesTotalBlend
                    machines {
                      machineId
                      locationId
                      machineName
                      locationName
                      regionId
                      regionName
                      blendCount
                      hasHeartbeat
                      deploymentManagerId
                      deploymentManagerName
                      deploymentTypeId
                      deploymentTypeName
                      distributorId
                      distributorName
                      tickets
                      }
                      }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.getAllTimeBlendSummary;
  }
  export async function getCustomerSatisfactionSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendCustomerSatisfactionSummary(
                $filter :BlendSummaryInput!
                ) {
                  getBlendCustomerSatisfactionSummary(filter: $filter) {
                    averageRating
                    totalFeedback
                    feedbackByFlavorSummary {
                      flavor
                      averageRating
                      totalFeedback
                    }
                  flavorFeedbackCount {
                    flavor
                    count
                    feedback
                  }
                  machineWiseAvgFeedback {
                    machineName
                    averageRating
                  }
                  machineWiseFlavorFeedbackCount {
                    totalGG
                    totalBB
                    totalTT
                    machineName
                    machineId
                  }
                      }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.getBlendCustomerSatisfactionSummary;
  }