import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getDashboardUrl() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
                query {
                  getQuickSightDashboardURL
                }
              `,
        variables: {},
      },
    });
    return data?.data?.data?.getQuickSightDashboardURL;
  }
  export async function getBlendSummary(filter: {} = {}) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `query getBlendSummary(
                $filter:BlendSummaryInput!
        
          ){
            getBlendSummary(filter: $filter) {
              averageBlend
              machines {
               machineId
                deploymentManagerId
                deploymentManagerName
               locationId
               machineName
               locationName
               regionId
               regionName
               blendCount
               hasHeartbeat
             }
          }
        }`,
        variables: { filter  },
      },
    });
    return data?.data?.data?.getBlendSummary;
  }
  