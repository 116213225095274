import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getUnassignedTickets(unAssignedTicketListInput: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getUnassignedTickets($unAssignedTicketListInput: UnAssignedTicketFilterDto!) {
                getUnassignedTickets(unAssignedTicketListInput: $unAssignedTicketListInput) {
                    id
                    hashId
                    uuid
                    ticketType
                    machineId
                    scheduleStartDate
                    scheduleEndDate
                    closingDateAndTime
                    machine {
                    id
                    uuid
                    serialNumber
                    macAddress
                    name
                      location{
                        id
                        name
                        customer{
                          id
                          name
                        }
                      }
                    }
                    parentId
                    ticketSourceId
                    reportedAt
                    assignToId
                    assignTo {
                      id
                      firstName
                      lastName
                      email
                    }
                    issuesReported {
                      id
                      name 
                    }
                    daysOpen
                    issueTypeId
                    priority
                    comment
                    status
                    createdBy {
                      id
                      firstName
                      lastName
                    }
                }
              }
              `,
      variables: { unAssignedTicketListInput },
    },
  });
  return data?.data?.data?.getUnassignedTickets;
}

interface AssignTicketInput {
  id: string;
  assignToId: string;
  scheduleStartDate: string;
  scheduleEndDate: string;
}
export async function assignTicket(input: AssignTicketInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation assignTicket (
            $input: AssignTicketInput!
          ){
            assignTicket(
                assignTicketInput: $input
              ) {
                id
                hashId
                uuid
                ticketType
                machineId
                machine {
                  id
                  uuid
                  serialNumber
                  macAddress
                  name
                  location{
                    id
                    name
                  }
                }
                assignTo {
                  id
                  firstName
                }
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getScheduledTickets(scheduledTicketListInput: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getScheduledTickets($scheduledTicketListInput: ScheduleTicketFilterDto!) {
                getScheduledTickets(scheduledTicketListInput: $scheduledTicketListInput) {
                  id
                  hashId
                  uuid
                  ticketType
                  machineId
                  reportedAt
                  scheduleStartDate
                  scheduleEndDate
                  closingDateAndTime
                  status
                  machine {
                    id
                    uuid
                    serialNumber
                    macAddress
                    name
                    deploymentDate
                    location{
                      id
                      name
                      customer{
                        id
                        name
                      }
                    }
                  }
                  issuesReported {
                    id
                    name 
                  }
                  daysOpen
                  scheduleStartDate
                  scheduleEndDate
                  travelingStartTime
                  travelingEndTime
                  workStartTime
                  parentId
                  ticketSourceId
                  reportedAt
                  assignToId
                  assignTo {
                    id
                    firstName
                    lastName
                  }
                }
              }
              `,
      variables: { scheduledTicketListInput },
    },
  });
  return data?.data?.data?.getScheduledTickets;
}



export async function cancelScheduleTicket(ticketId: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation cancelScheduleTicket (
            $ticketId: String!
          ){
            cancelScheduleTicket(
              ticketId: $ticketId
              ) {
                id
                uuid
                ticketType
                machineId
                machine {
                  id
                  uuid
                  serialNumber
                  macAddress
                  name
                  location{
                    id
                    name
                  }
                }
                assignTo {
                  id
                  firstName
                }
              }
          }
          `,
      variables: {
        ticketId,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}