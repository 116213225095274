import React,{Suspense,useCallback} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import clsx from "clsx";
import  { Toaster } from "react-hot-toast";

import LocationOverview from "./Tabs/LocationOverview";
import MachineOverview from "./Tabs/MachineOverview";
import JobsOverview from "./Tabs/JobsOverview";
import TicketsOverview from "./Tabs/TicketsOverview";
import Timeline from "./Tabs/Timeline";
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { LocationHistory_getLocationQuery } from './__generated__/LocationHistory_getLocationQuery.graphql';
import {EditLocation} from '../EditLocation'
export const getLocationById = graphql`
               query LocationHistory_getLocationQuery($id: String!) {
             getLocation(id: $id) {
                     id
                      uuid
                      customerId
                      name
                      address
                      regionId
                      gasSupplierId
                      contactName
                      contactPhone
                      contactEmail
                      latitude
                      longitude
                      zipCode
                      state
                      city
                      waterPressure
                      marketSegmentId
                      deploymentManagerId
                      businessModelId
                      locationTypeId
                      targetLaunchDate
                      noOfBlenders
                      noOfFreezers
                      distributorId
                      samplingRecommendedTime
                      samplingRecommendedDays
                      createdById
                      createdAt
                      updatedById
                      updatedAt
                      isActive
                      machineCount
                      customerSecondContact
                      secondContactName
                      secondContactPhoneNumber
                      secondContactEmail
                      secondContactNameRole
                      customerItContact
                      itContactName
                      itContactPhoneNumber
                      itContactEmail
                      itContactNameRole
                      customerServiceContact
                      serviceContactName
                      serviceContactPhoneNumber
                      serviceContactEmail
                      serviceContactNameRole
                      typeOfContactPhoneNumber
                      typeOfSecondContactPhoneNumber
                      typeOfItContactPhoneNumber
                      typeOfServiceContactPhoneNumber
                      storeManagerContact
                      storeManagerContactName
                      storeManagerContactPhoneNumber
                      storeManagerContactEmail
                      typeOfStoreManagerContactPhoneNumber
                      gasSupplier {
                        id
                        name
                      }
                      wifiCredentials {
                        name
                        password
                      }
                      freezerCredentials {
                        apiKey
                        serialNumber
                      }
                      wifiSignalStrength
                      businessModel {
                        id
                        name
                      }
                      distributor{
                        id
                        name
                      }
                      createdBy {
                        id
                        firstName
                        lastName
                      }
                      updatedBy {
                        id
                        firstName
                        lastName
                      }
                      marketSegment {
                        id
                        name
                      }
                      filterProvider {
                        id
                        title
                      }
                      deploymentManager {
                        id
                        firstName
                        lastName
                      }
                      locationType {
                        id
                        name
                      }
                      region {
                        id
                        name
                      }
                      customer {
                        id
                        name
                        uuid
                        customerCompanyName
                        customerContactName
                        customerContactPhoneNumber
                        customerContactEmail
                        customerContactNameRole
                        accountType
                        distributorId
                        customerSecondContact
                        customerSecondContactName
                        customerSecondContactPhoneNumber
                        customerSecondContactEmail
                        customerSecondContactNameRole
                        isActive
                      }
                      flavorPrices {
                        flavor
                        price
                      }
                      logJobs {
                        id
                        uuid
                        jobType
                        locationId
                        dateAndTime
                        machineSerialNumber
                        machineName
                        additionalComments
                        createdById
                        createdBy{
                          id
                          firstName
                        }
                        createdAt
                        updatedById
                        updatedAt
                        customerSatisfaction
                        cleanliness
                        trafficRate
                        staffEnthusiasm
                        status
                        closingDateAndTime
                        travelTime
                      }
                      filterProviderId
                      machines{
                        id
                        uuid
                        serialNumber
                        name
                        deploymentDate
                        locationId
                        deployingPersonId
                        deploymentTypeId
                        softwareVersion
                        hardwareVersion
                        connectionStatus
                        createdById
                        createdAt
                        updatedById
                        updatedAt
                        isActive
                        remark
                        retiredAt
                        retiredById
                        isRetired
                        
                          retiredBy {
                            id
                            firstName
                            lastName
                      
                          }
                        createdBy {
                          id
                          firstName
                        }
                        deployingPerson {
                          id
                          firstName
                        }
                        deploymentType {
                          id
                          name
                        }
                     
                        tickets{
                          id
                          uuid
                          machine{
                            id
                            name
                          }
                          ticketType
                          serviceType
                          comment
                          priority
                          ticketSource{
                            id
                            name
                          }
                          createdBy{
                            id
                            firstName
                          }
                          reportedAt
                          closingDateAndTime
                          assignTo{
                            id
                            firstName
                          }
                          travelTime
                          trafficRate
                          timeSpentOnTask
                          status
                          staffEnthusiasm
                        }
                      }
                      businessHours {
                        id
                        locationId
                        dayOfWeek
                        openTime
                        closeTime
                        isOpen
                        createdAt
                        updatedAt
                      }
                    }
    }
  `;

export const LocationHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const splittedRoute = location.pathname.split("/").reverse()[0];
  if (!splittedRoute) {
    navigate("/locations/list");
  }
  const [machines, setMachines] = React.useState<any>({});
  const [customers, setCustomers] = React.useState<any>({});
  const [locations, setLocations] = React.useState<any>({});
  const [logJobs, setLogJobs] = React.useState<any>([]);
  const [tickets, setTickets] = React.useState<any>([]);
  const [tab, setTab] = React.useState("Overview");
  const [locationEditModal, setLocationEditModal] = React.useState(false);
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState<any>(null);

  const locationRecord =
  useLazyLoadQuery<LocationHistory_getLocationQuery>(
    getLocationById,
    {
      id: splittedRoute ?? "",
    },refreshedQueryOptions ?? {}
  );
  const result: any = locationRecord?.getLocation ?? [];
 
  const  currentLocationId = result.id
  const fetchData = async () => {
    if (result == null) {
      navigate("/locations/list");
    } else {
      setLocations(result);
      const customer = result.customer ?? {};
      setCustomers(customer);
      const totalJobs = result.logJobs ?? [];
      setLogJobs(totalJobs);
      const totalMachines = result.machines ?? [];
      setMachines(totalMachines);
      let allTickets: any = [];

      for (const machine of totalMachines) {
        const ticketsRecord = machine.tickets ?? [];
        allTickets = [...allTickets, ...ticketsRecord];
      }
      setTickets(allTickets);
    }
  };

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);
  const handleLocationClose= () => {
    setLocationEditModal(false)
    refresh()
  }
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
  
  return (
    <Suspense>
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Location History - {locations.uuid}
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item text-muted">
                <span className="bullet bg-gray-400 w-5px h-2px" />
                <a
                  href="/locations/list"
                  className="text-muted text-hover-primary ps-2 fs-5"
                >
                  Locations List
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item fs-5 text-dark">
                Location History
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-xl-4 mt-9">
        <div className="card">
          <div className="card-header cursor-pointer border-bottom-0 py-4">
            <div className="card-title m-0">
              <h3 className="fw-semibold m-0">Location Details - {result.uuid}</h3>
            </div>
            <button  data-bs-toggle="tooltip" title="Click here to edit location"
              onClick={() => {setLocationEditModal(true)}}
              className="btn btn-secondary btn px-5 py-3 m-1">
              <i className="fa fa-pencil fs-4" ></i>
              </button>
             </div>
          </div>
          </div>
        
          {locationEditModal && (
             <EditLocation  locationEditModal={locationEditModal}  
             handleLocationClose={handleLocationClose}
             currentLocationId={currentLocationId}
             />
          )}
      <div className="card mt-9 mx-xl-4">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src="/media/icons/duotune/maps/map008.svg"
                  alt="Smoodi"
                  className="bg-light"
                />
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column pb-lg-0 pb-7">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {locations.name}
                    </div>
                  </div>

                  <div className="fw-bold fs-6 mb-3 ms-2 pe-2 mt-2">
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-map-marker me-3 fs-6"></i>
                      Location Name :
                      <div className="ms-3 text-gray-800">{locations.name}</div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-address-card me-3 fs-6"></i>
                      Location Address :
                      <div className="ms-3 text-gray-800">
                        {locations.address}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-800 fs-4 fw-bolder text-dark mb-3">
                      Customer History :
                    </div>
                    <Link
                      to={`/customers/list/history/${customers.id}`}
                   
                      className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0"
                    >
                      <i className="fa fa-user me-3 fs-6"></i>
                      Customer Name :
                      <div className="ms-3 text-primary">
                        {locations.contactName} - {customers.uuid}
                        <i className="fa fa-external-link ms-3"></i>
                      </div>
                    </Link>
                    <Link
                      to={`/customers/list/history/${customers.id}`}
                      className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0"
                    >
                      <i className="fa fa-phone me-3 fs-6"></i>
                      Contact Phone :
                      <div className="ms-3 text-gray-800 text-hover-primary">
                        {locations.contactPhone}
                      </div>
                    </Link>
                  </div>
                  <Link
                    to={`/customers/list/history/${customers.id}`}
                    className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0"
                  >
                    <i className="fa fa-envelope me-3 fs-6"></i>
                    Contact Email :
                    <div className="ms-3 text-gray-800 text-hover-primary">
                      {locations.contactEmail}
                    </div>
                  </Link>
                </div>
                {locations.latitude !== "" && locations.longitude !== "" && (
                  <div className="d-flex w-200px w-sm-300px flex-column mb-3">
                    <div className="card h-100">
                      <div className="card-body p-0">
                        <div className="responsive-map-container">
                          <iframe
                            allowFullScreen
                            title="location-address"
                            src={`//maps.google.com/maps?q=${locations.latitude},${locations.longitude}&z=15&output=embed`}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="my-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {machines.length ?? 0}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Machines
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {logJobs.length ?? 0}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">Jobs</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-xl-4">
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {tickets.length ?? 0}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Tickets
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            <ul
              className="nav nav-stretch nav-line-tabs mt-5 border-transparent flex-nowrap fs-5 fw-bolder"
              role="tablist"
            >
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Overview",
                  })}
                  onClick={() => setTab("Overview")}
                  role="tab"
                >
                  Overview
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Machine",
                  })}
                  onClick={() => setTab("Machine")}
                  role="tab"
                >
                  Machines
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Jobs",
                  })}
                  onClick={() => setTab("Jobs")}
                  role="tab"
                >
                  Log Jobs
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Tickets",
                  })}
                  onClick={() => setTab("Tickets")}
                  role="tab"
                >
                  Tickets
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Timeline",
                  })}
                  onClick={() => setTab("Timeline")}
                  role="tab"
                >
                  Timeline
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="tab-content pt-3">
        <div className={clsx("tab-pane", { active: tab === "Overview" })}>
          <LocationOverview locations={locations} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Machine" })}>
          <MachineOverview machines={machines} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Jobs" })}>
          <JobsOverview logJobs={logJobs} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Tickets" })}>
          <TicketsOverview tickets={tickets} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Timeline" })}>
          <Timeline machines={machines} logJobs={logJobs} tickets={tickets} />
        </div>
      </div>
    </div>
    <Toaster
     position="bottom-left"
     reverseOrder={false}
     gutter={8}
     containerClassName=""
     containerStyle={{}}
     toastOptions={{
       className: "",
       duration: 3000,
     }}
   />
    </Suspense>
  );
};
