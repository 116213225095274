import React from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { Tooltip } from 'react-tooltip'
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { DashboardCard_getCountsQuery } from "./__generated__/DashboardCard_getCountsQuery.graphql";
import { DashboardCard_totalTicketsCountQuery } from "./__generated__/DashboardCard_totalTicketsCountQuery.graphql";
import { DashboardCard_machinesCountByStatusQuery } from "./__generated__/DashboardCard_machinesCountByStatusQuery.graphql";
const getCounts = graphql`
  query DashboardCard_getCountsQuery {
    getCounts {
      totalCustomerCount
      totalLocationCount
      totalMachineCount
    }
  }
`;
const getTotalTicketsCount = graphql`
  query DashboardCard_totalTicketsCountQuery {
    totalTicketsCount {
      totalTicketCount
      inProcessTicketCount
      onHoldTicketCount
      pendingTicketCount
      completedTicketCount
      pendingInstallationLocationCount
    }
  }
`;

const getMachinesCountByStatus = graphql`
  query DashboardCard_machinesCountByStatusQuery {
    machinesCountByStatus {
      deployedMachines
      commerciallyDeploy
      readyToDeploy
      inRepair
    }
  }
`;

export const DashboardCard = () => {
  const [totalCount, setTotalCount] = React.useState<any>([]);
  const [machinesCountByStatus, setMachinesCountByStatus] = React.useState<any>({});
  const [totalTicketCount, setTotalTicketCount] = React.useState<any>([]);

  const getCountsRecord = useLazyLoadQuery<DashboardCard_getCountsQuery>(
    getCounts,
    {}
  );
  const getTotalTicketsCountRecord =
    useLazyLoadQuery<DashboardCard_totalTicketsCountQuery>(
      getTotalTicketsCount,
      {}
    );

    const getMachinesCountByStatusRecord =
    useLazyLoadQuery<DashboardCard_machinesCountByStatusQuery>(
      getMachinesCountByStatus,
      {}
    );
  const getCountsResult: any = getCountsRecord.getCounts;
  const totalTicketsCountResult: any =
    getTotalTicketsCountRecord.totalTicketsCount;

  

  const fetchData = async () => {
    try {
      setTotalCount(getCountsResult);
      setTotalTicketCount(totalTicketsCountResult);
      setMachinesCountByStatus(getMachinesCountByStatusRecord.machinesCountByStatus ?? {} )
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div className="d-flex flex-stack flex-grow-1">
                <div className="d-flex flex-column me-2">
                  <a
                    href="/customers/list"
                    className="totalCustomer text-dark text-hover-success fw-bold fs-3"
                  >
                    Total Customers
                    <i className="fas fa-info-circle fs-4 ms-2"></i>
                  </a>
                  
                  <Tooltip anchorSelect=".totalCustomer" place="top-end" className="tooltip-dashboard">
                   The current count of unique billing customers who have purchased and are currently using  a smoodi machine
                  </Tooltip>

                  <span className="text-muted fw-semibold mt-1">
                    Count of total customers
                  </span>
                </div>

                <span className="symbol symbol-50px">
                  <span className="symbol-label fs-2 fw-bold bg-light-success text-success">
                    {totalCount.totalCustomerCount}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div className="d-flex flex-stack flex-grow-1">
                <div className="d-flex flex-column me-2">
                  <a
                    href="/locations/list"
                    className="totalLocation text-dark text-hover-danger fw-bold fs-3"

                  >
                    Total Locations
                    <i className="fas fa-info-circle fs-4 ms-2"></i>
                  </a>
                  <Tooltip anchorSelect=".totalLocation" className="tooltip-dashboard">
                  The current count of unique geographical locations who have purchased and are currently using  a smoodi machine
                  </Tooltip>
                  <span className="text-muted fw-semibold mt-1">
                    Count of total locations
                  </span>
                </div>

                <span className="symbol symbol-50px">
                  <span className="symbol-label fs-2 fw-bold bg-light-danger text-danger">
                    {totalCount.totalLocationCount}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div className="d-flex flex-stack flex-grow-1">
                <div className="d-flex flex-column me-2">
                  <a
                    href="/machines/list"
                    className="totalMachine text-dark text-hover-info fw-bold fs-3"
                  >
                    Total machines deployed 
                    <i className="fas fa-info-circle fs-4 ms-2"></i>
                  </a>
                  <Tooltip anchorSelect=".totalMachine" className="tooltip-dashboard">
                  The total count of smoodi machines that are currently active in commercial setting
                  </Tooltip>
                  <span className="text-muted fw-semibold mt-1" style={{ fontSize: "14px" }}>
                    Commercially deployed: {machinesCountByStatus?.commerciallyDeploy ?? 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br />
                    Ready to deploy: {machinesCountByStatus?.readyToDeploy ?? 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    In repair: {machinesCountByStatus?.inRepair ?? 0}
                  </span>
                  
                </div>

                <span className="symbol symbol-50px">
                  <span className="symbol-label fs-2 fw-bold bg-light-info text-info">
                  {machinesCountByStatus?.deployedMachines ?? 0}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-9 mx-auto justify-content-xl-between">
        <div className="col-xl-3  col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-info mb-4">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr065.svg"
                    className="svg-icon-2x svg-icon-info"
                  />
                </span>
              </span>
              <Link
                to={{ pathname: "/locations/list", search: "?pending_installation_location=true" }}
                className="text-dark fw-bold fs-2 mt-5 mb-0 text-hover-info"
              >
                Pending Installation Location
              </Link>
              <br />
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.pendingInstallationLocationCount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-success mb-4">
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-2x svg-icon-success"
                  />
                </span>
              </span>
              <Link
                to="/tickets/list"
                className="totalTickets text-dark fw-bold fs-2 mt-5 mb-0 text-hover-success"
              >
                Total Tickets
                <i className="fas fa-info-circle fs-4 ms-2"></i>
              </Link>
              <Tooltip anchorSelect=".totalTickets" className="tooltip-dashboard">
              The total number of open service tickets for issues currently preventing the machines from operating.
                  </Tooltip>
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.totalTicketCount}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-2  col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-info mb-4">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr024.svg"
                    className="svg-icon-2x svg-icon-info"
                  />
                </span>
              </span>
              <Link
                to={{ pathname: "/tickets/list", search: "?status=IN_PROCESS" }}
                className="text-dark fw-bold fs-2 mt-5 mb-0 text-hover-info"
              >
                In-Process Tickets
              </Link>
              <br />
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.inProcessTicketCount}
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-xl-2  col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-danger mb-4">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr014.svg"
                    className="svg-icon-2x svg-icon-danger"
                  />
                </span>
              </span>
              <Link
                to={{ pathname: "/tickets/list", search: "?status=ON_HOLD" }}
                className="text-dark fw-bold fs-2 mt-5 mb-0 text-hover-danger"
              >
                On-Hold Tickets
              </Link>
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.onHoldTicketCount}
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-xl-3  col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-warning mb-4">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr025.svg"
                    className="svg-icon-2x svg-icon-warning"
                  />
                </span>
              </span>
              <Link
                to={{ pathname: "/tickets/list", search: "?status=PENDING" }}
                className="pendingTickets text-dark fw-bold fs-2 mt-5 mb-0 text-hover-warning"
              >
                Pending Tickets
                <i className="fas fa-info-circle fs-4 ms-2"></i>
              </Link>
              <Tooltip anchorSelect=".pendingTickets" className="tooltip-dashboard">
              The number of machines deployed by in commercial locations in the past 7 days.
                  </Tooltip>
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.pendingTicketCount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3  col-lg-4 col-md-6 col-12 mb-4">
          <div className="card mb-xl-6 h-100">
            <div className="p-7 card-body flex-grow-1">
              <span className="symbol symbol-50px me-2 d-block">
                <span className="symbol-label bg-light-success mb-4">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr084.svg"
                    className="svg-icon-2x svg-icon-success"
                  />
                </span>
              </span>
              <Link
                to={{ pathname: "/tickets/list", search: "?status=COMPLETED" }}
                className="completeTickets text-dark fw-bold fs-2 mt-5 mb-0 text-hover-success"
              >
                Completed Tickets
                <i className=" fas fa-info-circle fs-4 ms-2"></i>
              </Link>
              <Tooltip anchorSelect=".completeTickets" className="tooltip-dashboard">
              The total number of service tickets resolved and closed by our team in the past 7 days
                  </Tooltip>
              <div className="text-muted fw-semibold mt-1 fs-2">
                {totalTicketCount.completedTicketCount}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
