import React, {useRef, useEffect} from "react";
import * as d3 from "d3";

interface BarChartProps {
  data: {
    flavor: string;
    consumptionRate: string;
  }[];
}

const ConsumptionByFlavorChart: React.FC<BarChartProps> = ({data}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current || data.length === 0) return;

    const margin = {top: 40, right: 30, bottom: 70, left: 150};
    const width = 1800 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const formattedData = data.map((d) => ({
      flavor: d.flavor,
      consumptionRate: Math.floor(parseFloat(d.consumptionRate)), // Round down to remove decimals
    }));

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(formattedData, (d) => d.consumptionRate) ?? 0])
      .range([0, width]);

    const y = d3
      .scaleBand()
      .domain(formattedData.map((d) => d.flavor))
      .range([0, height])
      .padding(0.2);

    const color = d3
      .scaleOrdinal<string>()
      .domain(formattedData.map((d) => d.flavor))
      .range(data.map((d: any) => d.flavorColorCode));

    // Add the X axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle") // Center-align the text
      .style("font-size", "12px"); // Adjust font size if needed

    svg
      .append("g")
      .call(d3.axisLeft(y).tickSize(0))
      .selectAll("text")
      .style("font-size", "12px");

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .text("Consumption Rate by Flavor")
      .style("font-size", "18px")
      .style("font-weight", "bold");

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "8px")
      .style("border-radius", "5px")
      .style("opacity", 0)
      .style("pointer-events", "none");

    svg
      .selectAll(".bar")
      .data(formattedData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", 0)
      .attr("y", (d) => y(d.flavor)!)
      .attr("width", 0)
      .attr("height", y.bandwidth())
      .attr("fill", (d) => color(d.flavor)!)
      .transition()
      .duration(800)
      .attr("width", (d) => x(d.consumptionRate));

    svg
      .selectAll(".label")
      .data(formattedData)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) =>
        x(d.consumptionRate) - 10 > 0
          ? x(d.consumptionRate) - 10
          : x(d.consumptionRate) + 5
      ) // Adjust label position based on bar width
      .attr("y", (d) => y(d.flavor)! + y.bandwidth() / 2)
      .attr("dy", ".35em")
      .text((d) => d.consumptionRate) // No decimal places
      .style("font-size", "12px")
      .style("fill", (d) => (x(d.consumptionRate) > 20 ? "white" : "black")) // Change text color based on bar width
      .attr("text-anchor", (d) =>
        x(d.consumptionRate) > 20 ? "end" : "start"
      );

    svg
      .selectAll(".bar")
      .on("mouseover", function (event, d: any) {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Flavor: ${d.flavor}<br>Consumption Rate: ${d.consumptionRate}`)
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 40}px`);
      })
      .on("mousemove", function (event: MouseEvent) {
        tooltip
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 40}px`);
      })
      .on("mouseout", function () {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    const legend = svg
      .append("g")
      .attr("transform", `translate(0, ${height + 40})`);

    const legendItem = legend
      .selectAll(".legend-item")
      .data(data.map((x: any) => x))
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => `translate(${i * 150}, 0)`);

    legendItem
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 18)
      .attr("height", 8)
      .attr("fill", (d) => d.flavorColorCode);

    legendItem
      .append("text")
      .attr("x", 25)
      .attr("y", 4)
      .attr("dy", ".35em")
      .text((d) => d.flavor)
      .style("font-size", "12px");

    return () => {
      tooltip.remove();
    };
  }, [data]);

  return (
    <div>
      <svg ref={svgRef} />
    </div>
  );
};

export default ConsumptionByFlavorChart;
