import _ from "lodash";
import React, { useEffect } from "react";
import Moment from "moment";

const Timeline = (props: { machines: any; logJobs: any; tickets: any }) => {
  const { machines } = props;
  const { logJobs } = props;
  const { tickets } = props;
  const [history, setHistory] = React.useState<any>([]);

  useEffect(() => {
    const fetchData = () => {
      const machineDetail = Array.from(machines).map((record: any) => {
        return {
          activity: `Machine name ${record.name} with serial number ${record.serialNumber} is created by ${
            record.createdBy?.firstName ?? ""
          }`,
          action: "Machine Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
        };
      });

      const jobsDetail = Array.from(logJobs).map((record: any) => {
        return {
          activity: `Log job of job type ${_.capitalize(
            _.lowerCase(record.jobType)
          )} is created by ${record.createdBy?.firstName ?? ""}`,
          action: "Log Job Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
        };
      });

      const ticketsDetail = Array.from(tickets).map((record: any) => {
        return {
          activity: `Ticket of machine ${
            record.machine?.name ?? ""
          } is created by ${record.createdBy?.firstName ?? ""}`,
          action: "Ticket Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
        };
      });
      const allHistory = [...machineDetail, ...jobsDetail, ...ticketsDetail];
      const sortedRecord = allHistory.sort(function (a, b) {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });
      setHistory(sortedRecord);
    };

    fetchData();
    // eslint-disable-next-line
  }, [history]);

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Timeline</span>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-9">
              {history.length > 0 ? (
                history.map((value: any) => {
                  return (
                    <div className="timeline-label pb-9">
                      <div className="timeline-item">
                        <div className="timeline-label fw-semibold text-gray-800 fs-6">
                          {Moment.utc(value.createdAt)
                            .local()
                            .format("YYYY-MM-DD HH:mm")}
                        </div>
                        <div className="timeline-badge">
                          {value.action === "Ticket Created" ? (
                            <i className="fa fa-genderless text-danger fs-1"></i>
                          ) : (
                            ""
                          )}
                          {value.action === "Log Job Created" ? (
                            <i className="fa fa-genderless text-info fs-1"></i>
                          ) : (
                            " "
                          )}
                          {value.action === "Machine Created" ? (
                            <i className="fa fa-genderless text-success fs-1"></i>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="fw-semibold timeline-content">
                          <div className="fw-semibold text-muted ps-3 fs-4">
                            Action : {value.action}
                          </div>
                          <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                            {value.activity}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="fs-6 text-muted">No records found</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
