import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import { createUser, getRoles } from "../../models/_user";

const validationSchema = yup.object({
  firstName: yup.string().required("User name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().required("Email is required").email("Enter valid email"),
  isDeveloper: yup.string().required("User ota update is required"),
  roleId: yup.string().required("Role is required"),
  password: yup.string().required("Password is required"),
});

const CreateUser = () => {
  const resetRole = React.useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [rolesDB, setRolesDB] = useState([]);

  const [accessDB] = useState([
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
  ]);

  const fetchdata = async () => {
    try {
      const roleRecord = await getRoles();
      const roleResults: any = roleRecord ? roleRecord : [];
      roleResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setRolesDB(roleResults);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create User
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create User</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            slackId: "",
            roleId: "",
            password: "",
            isDeveloper: "",
            isRegionalManager: false,
            canHandleServiceTicket: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);

            let $inputData: any = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              roleId: values.roleId,
              password: values.password,
              isDeveloper: values.isDeveloper,
              isRegionalManager: values.isRegionalManager,
              canHandleServiceTicket: values.canHandleServiceTicket,
            };
            const result = await createUser($inputData);

            if (result?.data?.data?.createUser) {
              toast.success("User created successfully");
              resetRole.current.setValue("");
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      First Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.firstName) && touched.firstName,
                      })}
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Last Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.lastName) && touched.lastName,
                      })}
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Email
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.email) && touched.email,
                      })}
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Password
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.password) && touched.password,
                      })}
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">SlackID</label>
                    <input
                      className="form-control"
                      name="slackId"
                      id="slackId"
                      value={values.slackId}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Role
                    </label>
                    <Select
                      ref={resetRole}
                      className={clsx("react-select", {
                        "is-invalid": Boolean(errors.roleId) && touched.roleId,
                      })}
                      classNamePrefix="my-react-select"
                      name="roleId"
                      placeholder=""
                      options={Array.from(rolesDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("roleId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="roleId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold ps-1">
                      Can Access Ota Update
                    </label>
                    {Array.from(accessDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label className="ms-2 fs-5" key={id}>
                          <input
                            id="isDeveloper"
                            className="form-check-input"
                            type="radio"
                            name="isDeveloper"
                            value={value.id}
                            checked={values.isDeveloper === value.id}
                            onChange={() =>
                              setFieldValue("isDeveloper", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="isDeveloper" />
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
                      <input
                        id="isRegionalManager"
                        type="checkbox"
                        name="isRegionalManager"
                        className="form-check-input"
                        value="isRegionalManager"
                        checked={values.isRegionalManager}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Is Regional Manager
                      </span>
                    </label>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
                      <input
                        id="canHandleServiceTicket "
                        type="checkbox"
                        name="canHandleServiceTicket"
                        className="form-check-input"
                        value="canHandleServiceTicket"
                        checked={values.canHandleServiceTicket}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Can Handle Service Ticket?
                      </span>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD USER</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateUser;
