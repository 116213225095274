import React,{FC ,Suspense ,useState ,useEffect } from 'react';
import {getTicketListById} from './ViewTicket';
import {ViewTicket_getTicketQuery} from './__generated__/ViewTicket_getTicketQuery.graphql'
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import SignaturePad from "react-signature-pad-wrapper";
import * as yup from "yup";


import {UpdateTicketSignature} from "../../models/_updateTickets";
 type Props = {
  currentTicketId:string;
    signatureModalOpen:boolean
    handleCloseSiganture:() => void
    refreshTable?:any
 }
 const signatureSchema = yup.object({
    signatureBy:yup.string().required("Name is required"),
   
 })
export const SignatureModal:FC<Props> = ({
  currentTicketId,
  signatureModalOpen,
    handleCloseSiganture,
    refreshTable
}) => {
    const navigate = useNavigate()
    if(!currentTicketId){
        navigate("/tickets/list");
      }

    const TicketRecord =useLazyLoadQuery<ViewTicket_getTicketQuery>(
        getTicketListById,
        {
          id:currentTicketId ?? ""
      
        },{
          fetchPolicy: "network-only",
        }
      )
      const ticket:any = TicketRecord?.getTicket ?? []
      if(ticket == null){
        navigate("/tickets/list");
    }
   
      const [loading, setLoading] = useState(false);

     
      const  [signatureEdit] = useState({
        id:ticket?.id,
        signatureBy:'',
        signatureImgName:{
            name: "",
            content:"",
          }
    })
    let sigPad:any = {}
    
       useEffect(() => {
        // eslint-disable-next-line
        }, []);
  return (
    <div>
       <Suspense>
          <Modal
            open={signatureModalOpen}
            onClose={() => handleCloseSiganture()}
            role="modal"
            center
            classNames={{
                root: "content-wrapper",
            }}
            >
             <Formik
                enableReinitialize
                initialValues={signatureEdit}
                validationSchema={signatureSchema}
                onSubmit = {async (values,{resetForm , setSubmitting}) => {
                    let hasErrors = false;
                 
                    if (sigPad.isEmpty() === true) {
                        hasErrors = true;
                        toast.error("Signature is required!");
                      }
                      if (hasErrors) {
                        return false;
                      }
                      setLoading(true);
                 const signatureImage = new Image();
                  const signatureImgName = "signature.png";
                   signatureImage.src = sigPad.toDataURL();
              
                  const imageArrays: any = {
                    name: signatureImgName,
                    content: signatureImage.src,
                  };
             
                  const updateData = {
                    id: values.id,
                    signatureName:values.signatureBy,
                    signatureImage: imageArrays,
                  };

                  if (imageArrays && imageArrays.length > 0) {
                    updateData["signatureImage"] = imageArrays;
                  }
                  const result = await UpdateTicketSignature(updateData);
                  if (result?.data?.data?.updateTicketSignature) {
                    toast.success("Signature created successfully");
                    setLoading(false)
                   handleCloseSiganture()
                   resetForm()
                   if(typeof refreshTable !== 'undefined'){
                    refreshTable() 
                  }
                  setLoading(false)
                  }else {
                    const messages = result?.data?.errors.map((e: any) => e.message);
                    toast.error(messages.join(","));
                    setLoading(false)
                  }
                }}
                  
              >
                 {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                }) => 
            <form   
            id="kt_modal_add_lesson_form"
            className="form"
            onSubmit={handleSubmit} autoComplete="off"
            noValidate>
                
             <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">
                    Add Signature - {ticket.uuid}
                    </h4>
                </div>
                <div className="modal-body">
            <div className="row">
              <div className="col-12 mt-2 pt-2">
                <label className="form-label required fs-4 fw-bold">
                  Signature By
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  name="signatureBy"
                  id="signatureBy"
                  onChange={handleChange}
                />
                  <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="signatureBy" />
                    </div>
              </div>
              <div className="col-12 mt-3 pt-2">
                <SignaturePad
                  options={{
                    dotSize: 1,
                    minWidth: 3,
                    maxWidth: 8,
                    penColor: "#81B942",
                    backgroundColor: "#CCCCCC",
                  }}
                  ref={(ref: any) => {
                    sigPad = ref;
                  }}
                />
              </div>
            </div>

            <div className="modal-footer mt-6 p-0 justify-content-start">
            <button
              type="submit"
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please Wait..
                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                </span>
              )}
              {!loading && (
                <span className="indicator-label">SUBMIT</span>
              )}
            </button>
          </div>
           
          </div>
       </form>
       
       }
       </Formik>

                </Modal>
                <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
                </Suspense>
    </div>
  )
}

export default SignatureModal
