import { Form, Formik, FormikValues } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  createTicketSchemas,
  ICreateTicket,
  inits,
} from "./CreateTicketHelper";
import { Step1 } from "./steps/Step1";
import Step2 from "./steps/Step2";
import $ from "jquery";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../_metronic/helpers";
import { createTicket } from "../../models/_tickets";
import Moment from "moment";
import Step3 from "./steps/Step3";
import { useNavigate } from "react-router-dom";

const CreateTicket: FC = () => {
  const navigate = useNavigate();
  const resetMachine = useRef<any>(null);
  const resetAssignTo = useRef<any>(null);
  const resetReportedById = useRef<any>(null);
  const resetSource = useRef<any>(null);
  const resetReportedAt = useRef<any>(null);
  const resetClosingDate = useRef<any>(null);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createTicketSchemas[0]);
  const [initValues] = useState<ICreateTicket>(inits);
  const [isSubmitButton, setSubmitButton] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultImages, setResultImages] = useState([]);

  const searchParams = new URLSearchParams(window.location.search);
  const selectedMachineId = searchParams.get('machine_id');
  if (selectedMachineId) {
    initValues.machineId = selectedMachineId;
  }

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: any = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const type = selectedFilesArray[i].type;
      const name = selectedFilesArray[i].name;
      getBase64(selectedFilesArray[i])
        .then((result) => {
          const imagesArray: any = [
            { name: name, type: type, content: result },
          ];
          const resultArray: any = [{ name: name, content: result }];
          setSelectedImages((previousImages) =>
            previousImages.concat(imagesArray)
          );
          setResultImages((previousImages) =>
            previousImages.concat(resultArray)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    event.target.value = "";
  };

  function deleteHandler(image: any) {
    setSelectedImages(
      selectedImages.filter((e: any) => e.content !== image.content)
    );
    setResultImages(
      resultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current.goPrev()
    setCurrentSchema(createTicketSchemas[stepper.current.currentStepIndex - 1])
    if(initValues.ticketType === 'MARKETING_OTHER'){
      setSubmitButton(
        stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1
      );
    }else{
      setSubmitButton(
        stepper.current.currentStepIndex === stepper.current.totalStepsNumber
      );
    }
  };

  const submitStep = async (values: ICreateTicket, actions: FormikValues) => {

    if (!stepper.current) {
      return;
    }

    if(values.ticketType === 'MARKETING_OTHER'){
      setSubmitButton(
        stepper.current.currentStepIndex === stepper.current.totalStepsNumber! - 2)
    }else{
      setSubmitButton(
        stepper.current.currentStepIndex === stepper.current.totalStepsNumber! - 2)
    }
    
    setCurrentSchema(createTicketSchemas[stepper.current.currentStepIndex]);

    // if (values.ticketType !== 'MARKETING_OTHER' && stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
    //   stepper.current.goNext();
    // } else if (values.ticketType === 'MARKETING_OTHER' && stepper.current.currentStepIndex !== stepper.current.totalStepsNumber - 1){
    //   stepper.current.goNext();
    // } else {
    if (stepper.current.currentStepIndex === 1) {
      stepper.current.goNext();
    } else {
      let hasErrors = false;
      if (values.issuesReported?.includes("other")) {
        if (values.issueReported_other === "") {
          hasErrors = true;
          toast.error("Other issue is required");
        }
      }
      if (values.machineServices?.includes("other")) {
        if (values.services_other === "") {
          hasErrors = true;
          toast.error("Other machine services is required");
        }
      }
      if (values.marketingOrOthers?.includes("other")) {
        if (values.marketing_other === "") {
          hasErrors = true;
          toast.error("Other marketing is required");
        }
      }
      if (values.operations?.includes("other")) {
        if (values.operation_other === "") {
          hasErrors = true;
          toast.error("Other operation is required");
        }
      }
      if (!values.reportedAt) {
        hasErrors = true;
        toast.error("Reported at is required");
      }
      if (values.closeTicket === true) {
        if (!values.closingDateAndTime) {
          hasErrors = true;
          toast.error("Closing time is required");
        }
      }
      if (hasErrors) {
        return false;
      }
      const $inputData: any = {
        ticketType: values.ticketType,
        machineId: values.machineId,
        ticketSourceId: values.ticketSourceId,
        reportedAt: Moment(values.reportedAt)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        assignToId: values.assignToId,
        priority: values.priority,
        serviceType: values.serviceType,
        comment: values.comment,
        chatDescription: values.chatDescription,
        machineServices: values.machineServices.filter((a) => a !== "other"),
        marketingOrOthers: values.marketingOrOthers.filter(
          (a) => a !== "other"
        ),
        // issuesReported: values.issuesReported.filter((a: any) => a !== "other"),
        operations: values.operations.filter((a) => a !== "other"),
        marketingMaterials: values.marketingMaterials,
        closingDateAndTime: Moment(values.closingDateAndTime)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        timeSpentOnTask: parseFloat(values.timeSpentOnTask),
        travelTime: parseFloat(values.travelTime),
        customerSatisfaction: null,
        cleanliness: null,
        trafficRate: null,
        staffEnthusiasm: null,
        isClosed: values.closeTicket,
        howTicketClosed: null,
        machineServiceOtherInput: values.services_other,
        operationOtherInput: values.operation_other,
        marketingOtherInput: values.marketing_other,
        issueReportedOtherInput: values.issueReported_other,
        reportedByType: values.reporterType,
        reportedById: values.reportedById,
        reportedByName: values.reporterName,
        reportedByEmail: values.reporterEmail,
        reportedByPhone: values.reporterPhone
      };
      if (values.issuesReported) {
        $inputData["issuesReported"] = values.issuesReported.filter((a: any) => a !== "other")
      }
      if (values.howTicketClosed) {
        $inputData["howTicketClosed"] = values.howTicketClosed;
      }
      if (values.customerSatisfaction) {
        $inputData["customerSatisfaction"] = values.customerSatisfaction;
      }
      if (values.cleanliness) {
        $inputData["cleanliness"] = values.cleanliness;
      }
      if (values.trafficRate) {
        $inputData["trafficRate"] = values.trafficRate;
      }
      if (values.staffEnthusiasm) {
        $inputData["staffEnthusiasm"] = values.staffEnthusiasm;
      }
      if (resultImages && resultImages.length > 0) {
        $inputData["media"] = resultImages;
      }
      setLoading(true);
      const result = await createTicket($inputData);
      if (result?.data?.data?.createTicket) {
        toast.success("Ticket created successfully");
        setLoading(false);
        actions.resetForm();
        resetReportedAt.current.state.inputValue = "";
        resetMachine.current.setValue("");
        resetAssignTo.current.setValue("");
        resetSource.current.setValue("");
        $("input[type=checkbox]").prop("checked", function () {
          $(this).prop("checked", false);
        });
        setSelectedImages([]);
        // stepper.current.goto(1);
        // window.location.reload();
        navigate("/tickets/list");
      } else {
        setSubmitButton(true);
        setLoading(false);
        const messages = result?.data?.errors.map((e: any) => e.message);
        toast.error(messages.join(","));
      }
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);
  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Ticket
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create ticket</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card mx-xl-4 mt-9">
        <div className="card-body w-100 p-lg-7 p-md-7 p-5 pt-0">
          <div
            ref={stepperRef}
            className="stepper stepper-links"
            id="kt_create_account_stepper"
          >
            <div className="stepper-nav">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title d-none">Create Ticket : 1</h3>
              </div>
             {initValues.ticketType !== 'MARKETING_OTHER' && (
              <div className="stepper-item" data-kt-stepper-element="nav">
              <h3 className="stepper-title d-none">Create Ticket: 2</h3>
            </div>
            )} 
     
            
              <div className="stepper-item" data-kt-stepper-element="nav">
                <h3 className="stepper-title d-none">Create Ticket: 3</h3>
              </div>
            </div>
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({ values, handleChange, errors, touched, setFieldValue }) => (
                <Form id="kt_create_account_form">
                  <div className="current" data-kt-stepper-element="content">
                    <Step1
                      resetMachine={resetMachine}
                      resetAssignTo={resetAssignTo}
                      resetReportedById={resetReportedById}
                      resetSource={resetSource}
                      formValues={values}
                      formError={errors}
                      formTouched={touched}
                      setFieldValue={setFieldValue}
                      resetReportedAt={resetReportedAt}
                    />
                  </div>

                  <div data-kt-stepper-element="content">
                    {values.ticketType !== "MARKETING_OTHER" && ( 
                      <Step2 formValues={values} handleChange={handleChange} />
                    )} 
                    <Step3
                      formValues={values}
                      formError={errors}
                      formTouched={touched}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      onSelectFile={onSelectFile}
                      selectedImages={selectedImages}
                      resetClosingDate={resetClosingDate}
                      setSelectedImages={setSelectedImages}
                      deleteHandler={deleteHandler}
                    />
                  </div>

                  <div className="d-flex flex-stack mt-4">
                    <div className="mr-2">
                      <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-lg btn-light-primary me-3"
                        data-kt-stepper-action="previous"
                      >
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr063.svg"
                          className="svg-icon-4 me-1"
                        />
                        BACK
                      </button>
                    </div>

                    <div>
                      <button
                        type="submit"
                        id="formSubmit"
                        className="btn btn-lg btn-primary me-3"
                      >
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please Wait..
                            <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                          </span>
                        )}
                        {!loading && (
                          <>
                            <span className="indicator-label">
                              {!isSubmitButton && "Select the issues"}
                              {isSubmitButton && "SUBMIT"}
                            </span>
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr064.svg"
                              className="svg-icon-4 ms-1"
                            />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
