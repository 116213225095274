import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getVideoTutorials() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getVideoTutorials {
            id
            title
            videoTranscript
            videoUrl
            moduleName
            createdAt
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getVideoTutorials;
}
