import { FC } from "react";
import { ErrorMessage } from "formik";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

type Props = {
  values?: any;
  setFieldValue: (a: any, b: any) => void;
};

export const BusinessHourForm: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className="row">
      <>
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ].map((day, index) => (
          <div className="row" key={index}>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
              <div className="form-check form-switch form-check-custom form-check-solid w-100 mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={values.businessHours[index]?.isOpen === true}
                  onChange={(event) => {
                    const tempBusinessHours = [...values.businessHours];
                    if (typeof tempBusinessHours[index] === "undefined") {
                      tempBusinessHours[index] = {
                        isOpen: false,
                        dayOfWeek: index,
                        openTime: null,
                        closeTime: null,
                      };
                    }
                    tempBusinessHours[index] = {
                      ...tempBusinessHours[index],
                      isOpen: event.target.checked,
                    };

                    setFieldValue("businessHours", tempBusinessHours);
                  }}
                  name="isOpen"
                />

                <label className="ms-2">{day}</label>
              </div>
            </div>
            {values.businessHours[index]?.isOpen ? (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
                  <Datetime
                    value={values.businessHours[index]?.openTime}
                    onChange={(e) => {
                      const tempBusinessHours = [...values.businessHours];
                      if (typeof tempBusinessHours[index] === "undefined") {
                        tempBusinessHours[index] = {
                          isOpen: false,
                          dayOfWeek: index,
                          openTime: moment(e).format("hh:mm A"),
                          closeTime: null,
                        };
                      } else {
                        tempBusinessHours[index] = {
                          ...tempBusinessHours[index],
                          openTime: moment(e).format("hh:mm A"),
                        };
                      }
                      setFieldValue("businessHours", tempBusinessHours);
                    }}
                    timeFormat="hh:mm A"
                    dateFormat={false}
                    closeOnSelect
                    inputProps={{placeholder: "Open Time"}}
                  />
                  <ErrorMessage
                    name={`businessHours[${index}].openTime`}
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
                  <Datetime
                    value={values.businessHours[index]?.closeTime}
                    onChange={(e) => {
                      const tempBusinessHours = [...values.businessHours];
                      if (typeof tempBusinessHours[index] === "undefined") {
                        tempBusinessHours[index] = {
                          isOpen: false,
                          dayOfWeek: index,
                          openTime: null,
                          closeTime: moment(e).format("hh:mm A"),
                        };
                      } else {
                        tempBusinessHours[index] = {
                          ...tempBusinessHours[index],
                          closeTime: moment(e).format("hh:mm A"),
                        };
                      }
                      setFieldValue("businessHours", tempBusinessHours);
                    }}
                    timeFormat="hh:mm A"
                    dateFormat={false}
                    closeOnSelect
                    inputProps={{placeholder: "Close Time"}}
                  />
                  <ErrorMessage
                    name={`businessHours[${index}].closeTime`}
                    component="div"
                    className="text-danger"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-6 mb-3 mb-lg-4 mt-3 ">
                  <div className="p-3 bg-light mt-2">
                    <i className="far fa-moon"></i>
                    <span className="ms-2 text-muted">Closed</span>{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </>
    </div>
  );
};
