import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import $ from "jquery";
import {
  getMachineService,
  getMarketing,
  getOperations,
  getTicket,
} from "../../models/_tickets";
import { useNavigate, useLocation } from "react-router-dom";
import { getUsers } from "../../models/_location";
import { createSubTask, getIssueType } from "../../models/_subtask";

const validationSchema = yup.object({
  issueType: yup.string().required("Issue type is required"),
  assignTo: yup.string().required("Assign to is required"),
});

export interface ICreateSubTask {
  comment: string;
  assignTo: string;
  issueType: string;
  services_other: string;
  marketing_other: string;
  operation_other: string;
  priority: string;
  machineServices: string[];
  operations: string[];
  marketingOrOthers: string[];
}

const inits: ICreateSubTask = {
  comment: "",
  assignTo: "",
  issueType: "",
  services_other: "",
  marketing_other: "",
  operation_other: "",
  priority: "HIGH",
  machineServices: [],
  operations: [],
  marketingOrOthers: [],
};

const CreateSubTask = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetAssignTo = useRef<any>(null);
  const resetIssueType = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [issueTypeDB, setIssueTypeDB] = useState([]);
  const [assignToDB, setAssignToDB] = useState([]);
  const [machineServicesDB, setMachineServicesDB] = useState([]);
  const [operationDB, setOperationDB] = useState([]);
  const [marketingDB, setMarketingDB] = useState([]);
  const [ticket, setTicket] = useState<any>({});
  const [priorityDB] = useState([
    {
      id: "HIGHEST",
      name: "Highest",
    },
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);

  const fetchData = async () => {
    const ticketId = location.pathname.split("/").reverse()[0];
    if (!ticketId) {
      navigate("/tickets/list");
    }
    try {
      const result = await getTicket(ticketId);
      if (result == null) {
        navigate("/tickets/list");
      } else {
        setTicket(result);
      }
    } catch (e) {
      navigate("/tickets/list");
    }

    try {
      const issueTypeRecord = await getIssueType();
      const issueTypeResult: any = issueTypeRecord ? issueTypeRecord : [];
      issueTypeResult.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const assignRecord = await getUsers();
      const assignResult: any = assignRecord ? assignRecord : [];
      assignResult.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const machineServiceRecord = await getMachineService();
      const machineServiceResults: any = machineServiceRecord
        ? machineServiceRecord
        : [];
      machineServiceResults.push({
        id: "other",
        name: "Other",
      });

      const operationRecord = await getOperations();
      const operationResults: any = operationRecord ? operationRecord : [];
      operationResults.push({
        id: "other",
        name: "Other",
      });

      const marketingRecord = await getMarketing();
      const marketingResults: any = marketingRecord ? marketingRecord : [];
      marketingResults.push({
        id: "other",
        name: "Other",
      });

      setIssueTypeDB(issueTypeResult);
      setAssignToDB(assignResult);
      setOperationDB(operationResults);
      setMarketingDB(marketingResults);
      setMachineServicesDB(machineServiceResults);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Task - {ticket.uuid}
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item text-muted">
                <span className="bullet bg-gray-400 w-5px h-2px" />
                <a
                  href="/tickets/list"
                  className="text-muted text-hover-primary ps-2 fs-5"
                >
                  Ticket Logs
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Task</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={inits}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            let hasErrors = false;
            if (values.machineServices?.includes("other")) {
              if (values.services_other === "") {
                hasErrors = true;
                toast.error("Other machine services is required");
              }
            }
            if (values.marketingOrOthers?.includes("other")) {
              if (values.marketing_other === "") {
                hasErrors = true;
                toast.error("Other marketing is required");
              }
            }
            if (values.operations?.includes("other")) {
              if (values.operation_other === "") {
                hasErrors = true;
                toast.error("Other operation is required");
              }
            }
            if (hasErrors) {
              return false;
            }
            setLoading(true);
            const $inputData = {
              parentId: ticket.id,
              assignToId: values.assignTo,
              priority: values.priority,
              issueTypeId: values.issueType,
              comment: values.comment,
              machineServices: values.machineServices.filter(
                (a) => a !== "other"
              ),
              marketingOrOthers: values.marketingOrOthers.filter(
                (a) => a !== "other"
              ),
              operations: values.operations.filter((a) => a !== "other"),
              machineServiceOtherInput: values.services_other,
              operationOtherInput: values.operation_other,
              marketingOtherInput: values.marketing_other,
            };
            const result = await createSubTask($inputData);

            if (result?.data?.data?.createSubTask) {
              toast.success("Task created successfully");
              resetAssignTo.current.setValue("");
              resetIssueType.current.setValue("");
              $("input[type=checkbox]").prop("checked", function () {
                $(this).prop("checked", false);
              });
              setLoading(false);
              resetForm();
              setTimeout(() => {
                navigate("/tickets/list");
          }, 1000);
              
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                    <div className="accordion">
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          data-bs-toggle="tooltip"
                          title="Click here to add machine services needed"
                          id="machine-services"
                        >
                          <button
                            className="accordion-button collapsed py-5 form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#machine-services-needed"
                            aria-expanded="false"
                            aria-controls="machine-services-needed"
                          >
                            Machine Services Needed
                          </button>
                        </h2>
                        <div
                          id="machine-services-needed"
                          className="accordion-collapse collapse"
                          aria-labelledby="machine-services"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body pb-1 p-3">
                            <div className="row">
                              {Array.from(machineServicesDB).map(
                                (value: any, index) => {
                                  return (
                                    <div className="col-lg-6 col-md-6 col-12">
                                      <label
                                        key={index}
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                      >
                                        <input
                                          id="machineServices"
                                          className="form-check-input mb-2 me-1"
                                          type="checkbox"
                                          name={value.id}
                                          value={value.id}
                                          onChange={(e) => {
                                            const { checked, name } = e.target;
                                            if (checked) {
                                              setFieldValue("machineServices", [
                                                ...values.machineServices,
                                                name,
                                              ]);
                                            } else {
                                              setFieldValue(
                                                "machineServices",
                                                values.machineServices.filter(
                                                  (e) => e !== name
                                                )
                                              );
                                            }
                                          }}
                                        />
                                        <span className="ms-2 mb-1 fs-5 form-check-label">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                              <div className="text-danger text-align-top-center mt-2">
                                <ErrorMessage name="machineServices" />
                              </div>
                            </div>

                            {values.machineServices.includes("other") ? (
                              <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                <label className="form-label fs-4 required fw-bold">
                                  Other Machine services
                                </label>
                                <input
                                  id="services_other"
                                  name="services_other"
                                  className={clsx("form-control", {
                                    "is-invalid":
                                      Boolean(errors.services_other) &&
                                      touched.services_other,
                                  })}
                                  value={values.services_other}
                                  onChange={handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                    <div className="accordion">
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          data-bs-toggle="tooltip"
                          title="Click here to add operations"
                          id="operations"
                        >
                          <button
                            className="accordion-button collapsed py-5 form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#operations-needed"
                            aria-expanded="false"
                            aria-controls="operations-needed"
                          >
                            Operations
                          </button>
                        </h2>
                        <div
                          id="operations-needed"
                          className="accordion-collapse collapse"
                          aria-labelledby="operations"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body pb-1 p-3">
                            <div className="row">
                              {Array.from(operationDB).map(
                                (value: any, index) => {
                                  return (
                                    <div className="col-lg-6 col-md-6 col-12">
                                      <label
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                        key={index}
                                      >
                                        <input
                                          id="operations"
                                          type="checkbox"
                                          name={value.id}
                                          className="form-check-input mb-2 me-1"
                                          value={value.id}
                                          onChange={(e) => {
                                            const { checked, name } = e.target;
                                            if (checked) {
                                              setFieldValue("operations", [
                                                ...values.operations,
                                                name,
                                              ]);
                                            } else {
                                              setFieldValue(
                                                "operations",
                                                values.operations.filter(
                                                  (e) => e !== name
                                                )
                                              );
                                            }
                                          }}
                                        />
                                        <span className="ms-1 mb-2 fs-5 form-check-label">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                              <div className="text-danger text-align-top-center mt-2">
                                <ErrorMessage name="operations" />
                              </div>
                            </div>

                            {values.operations.includes("other") ? (
                              <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                <label className="form-label fs-4 required fw-bold">
                                  Other Operations
                                </label>
                                <input
                                  id="operation_other"
                                  name="operation_other"
                                  className="form-control"
                                  value={values.operation_other}
                                  onChange={handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                    <div className="accordion">
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          data-bs-toggle="tooltip"
                          title="Click here to add marketing purpose"
                          id="marketing-purpose"
                        >
                          <button
                            className="accordion-button collapsed py-5 form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#marketings"
                            aria-expanded="false"
                            aria-controls="marketings"
                          >
                            Marketing Purpose
                          </button>
                        </h2>
                        <div
                          id="marketings"
                          className="accordion-collapse collapse"
                          aria-labelledby="marketing-purpose"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body pb-1 p-3">
                            <div className="row">
                              {Array.from(marketingDB).map(
                                (value: any, index) => {
                                  return (
                                    <div className="col-lg-6 col-md-6 col-12">
                                      <label
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2"
                                        key={index}
                                      >
                                        <input
                                          id="marketingOrOthers"
                                          type="checkbox"
                                          name={value.id}
                                          className="form-check-input mb-2 me-1"
                                          value={value.id}
                                          onChange={(e) => {
                                            const { checked, name } = e.target;
                                            if (checked) {
                                              setFieldValue(
                                                "marketingOrOthers",
                                                [
                                                  ...values.marketingOrOthers,
                                                  name,
                                                ]
                                              );
                                            } else {
                                              setFieldValue(
                                                "marketingOrOthers",
                                                values.marketingOrOthers.filter(
                                                  (e) => e !== name
                                                )
                                              );
                                            }
                                          }}
                                        />
                                        <span className="ms-1 mb-2 fs-5 form-check-label">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                              <div className="text-danger text-align-top-center mt-2">
                                <ErrorMessage name="marketingOrOthers" />
                              </div>
                            </div>

                            {values.marketingOrOthers.includes("other") ? (
                              <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                <label className="form-label fs-4 required fw-bold">
                                  Other Marketing
                                </label>
                                <input
                                  id="marketing_other"
                                  name="marketing_other"
                                  className="form-control"
                                  value={values.marketing_other}
                                  onChange={handleChange}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label required fs-4 fw-bold">
                      Priority
                    </label>
                    {Array.from(priorityDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2">
                        <label className="ms-2 fs-5" key={id}>
                          <input
                            id="priority"
                            className="form-check-input"
                            type="radio"
                            name="priority"
                            value={value.id}
                            defaultChecked={value.id === "HIGH"}
                            onChange={() => setFieldValue("priority", value.id)}
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">Comments</label>
                    <textarea
                      className="form-control"
                      name="comment"
                      id="comment"
                      value={values.comment}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Issue Type
                    </label>
                    <Select
                      ref={resetIssueType}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.issueType) && touched.issueType,
                      })}
                      classNamePrefix="my-react-select"
                      name="issueType"
                      placeholder=""
                      options={Array.from(issueTypeDB).map((value: any) => {
                        return { value: value.id, label: value.name };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("issueType", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="issueType" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Assign To
                    </label>
                    <Select
                      ref={resetAssignTo}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.assignTo) && touched.assignTo,
                      })}
                      classNamePrefix="my-react-select"
                      name="assignTo"
                      placeholder=""
                      options={Array.from(assignToDB).map((value: any) => {
                        return { value: value.id, label: value.firstName };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("assignTo", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="assignTo" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">CREATE TASK</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateSubTask;
