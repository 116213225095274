
import React, { FC, useState, Suspense } from 'react'
import Modal from 'react-responsive-modal'
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { ErrorMessage } from "formik";
import clsx from "clsx";
import toast, { Toaster } from "react-hot-toast";
import { updateGasSupplier } from '../../../models/_gasSupplier';

type Props = {
  currentGasSupplierId: string;
  currentlyOpenRecord: any;
  gasSupplierEditModal: boolean;
  handleGasSupplierClose: () => void;
  refreshTable?: any
};

const editCustomerSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  isActive: yup.string().required("Is active is required"),
});

export const EditGasSupplier: FC<Props> = ({
  currentGasSupplierId,
  currentlyOpenRecord,
  gasSupplierEditModal,
  handleGasSupplierClose,
  refreshTable
}) => {
  const navigate = useNavigate();
  if (!currentGasSupplierId) {
    navigate("/masters/gas-supplier/list");
  }
  const [loading, setLoading] = useState(false);
  const statusDB = [ 
    { id: true, name: "Active" },
    { id: false, name: "Inactive" },
  ];
  if (currentlyOpenRecord == null || currentlyOpenRecord === undefined || currentlyOpenRecord === 'undefined') {
    navigate("/masters/gas-supplier/list");
  }

  return (
    <Suspense>
       <Modal
          open={gasSupplierEditModal}
          onClose={() => handleGasSupplierClose()}
          role="modal"
          center
          classNames={{ root: "content-wrapper" }}
        >
          <Formik
            enableReinitialize
            initialValues= {{
              id: currentlyOpenRecord?.id,
              name: currentlyOpenRecord?.name,
              isActive: currentlyOpenRecord?.isActive
            }}
            validationSchema= {editCustomerSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setLoading(true);

              const updateData: any = {
                name: values.name,
                isActive: values.isActive
              };
              const result = await updateGasSupplier(values.id, updateData);

              if (result?.data?.data?.updateGasSupplier) {
                setLoading(false)
                toast.success("Gas Supplier updated successfully");
                handleGasSupplierClose()
                resetForm()
                if(typeof refreshTable !== 'undefined'){
                  refreshTable() 
                }
              } else {
                setLoading(false)
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
              }
            }}
            >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate
              >
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Edit Gas Supplier - 
                    {currentlyOpenRecord?.uuid}
                  </h4>
                </div>

                <div className="modal-body">
                  <div className="row">

                    <div className="col-12 mt-2 pt-2 w-100">
                      <label className="form-label fs-4 required fw-bold">
                        Name
                      </label>
                        <input
                          className={clsx("form-control", {
                              "is-invalid": Boolean(errors.name) && touched.name,
                          })}
                          name="name"
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="name" />
                      </div>
                    </div>

                    <div className="col-12 mt-2 pt-2 w-100">
                      <label className="form-label d-block fs-4 ps-1 pb-2 required fw-bold">
                        Status
                      </label>
                      {Array.from(statusDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label className="form-check-label fs-5 mb-0 ms-2" key={id} >
                            <input
                              id="isActive"
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              value={value.id}
                              checked={values.isActive === value.id}
                              onChange={() =>
                                setFieldValue("isActive", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>

                <div className="modal-footer mt-6 p-0 justify-content-start">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  >
                    {loading && (
                      <span className="indicator-progress" style={{ display: "block" }}>
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">UPDATE</span>
                    )}
                  </button>
                </div>
                
              </form>
            )}
          </Formik>
        </Modal> 
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  )
}

