/**
 * @generated SignedSource<<7222d84b5e34c2bd4df1bebfac4ccd3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE" | "%future added value";
export type DashboardTable_offlineMachinesQuery$variables = {};
export type DashboardTable_offlineMachinesQuery$data = {
  readonly offlineMachines: ReadonlyArray<{
    readonly connectionStatus: ConnectionStatus | null;
    readonly createdAt: any | null;
    readonly deployingPersonId: string | null;
    readonly deploymentDate: string;
    readonly deploymentTypeId: string | null;
    readonly hardwareVersion: string | null;
    readonly id: string;
    readonly isActive: boolean;
    readonly lastHearbeatSyncAt: any | null;
    readonly lastSanitizeTime: any | null;
    readonly location: {
      readonly deploymentManager: {
        readonly firstName: string;
        readonly id: string;
      } | null;
      readonly id: string;
      readonly name: string;
    } | null;
    readonly locationId: string | null;
    readonly name: string | null;
    readonly remark: string | null;
    readonly serialNumber: string;
    readonly softwareVersion: number | null;
    readonly updatedAt: any | null;
    readonly uuid: string | null;
  }>;
};
export type DashboardTable_offlineMachinesQuery = {
  response: DashboardTable_offlineMachinesQuery$data;
  variables: DashboardTable_offlineMachinesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "offlineMachines",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deploymentDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deployingPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deploymentTypeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "softwareVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hardwareVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "connectionStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastHearbeatSyncAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastSanitizeTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserObjectType",
            "kind": "LinkedField",
            "name": "deploymentManager",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardTable_offlineMachinesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardTable_offlineMachinesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "055b5323f3b11f7233495250959d94ce",
    "id": null,
    "metadata": {},
    "name": "DashboardTable_offlineMachinesQuery",
    "operationKind": "query",
    "text": "query DashboardTable_offlineMachinesQuery {\n  offlineMachines {\n    id\n    uuid\n    serialNumber\n    name\n    deploymentDate\n    locationId\n    deployingPersonId\n    deploymentTypeId\n    softwareVersion\n    hardwareVersion\n    connectionStatus\n    createdAt\n    updatedAt\n    isActive\n    remark\n    lastHearbeatSyncAt\n    lastSanitizeTime\n    location {\n      id\n      name\n      deploymentManager {\n        id\n        firstName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a61a83a6d1bc8a3b1a37ab6c1e971f1";

export default node;
