import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { createMachineSerialNoLookup } from "../../../models/_machineSerialNumber";
import Papa from 'papaparse';
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

function CreateMachineSerialNumber() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();
  // eslint-disable-next-line
  const [csvFile, setCsvFile] = useState([])
  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (!file) {
      setLoading(false);
      toast.error("Please select valid csv file.");
    };
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const csv: any = Papa.parse(e.target.result, { header: true });
      const parsedData = csv?.data;
      const rows = Object.keys(parsedData[0]);
      if (rows[0] === 'Serial Number' && rows[1] === 'Mechanical Hardware Number') {
        const reqPayload: any = [];
        await parsedData.map(async (parsedD: any, index: any) => {
          if (parsedD['Serial Number'] && parsedD['Mechanical Hardware Number']) {
            const payloadObj = {
              serialNumber: parsedD['Serial Number'].toString(),
              mechanicalHardwareVersion: parseFloat(parsedD['Mechanical Hardware Number']),
            };
            reqPayload.push(payloadObj);
          }
        });
        setCsvFile(reqPayload)

        const $input = {
          payload: reqPayload,
        };
        const result = await createMachineSerialNoLookup($input);

        if (result?.data?.data?.createMachineSerialNoLookup?.isSuccess === true) {
          toast.success("Message: " + [`${result?.data?.data?.createMachineSerialNoLookup?.message} `]);
          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data?.data?.createMachineSerialNoLookup?.isSuccess === false) {
            toast.error("Message: " + [`${result?.data?.data?.createMachineSerialNoLookup?.message} `]);
            toast.error("Existing Serial Numbers:" + [`${result?.data?.data?.createMachineSerialNoLookup?.existingSerialNumbers}`]);
          }
        }
      } else {
        setLoading(false);
        toast.error("Please select valid csv file.");
      }
    };
    reader.readAsText(file);
  };

  // const exportDataToCSV = () => {
  //   const csv = Papa.unparse(csvFile);
  //   const csvBlob = new Blob([csv], { type: "text/csv" });
  //   const csvUrl = URL.createObjectURL(csvBlob);
  //   const link = document.createElement("a");
  //   link.href = csvUrl;
  //   link.download = "serial_number_data.csv";
  //   link.click();
  //   URL.revokeObjectURL(csvUrl);
  // };

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Import Machine Serial Number
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">
                Import Machine Serial Number
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">

        <div className="card-body w-100 p-lg-7 p-md-7 p-5">
          <form autoComplete="off">
            <div className="row">
              <div className="row align-items-center justify-content-between">
                 <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mt-md-9 mt-lg-8 mt-xl-8 pt-xl-3 order-xl-01 order-md-0">
                   <div className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3">
                      <label
                        className="form-label fs-4 mb-0 text-dark"
                        style={{ cursor: "pointer" }}
                      >
                        Upload File
                        <input
                          onChange={handleOnChange}
                          id="payload"
                          type="file"
                          accept={".csv"}
                          name="uploadImageVideo"
                          className="form-control d-none"
                        />
                      </label>
                    </div>
                 </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      <a
                        href={`${toAbsoluteUrl("/Document/serial_numbers.csv")}`}
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                        // onClick={exportDataToCSV}
                        download={true}
                      >
                        <i
                          className="fas fa-download"
                          data-bs-toggle="tooltip"
                          title="Click to download sample CSV file"
                        ></i> Download Sample CSV File
                      </a>
                      
                      </div>
                    </div>
           
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 px-xl-5 p-2">
                  <button
                    type="submit"
                    onClick={(e) => handleOnSubmit(e)}
                    className="btn btn-primary me-4 fs-4 w-100"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">
                        Add Machine Serial Number
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </div>
  );
}

export default CreateMachineSerialNumber;