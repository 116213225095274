import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateMachineInput {
  name: string;
  deploymentDate: string;
  deploymentTypeId?: string;
  deployingPersonId?: string;
  serialNumber: string;
  hardwareVersion?: number;
  softwareVersion?: number;
  isActive: boolean;
  remark: string;
  connectionStatus: string;
  blenderId: string;
  boardId: string;
  cupHolderId: string;
  machineId: string;
  replaceNewTubeDoors1mmShorter?: boolean;
}

export async function createMachine(input: CreateMachineInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createMachine (
          $input: CreateMachineInput!
        ){
          createMachine(
            createMachineInput: $input
            ) {
                id
                uuid
                serialNumber
                remark
                name
                deploymentDate
                locationId
                deployingPersonId
                deploymentTypeId
                softwareVersion
                hardwareVersion
                createdById
                updatedById
                isActive
                retiredAt
                replaceNewTubeDoors1mmShorter
                isPilot
                trialEndDate
                createdBy {
                  id
                  firstName
                  lastName
                  email
                  roleId
                  slackId
                  resetPasswordKey
                  createdAt
                  updatedAt
                }
                deployingPerson {
                  id
                  firstName
                  lastName
                  createdAt
                  updatedAt
                }
                location {
                  id
                  customerId
                  name
                  address
                  regionId
                  contactName
                  contactPhone
                  marketSegmentId
                  deploymentManagerId
                  locationTypeId
                  targetLaunchDate
                  noOfBlenders
                  noOfFreezers
                  distributorId
                  samplingRecommendedTime
                  samplingRecommendedDays
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  isActive
                }
                deploymentType {
                  id
                  name
                  createdAt
                  updatedAt
                }
              }
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}
export async function getReplaceMachines(
  where: {} = {},
  inStock = true,
  extraFilter: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
        $where: MachinesResolver_GetMachines_FilterInputType
        $inStock:Boolean!
        $extraFilter: MahineExtraFilterInputs  
    ){
    getMachines(where: $where ,inStock:$inStock,extraFilter: $extraFilter) {
      data {
        id
        uuid
        name
        serialNumber
        location {
          name
        }
      }
  }}`,
  variables: { where, inStock, extraFilter },
},
});
return data?.data?.data?.getMachines;
}
export async function getMachines(
  where: {} = {},
  inStock = false,
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
              $where: MachinesResolver_GetMachines_FilterInputType
              $inStock:Boolean!
              $paginate:PaginatorArgs
              $extraFilter: MahineExtraFilterInputs 
        ){
        getMachines(where: $where ,inStock:$inStock,paginate: $paginate,extraFilter: $extraFilter) {
          data {
            id
            uuid
            serialNumber
            macAddress
            name
            deploymentDate
            locationId
            deployingPersonId
            deploymentTypeId
            softwareVersion
            hardwareVersion
            connectionStatus
            createdById
            createdAt
            updatedById
            updatedAt
            isActive
            lastSanitizerReplacedAt
            remark
            lastHearbeatSyncAt
            lastSanitizeTime
            lastFilterReplacedAt
            retiredAt
            retiredById
            isRetired
            blenderId
            boardId
            cupHolderId
            replaceNewTubeDoors1mmShorter
            noteRelatedToMechanicalHardwareVersion
            isPilot
            trialEndDate
            metas {
              metaKey
              metaValue
              createdAt
            }
            blender{
              id
              title
            }
            board {
              id
              title
            }
            retiredBy {
              id
              firstName
              lastName
        
            }
            cupHolder {
              id
              title
            }
            updatedBy {
              id
              firstName
              lastName
            }
            deployingPerson {
              id
              firstName
              lastName
            }
            location {
              id
              customerId
              customer{
                id
                name
                customerCompanyName
                isVip
              }
              name
              address
              regionId
              contactName
              contactPhone
              marketSegmentId
              deploymentManagerId
              deploymentManager{
                firstName
              }
              locationTypeId
              targetLaunchDate
              noOfBlenders
              noOfFreezers
              distributorId
              samplingRecommendedTime
              samplingRecommendedDays
              createdById
              createdAt
              updatedById
              updatedAt
              isActive 
            }
            deploymentType {
              id
              name
           }
          }
          totalPages
          total
          perPage
          page
        }
      }`,
      variables: { where, inStock, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachines;
}

export async function getMachinesNameAndSerials(
  where: {} = {},
  inStock = false,
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getMachines(
              $where: MachinesResolver_GetMachines_FilterInputType
              $inStock:Boolean!
              $paginate:PaginatorArgs
        ){
        getMachines(where: $where ,inStock:$inStock,paginate: $paginate) {
          data {
            id
            uuid
            serialNumber
            name
           }
          totalPages
          total
          perPage
          page
        }
      }`,
      variables: { where, inStock, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachines;
}

interface UpdateMachineInput {
  id?: string;
  name: string;
  deploymentDate: string;
  deploymentTypeId: string | undefined;
  deployingPersonId: string | undefined;
  serialNumber: string;
  remark: string;
  softwareVersion?: number;
  hardwareVersion?: string | number;
  connectionStatus: string;
  isRetired: boolean;
  blenderId: string;
  boardId: string;
  cupHolderId: string;
  replaceNewTubeDoors1mmShorter: boolean;
}
export async function updatemachine(input: UpdateMachineInput) {
  let params = { ...input };
  delete params["id"];
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updatemachine (
        $id: String!
        $input: UpdateMachineInput!  
            ){
          updatemachine(
            id: $id
            updateMachineInput: $input
          ) {
            id
            uuid
            serialNumber
            name
            remark
            deploymentDate
            locationId
            deployingPersonId
            lastHearbeatSyncAt
            lastSanitizeTime
            deploymentTypeId
            softwareVersion
            hardwareVersion
            replaceNewTubeDoors1mmShorter
            createdById
            updatedById
            isActive
            isPilot
            trialEndDate
            connectionStatus
            createdBy {
              id
              firstName
              lastName
            }
            isRetired
            blenderId
            boardId
            cupHolderId
            retiredAt
            retiredBy {
              id
              firstName
              lastName
            }
            updatedBy {
              id
              firstName
              lastName
            }
            deployingPerson {
              id
              firstName
              lastName
            }
            location {
              id
              customerId
              name
              address
              regionId
              contactName
              contactPhone
              marketSegmentId
              deploymentManagerId
              locationTypeId
              targetLaunchDate
              noOfBlenders
              noOfFreezers
              distributorId
              samplingRecommendedTime
              samplingRecommendedDays
              createdById
              createdAt
              updatedById
              updatedAt
              isActive
            }
            deploymentType {
              id
              name
            }
            blender {
              id
              versionNo
              title
            }
            board {
              id
              versionNo
              title
            }
            cupHolder {
              id
              title
            }
          }
        }
      `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateMachineStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateMachineStatus(
          updateMachineStatusInput: { id: $id, isActive: $isActive }
              )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function changeMachineLocation(
  id: string,
  locationId: string,
  deploymentTypeId: string,
  isPilot: boolean,
  trialEndDate: string,
  deployingPersonId: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation changeMachineLocation (
                  $id: String!,
                  $locationId: String!,
                  $deploymentTypeId: String!,
                  $deployingPersonId: String!,
                  $isPilot: Boolean,
                  $trialEndDate: String
              ){
                changeMachineLocation(
                  changeMachineLocationInput: {
                id: $id
                locationId:$locationId
                isPilot: $isPilot
                trialEndDate: $trialEndDate
                deploymentTypeId: $deploymentTypeId
                deployingPersonId: $deployingPersonId
              }
            ) {
              id
              uuid
              serialNumber
              name
              deploymentDate
              locationId
              deployingPersonId
              deploymentTypeId
              softwareVersion
              hardwareVersion
              connectionStatus
              createdById
              createdAt
              updatedById
              updatedAt
              isActive
              isPilot
              trialEndDate
              remark
              isRetired
              retiredAt
              retiredBy {
                id
                firstName
                lastName
              }
              createdBy {
                id
                firstName
                lastName
              }
              updatedBy {
                id
                firstName
                lastName
              }
              deployingPerson {
                id
                firstName
                lastName
              }
              location {
                id
                uuid
                customerId
                name
                address
                regionId
              }
              deploymentType {
                id
                name
                createdAt
                updatedAt
              }
            }
          }
        `,
      variables: {
        id,
        locationId,
        deploymentTypeId,
        isPilot,
        trialEndDate,
        deployingPersonId,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getSoftwareVersions() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getSoftwareVersionList 
            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getSoftwareVersionList ?? [];
}

export async function getHardwareVersionsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
                  getHardwareVersionList

            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getHardwareVersionList;
}
