import React, { useEffect, useState, useRef, memo, createRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Moment from "moment";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import ReactSelect from "react-select";
import copy from 'copy-to-clipboard';
import { Link } from "react-router-dom";
import { getServiceEngineers } from "../../../models/_user";
import { assignTicket, cancelScheduleTicket, getScheduledTickets, getUnassignedTickets } from "../../../models/_assignTickets";
import { getTicket } from "../../../models/_tickets";
import { Option } from '../../../../_metronic/helpers/MySelect';
import { v4 as uuidv4 } from 'uuid';
import resourceTimeline from "@fullcalendar/resource-timeline";
import { groupBy } from "lodash";
const ticketTypes: any = [
  {
    id: "REACTIVE_MAINTANANCE",
    name: "Reactive Maintenance",
  },
  {
    id: "PREVENTIVE_MAINTANANCE",
    name: "Preventive Maintenance",
  },
];

const ExternalEvent = memo(({ event }: any) => {
  let elRef: any = useRef(null);

  useEffect(() => {
    let draggable = new Draggable(elRef.current, {
      eventData: function () {
        return { ...event, create: true };
      },
    });
    // a cleanup function
    return () => draggable.destroy();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={elRef}
      className="border-bottom border-1 py-3"
      title={event.title}
      data-id={event.id}
      data-hashid={event.hashId}
      data-issue={event.issue}
      data-location={event.location}
      data-customer={event.customer}
      data-days={event.daysOpen}
      key={event.id}
      data-status={event.status}
      data-travelingstarttime={event.travelingStartTime}
      data-travelingendtime={event.travelingEndTime}
      data-workstarttime={event.workStartTime}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="row">
        <div className="col-xl-3 col-lg-8 col-md-3 col-3 p-0 m-0 text-dark">Ticket Id</div>
        <div className="col-lg-1 col-md-1 col-1 p-0 m-0"> : </div>
        <div className="p-0 m-0 col-lg-8 col-md-8 col-8 text-gray-600">
          <Link to={`/tickets/list/viewticket/${event.id}`} target="_blank">
            {event.title}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-8 col-md-3 col-3 p-0 m-0 text-dark">Customer</div>
        <div className="col-lg-1 col-md-1 col-1 p-0 m-0"> : </div>
        <div className="p-0 m-0 col-lg-8 col-md-8 col-8 text-gray-600 truncate">
          {event.customer}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-8 col-md-3 col-3 p-0 m-0 text-dark">Location</div>
        <div className="col-lg-1 col-md-1 col-1 p-0 m-0"> : </div>
        <div className="p-0 m-0 col-lg-8 col-md-8 col-8 text-gray-600 truncate">
          {event.location}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-8 col-md-3 col-3 p-0 m-0 text-dark">Issue Reported </div>
        <div className="col-lg-1 col-md-1 col-1 p-0 m-0"> : </div>
        <div className="p-0 m-0 col-lg-8 col-md-8 col-8 text-gray-600 truncate">
          {event.issue}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-8 col-md-3 col-3 p-0 m-0 text-dark">Days Open</div>
        <div className="col-lg-1 col-md-1 col-1 p-0 m-0"> : </div>
        <div className="p-0 m-0 col-lg-8 col-md-8 col-8 text-gray-600">
          {event.daysOpen}
        </div>
      </div>
    </div>
  );
});

export default function Calender() {
  const calendarRef = createRef<FullCalendar>();
  const [memberDB, setMemberDB] = useState<any>([]);
  const [showMore, setShowMore] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(6);
  const [date, setDate] = useState<any>(
    Moment(calendarRef.current?.getApi().getDate())
  );
  const [searchMember, setSearchMember] = React.useState("");
  const [selectedMember, setSelectedMember] = React.useState<any>([]);
  const [state, setState] = useState<any>({
    weekendsVisible: true,
    externalEvents: [],
  });
  const [calenderEvents, setCalenderEvents] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(true);
  const [filterTicketType, setFilterTicketType] = useState<any>(['REACTIVE_MAINTANANCE', 'PREVENTIVE_MAINTANANCE'])
  const [ticketTypeSelected, setTicketTypeSelected] = useState([
    {
      value: "REACTIVE_MAINTANANCE",
      label: "Reactive Maintenance",
    },
    {
      value: "PREVENTIVE_MAINTANANCE",
      label: "Preventive Maintenance",
    },
  ])
  const [displayMember, setDisplayMember] = useState<any>(true);
  const [currentViewType, setCurrentViewType] = useState<any>(true);

  // Fetch data from scheduled tickets
  const handleFetchData = async () => {
    // setCalenderEvents([]);
     const startTime = '00:00:00'
     const endTime = '23:59:59'
    const calendarApi = calendarRef.current?.getApi();
    const view = calendarApi?.view;
    
    const filterFromDate = view?.activeStart ? Moment(view.activeStart).format('YYYY-MM-DD') + ' ' + startTime : Moment(date).format('YYYY-MM-DD') + ' ' + startTime
    const filterToDate = view?.activeEnd ? Moment(view.activeEnd).format('YYYY-MM-DD') + ' ' + endTime : Moment(date).format('YYYY-MM-DD') + ' ' + endTime
    let filter: any = {
      fromDate: Moment(filterFromDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      toDate: Moment(filterToDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      orderByReportedAt: 'desc',
    };
    if (filterTicketType.length !== 0) {
      filter["ticketType"] = filterTicketType;
    } else {
      filter["ticketType"] = ['REACTIVE_MAINTANANCE', 'PREVENTIVE_MAINTANANCE'];
    }
    const assignedTickets = await getScheduledTickets(filter);
    const assignRows: any = [];
    for (const tickets of assignedTickets) {
      const record = {
        id: tickets.id,
        hashId: tickets.hashId,
        title: tickets.uuid,
        location: tickets.machine?.location?.name ?? "",
        customer: tickets.machine?.location?.customer?.name ?? "",
        status: tickets.status ?? "",
        issue: tickets.issuesReported
          ?.map((element: any) => element.name)
          .join(",") ?? "",
        daysOpen: tickets.daysOpen ?? 0,
        resourceId: tickets.assignToId ?? "",
        start: tickets.scheduleStartDate ?
          Moment.utc(tickets.scheduleStartDate).local().toISOString() : new Date().toISOString(),
        end: tickets.scheduleEndDate ?
          Moment.utc(tickets.scheduleEndDate).local().toISOString() : new Date().toISOString(),
        color: '#dfffd5',
        textColor: '#70c057',
        editable: true,
        droppable: true,
        draggable: true,
        travelingStartTime: tickets.travelingStartTime ?? null,
        travelingEndTime: tickets.travelingEndTime ?? null,
        workStartTime: tickets.workStartTime ?? null,
      };
      if (tickets.travelingStartTime !== null) {
        record.color = "#FFFFE6";
        record.textColor = '#9B870C'
        // record.editable = false
        if (tickets.travelingEndTime !== null) {
          record.color = "#FFD9B3";
          record.textColor = '#FF671F'
          // record.editable = false
        }
      }
      if (tickets.status === 'COMPLETED') {
        record.color = "#fff5f8";
        record.textColor = '#f1416c'
        record.editable = false
        // record.eventStartEditable= false
        record.droppable = false
        record.draggable = false
      }
      assignRows.push(record);
    }
    calendarRef.current?.getApi().removeAllEvents();
    setCalenderEvents(assignRows);
  };


  // Fetch data from users 
  const fetchUsers = async () => {
    const memberRecord = await getServiceEngineers();
    let memberResult: any = memberRecord ? memberRecord : [];
    memberResult.sort(function (a: any, b: any) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (isCheckAll === true) {
      setSelectedMember(memberResult);
    }
    if (searchMember !== "") {
      memberResult = memberResult.filter((a: any) =>
        a.firstName.toLowerCase().includes(searchMember)
      );
    }
    setMemberDB(memberResult);
  }
  // Fetch unassigned tickets
  const handleFetchUnassign = async () => {
    let filter: any = {
      orderByReportedAt: 'desc'
    };
    if (filterTicketType.length !== 0) {
      filter["ticketType"] = filterTicketType;
    } else {
      filter["ticketType"] = ['REACTIVE_MAINTANANCE', 'PREVENTIVE_MAINTANANCE'];
    }
    const unassignTickets = await getUnassignedTickets(filter);
    const unassignRows: any = [];
    for (const tickets of unassignTickets) {
      const record = {
        id: tickets.id,
        hashId: uuidv4(),
        title: tickets.uuid,
        location: tickets.machine?.location?.name ?? "",
        travelingStartTime: tickets.travelingStartTime ?? null,
        travelingEndTime: tickets.travelingEndTime ?? null,
        workStartTime: tickets.workStartTime ?? null,
        customer: tickets.machine?.location?.customer?.name ?? "",
        status: tickets.status ?? "",
        issue: tickets.issuesReported
          ?.map((element: any) => element.name)
          .join(",") ?? [],
        daysOpen: tickets.daysOpen ?? 0,
        color: '#dfffd5',
        textColor: '#70c057'
      };
      unassignRows.push(record);
    }
    setState(() => {
      return {
        externalEvents: unassignRows,
      };
    });
  };

  // handle external event receive
  const handleEventReceive = async (eventInfo: any) => {
    const newEvent: any = {
      id: eventInfo.draggedEl.getAttribute("data-id"),
      hashId: eventInfo.draggedEl.getAttribute("data-hashid"),
      title: eventInfo.draggedEl.getAttribute("title"),
      start: Moment(eventInfo.event.startStr).utc().format("YYYY-MM-DD HH:mm:ss") ?? new Date().toUTCString(),
      end: eventInfo.event.endStr
        ? Moment(eventInfo.event.endStr).utc().format("YYYY-MM-DD HH:mm:ss")
        : Moment(eventInfo.event.startStr).utc()
          .add((currentViewType === 'week' ? 24 : 1), "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      issue: eventInfo.draggedEl.getAttribute("data-issue"),
      location: eventInfo.draggedEl.getAttribute("data-location"),
      customer: eventInfo.draggedEl.getAttribute("data-customer"),
      assignTo: eventInfo.event._def.resourceIds[0],
      status: eventInfo.draggedEl.getAttribute("data-status"),
      travelingStartTime: eventInfo.draggedEl.getAttribute("data-travelingstarttime") ?? null,
      travelingEndTime: eventInfo.draggedEl.getAttribute("data-travelingendtime") ?? null,
      workStartTime: eventInfo.draggedEl.getAttribute("data-workstarttime") ?? null,
      color: '#dfffd5',
      textColor: '#70c057'
    };

    if (currentViewType === 'week') {
      
      newEvent.allDay = true;
      //newEvent.start = Moment(newEvent.start).format("YYYY-MM-DD");
      //newEvent.end = Moment(newEvent.end).format("YYYY-MM-DD");
    }
    
    const $input = {
      id: newEvent.id,
      hashId: newEvent.hashId,
      assignToId: newEvent.assignTo,
      scheduleStartDate: newEvent.start,
      scheduleEndDate: newEvent.end,
    };
    
 
    const result = await assignTicket($input);
    if (result?.data?.data?.assignTicket) {
      handleFetchUnassign();
      const calendarRef = eventInfo.view.calendar;
      calendarRef.current?.getApi().removeAllEvents();
      handleFetchData()
      toast.success("Ticket scheduled successfully");
    } else {
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };

  // Internal event drag and drop
  const eventDrop = async (arg: any) => {
    calendarRef.current?.getApi().removeAllEvents();
    const newEvent = {
      id: arg.event.id,
      hashId: arg.event.hashId,
      title: arg.event.title,
      start: arg.event.startStr ? arg.event.startStr : new Date().toISOString(),
      end: arg.event.endStr
        ? Moment(arg.event.endStr).utc().format("YYYY-MM-DD HH:mm:ss")
        : Moment(arg.event.startStr).utc()
          .add(1, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      comment: arg.event._def.extendedProps.comment,
      location: arg.event._def.extendedProps.location,
      assignTo: arg.event._def.resourceIds[0],
      status: arg.event._def.extendedProps.status,
      travelingStartTime: arg.event._def.extendedProps.travelingStartTime ?? null,
      travelingEndTime: arg.event._def.extendedProps.travelingEndTime ?? null,
      workStartTime: arg.event._def.extendedProps.workStartTime ?? null,
    };

    const getTicketResult = await getTicket(arg.event.id);
    if (getTicketResult == null) {
      toast.error(`Ticket not found.`);
    } else {
      // const filterEvents = calenderEvents.filter((value: any) => value.id !== newEvent.id)
      // const addNewEvent: any = [...filterEvents, newEvent];
      if (getTicketResult?.status && getTicketResult?.status === "COMPLETED") {
        toast.error(`It's not possible to reschedule this ticket as it is already completed.`);
      // else if (getTicketResult?.status && getTicketResult?.status !== "COMPLETED" && (getTicketResult?.travelingStartTime !== null || getTicketResult?.travelingEndTime !== null || getTicketResult?.workStartTime !== null)) {
      //   toast.error(`It's not possible to reschedule this ticket as it is already in progress.`);
      // }
      } else {
        const $input = {
          id: newEvent.id,
          assignToId: newEvent.assignTo,
          scheduleStartDate: Moment(newEvent.start).utc().format("YYYY-MM-DD HH:mm:ss"),
          scheduleEndDate: newEvent.end,
        };
 
        const result = await assignTicket($input);
        if (result?.data?.data?.assignTicket) {
          toast.success("Ticket scheduled successfully");
        } else {
          const messages = result?.data?.errors.map((e: any) => e.message);
          toast.error(messages.join(","));
        }
      }
      handleFetchData();
       //arg.el.remove()
    }
  };

  // Show more and less members
  const handleShowLess = () => {
    setItemsToShow(6);
    setShowMore(false);
  };
  const handleShowMore = () => {
    setItemsToShow(memberDB.length);
    setShowMore(true);
  };

  // Full calender date change
  const handleDateChange = (direction: "prev" | "today" | "next"): void => {
    const calApi = calendarRef.current?.getApi();
    calendarRef.current?.getApi().removeAllEvents();
    if (calApi) {
      if (direction === "prev") {
        calApi.prev();
      } else if (direction === "next") {
        calApi.next();
      } else {
        calApi.today();
      }
      setDate(Moment(calApi.getDate()));
      handleFetchData()
    }
  };

  // Cancel event API fetch
  const handleCancelEvent = async (event: any) => {
    const result = await cancelScheduleTicket(event.id);
    if (result?.data?.data?.cancelScheduleTicket) {
      calendarRef.current?.getApi().removeAllEvents();
      handleFetchData()
      event.remove()
      handleFetchUnassign()
      toast.success("Scheduled ticket cancelled successfully!");
    } else {
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
      handleFetchData()
    }
  }

  useEffect(() => {
    //handleFetchData();
    handleFetchUnassign();
    fetchUsers()
    if (selectedMember.length === memberDB.length) {
      setIsCheckAll(true);
    }

    if(currentViewType === 'week') {
      handleFetchData()
    } else if(currentViewType === 'time') {
      handleFetchData()
    }
    // eslint-disable-next-line
  }, [searchMember, filterTicketType, currentViewType]);

  const sortedGrouped = state.externalEvents.sort((a:any, b:any) => a.location.localeCompare(b.location));
  const grouped = groupBy(sortedGrouped, group => group.location);

  return (
    <>
      <div className="card mt-9">
        <div className="card-body w-100 p-lg-7 p-md-7 p-5">
          <div className="row">
            <div className="col-xl-3 col-lg-12 col-12">
              {/* Toggle button */}

              <div className="calender">
                <Calendar
                  onChange={(value: any) => {
                    calendarRef.current?.getApi().removeAllEvents();
                    const newValue = Moment(value);
                    setDate(newValue);
                    calendarRef.current?.getApi().gotoDate(newValue?.toDate());
                    handleFetchData()
                  }}
                  value={date}
                />
              </div>

              <button
                className="btn btn-outline-secondary mb-3 w-100 d-lg-none"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              ></button>

              {displayMember && (
                <div
                  className="collapse d-block mb-5 border-muted rounded"
                  id="navbarSupportedContent"
                >
                  <div
                    className="ticket-accordion accordion"
                    id="accordionPanelsStayOpenExample"
                  >

                    <div className="row">
                      <div className="col-xl-12 col-md-6 col-12">
                        <div className="accordion-item border-0">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                          >
                            <button
                              className="accordion-button collapsed text-primary fw-bold py-4 px-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              Open Tickets ({state.externalEvents.length})
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                          >
                            {/* <label className="form-label fs-4 fw-bold">Ticket Type</label> */}
                            <ReactSelect
                              className="react-select mb-2"
                              classNamePrefix="my-react-select"
                              placeholder=""
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onChange={(selected: any) => {
                                const value = selected?.map((e: any) => e.value);
                                setFilterTicketType(value)
                                setTicketTypeSelected(selected)
                              }}
                              components={{
                                Option,
                              }}
                              value={ticketTypeSelected}
                              options={ticketTypes.map(
                                (value: any) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                            />
                            {state.externalEvents.length > 0 ? (
                              <>
                                {Object.keys(grouped).map((key: any, index: any) => (
                                  <div
                                    className="collapse d-block border-muted rounded"
                                    id="navbarSupportedContent"
                                  >
                                    <div
                                      className="ticket-accordion accordion"
                                      id="accordionPanelsStayOpenExample"
                                    >
                                      <div className="row">
                                        <div className="col-xl-12 col-md-6 col-12">
                                          <div className="accordion-item border-0">
                                            <h2
                                              className="accordion-header"
                                              id="panelsStayOpen-headingFive"
                                            >
                                              <button
                                                className="accordion-button collapsed text-primary fw-bold py-4 px-2"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={"#panelsStayOpen-collapseFive" + index}
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseFive"
                                              >
                                                {key}
                                              </button>
                                            </h2>
                                            <div
                                              id={"panelsStayOpen-collapseFive" + index}
                                              className="accordion-collapse collapse"
                                              aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                              <div className="accordion-body text-muted py-4 max-h-600px overflow-scroll">
                                                {grouped[key].map((event: any) => (
                                                  <ExternalEvent key={event.id} event={event} />
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                                }</>
                            ) : (
                              <>
                                <div className="accordion-body text-muted">
                                  No open tickets found
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-12 col-md-6 col-12">
                        <div className="accordion-item border-0">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingThree"
                          >
                            <button
                              className="accordion-button collapsed text-primary fw-bold py-4 px-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree"
                            >
                              <div className="cursor-pointer form-check form-check-sm form-check-custom form-check-solid align-items-center">
                                <input
                                  className="form-check-input fs-6"
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  checked={isCheckAll}
                                  onChange={() => {
                                    setIsCheckAll(!isCheckAll);
                                    setSelectedMember(memberDB);
                                    if (isCheckAll) {
                                      setSelectedMember([]);
                                    }
                                  }}
                                />
                                <label className="form-check-label text-primary fw-bold ms-2">
                                  Team Members
                                </label>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThree"
                            className="accordion-collapse collapse "
                            aria-labelledby="panelsStayOpen-headingThree"
                          >
                            <input
                              type="text"
                              autoComplete="off"
                              className="text-transform search form-control cursor-pointer my-3 p-1 fs-7 ps-3"
                              id="search"
                              onChange={(event: any) => {
                                setSearchMember(event.target.value);
                              }}
                              value={searchMember}
                              placeholder="&#xF002;  Search Members"
                              style={{ fontFamily: "Arial, FontAwesome" }}
                            />
                            <div className="accordion-body text-muted py-4 px-2">
                              <div>
                                {memberDB
                                  .slice(0, itemsToShow)
                                  .map((value: any, index: number) => {
                                    return (
                                      <label
                                        key={index}
                                        className="cursor-pointer form-check form-check-sm form-check-custom form-check-solid mt-2 d-flex justify-content-start align-items-center"
                                      >
                                        <input
                                          className="form-check-input fs-5"
                                          type="checkbox"
                                          value={value}
                                          // name={value.id}
                                          checked={selectedMember.includes(value)}
                                          onChange={(event: any) => {
                                            const { checked } = event.target;
                                            if (checked === true) {
                                              const members = [
                                                ...selectedMember,
                                                value,
                                              ];
                                              setSelectedMember(members);
                                            } else {
                                              const members = selectedMember.filter(
                                                (e: any) => e !== value
                                              );
                                              setIsCheckAll(false);
                                              setSelectedMember(members);
                                            }
                                          }}
                                        />
                                        <span
                                          key={index}
                                          className="form-check-label ms-2"
                                        >
                                          {value.firstName}
                                        </span>
                                      </label>
                                    );
                                  })}
                                {memberDB.length > 0 ? (
                                  <>
                                    {memberDB.length > 6 && (<>
                                      {showMore ? (
                                        <div
                                          className="text-primary cursor-pointer pt-3 align-items-center"
                                          onClick={handleShowLess}
                                        >
                                          Show less
                                          <i className="fa fa-chevron-up ms-2 text-primary fs-6"></i>
                                        </div>
                                      ) : (
                                        <div
                                          className="text-primary cursor-pointer pt-3 align-items-center"
                                          onClick={handleShowMore}
                                        >
                                          Show more
                                          <i className="fa fa-chevron-down ms-2 text-primary fs-6"></i>
                                        </div>
                                      )}</>)}
                                  </>
                                ) : (
                                  <div>No members found</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-xl-9 col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-3 col-12">
                  <button
                    className="btn btn-sm btn-light me-1"
                    data-trackid="prev-button"
                    onClick={(): void => handleDateChange("prev")}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-light me-1"
                    data-trackid="today-button"
                    onClick={(): void => handleDateChange("today")}
                  >
                    Today
                  </button>
                  <button
                    className="btn btn-sm btn-light"
                    data-trackid="next-button"
                    onClick={(): void => handleDateChange("next")}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
                <div className="col-lg-8 col-md-9 col-12 justify-content-end float-end d-flex">
                  <div className="ticket-style mt-4">
                    <div className="row">
                      <div className="w-auto col-lg-3 col-md-3 col-12 d-inline-flex align-items-center mt-md-0 mt-lg-0 mt-2">
                        <span className="square square-light-primary"></span>
                        <span className="text-primary ms-1 fw-bold">Scheduled Tickets</span>
                      </div>
                      <div className="w-auto col-lg-3 col-md-3 col-12 d-inline-flex align-items-center mt-md-0 mt-lg-0 mt-2">
                        <span className="square square-light-warning"></span>
                        <span className="text-warning ms-1 fw-bold">On My Way</span>
                      </div>
                      <div className="w-auto col-lg-3 col-md-3 col-12 d-inline-flex align-items-center mt-md-0 mt-lg-0 mt-2">
                        <span className="square square-light-saffron"></span>
                        <span className="text-saffron ms-1 fw-bold">Work Started</span>
                      </div>
                      <div className="w-auto col-lg-3 col-md-3 col-12 d-inline-flex align-items-center mt-md-0 mt-lg-0 mt-2">
                        <span className="square square-light-danger"></span>
                        <span className="text-danger ms-1 fw-bold">Completed Tickets</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3 justify-content-lg-end justify-content-md-end justify-content-start d-flex">
                  <input
                    type="text"
                    autoComplete='off'
                    className="text-transform search form-control cursor-pointer p-1 fs-7 ps-3 w-100 w-lg-auto w-md-auto"
                    id="search"
                    onChange={(event: any) => {
                      const search = event.target.value

                      const member: any = calendarRef.current?.getApi().getResources().filter((value) =>
                        value._resource.title.toLowerCase().includes(search)
                      )
                      if (search !== "") {
                        const members = selectedMember.filter(
                          (a: any) =>
                            a.firstName.toLowerCase().includes(search)
                        )
                        setSelectedMember(members)
                      }
                    }}
                    placeholder="&#xF002;  Search"
                    style={{ fontFamily: "Arial, FontAwesome" }}
                  />
                </div> */}
              </div>
              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  resourceTimelinePlugin,
                  resourceTimeline,
                ]}
                headerToolbar={{
                  left: "",
                  center: "title",
                  right: 'resourceTimelineDay, resourceTimelineWeek'
                  // right: 'resourceTimelineDay, resourceTimelineMonth, dayGridMonth'
                }}
                datesSet={(event) => {
                  const viewType = event?.view?.type;
                  if (viewType === 'resourceTimelineDay') {
                    setDisplayMember(true); //time
                    setCurrentViewType('time')
                  } else {
                    
                    setDisplayMember(true); //day
                    setCurrentViewType('week')
                  }
                  
                }}
                views={
                  {
                    resourceTimelineWeek: {
                      type: 'resourceTimelineWeek',
                      slotMinWidth: 170,
                      buttonText: 'Week',
                      selectable: true,
                    },
                    resourceTimelineDay: {
                      type: 'resourceTimelineDay',
                      buttonText: 'Day',
                    },
                    // dayGridMonth: {
                    //   type: 'dayGridMonth',
                    //   buttonText: 'Month',
                    //   editable: false,
                    //   eventStartEditable: false,
                    //   droppable: false,
                    // }
                  }
                }
                resourceOrder="-type1,type2"
                resourceAreaWidth="10%"
                resources={
                  selectedMember.length > 0
                    ? selectedMember.map((value: any) => {
                      return {
                        id: value.id,
                        title: value.firstName,
                      };
                    })
                    : []
                }
                slotMinWidth={45}
                slotEventOverlap={true}
                // slotLabelInterval={{
                //   hour: 1,
                // }}
                slotDuration={
                  currentViewType === 'week' ? "24:00:00" : null
                }
                slotLabelInterval={null} 
                aspectRatio={1.5}
                eventResize={eventDrop}
                resourceAreaHeaderContent={"Members"}
                initialView="resourceTimelineWeek"
                editable={true}
                displayEventEnd={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={calenderEvents}
                initialEvents={calenderEvents}
                eventDrop={eventDrop}
                eventContent={function (eventInfo: any) {
                  return (
                    <>
                      <div className="event-ticket" style={{width: "100%"}} id={eventInfo.event.id} title={eventInfo?.event.title}>
                        <span>
                          {/* {Moment(eventInfo.event?.startStr).format("LT") ??
                            ""}
                          <span> to </span>
                          {eventInfo.event?.end
                            ? Moment(eventInfo.event?.endStr).format("LT")
                            : Moment(eventInfo.event.startStr)
                              .add(1, "hours")
                              .format("LT")} */}
                          {(eventInfo?.event._def.extendedProps.travelingStartTime === null && eventInfo?.event._def.extendedProps.status !== "COMPLETED") &&
                            <div className="close-ticket-btn">
                              <i className="fa fa-close text-gray-600 text-hover-gray-700 fs-5" data-bs-toggle="tooltip" title="Cancel schedule" onClick={() => handleCancelEvent(eventInfo.event)}>
                              </i></div>}
                        </span>
                        {/* <div className="fw-bold">
                          Ticket ID : {eventInfo?.event.title}
                        </div> */}
                        <div>
                          <div className="fw-bold">Location</div>
                          {eventInfo?.event._def.extendedProps.location}
                        </div>
                        <div>
                          <div className="fw-bold">Issue Reported</div>
                          {eventInfo?.event._def.extendedProps.issue}
                        </div>

                        {eventInfo?.event._def.extendedProps.status !== 'COMPLETED' &&
                          <span onClick={() => {
                            copy(`${window.location.protocol + "//" + window.location.host}/job-order/${eventInfo.event._def.extendedProps.hashId}`, {
                              message: toast.success('Link Copied')
                            })
                          }}>
                            Job order : <i className="fa fa-copy text-gray-600 text-hover-gray-700" data-bs-toggle="tooltip" title="Copy to clipboard"></i>
                          </span>}
                      </div>
                    </>
                  );
                }}
                droppable={true}
                eventReceive={handleEventReceive}
              />
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 4000,
        }}
      />
    </>
  );
}
