
import _ from "lodash";
import Moment from "moment";

const MachineOverview = (props: { machines: any }) => {
  const { machines } = props;


  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9">Machine Overview</h3>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Person Deploying
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(
                      _.lowerCase(machines.deployingPerson?.firstName ?? "")
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Deployment Type
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(_.lowerCase(machines.deploymentType?.name ?? ""))}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Remark
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">{machines.remark}</span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Connection Status
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(_.lowerCase(machines.connectionStatus ?? ""))}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Deployment Date
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {Moment.unix(machines.deploymentDate / 1000)
                      .local()
                      .format("YYYY-MM-DD")}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Software Version
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.softwareVersion ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Mechanical Hardware Version
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.hardwareVersion ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Note related to mechanical hardware version
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.noteRelatedToMechanicalHardwareVersion ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Replace new tube doors -1mm shorter
                </label>
                <div className="col-lg-6 col-md-6 fv-row ps-0">
                  <span className="fw-bold fs-6 ms-2">
                    {machines.replaceNewTubeDoors1mmShorter === true ? (
                      <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                        Yes
                      </span>
                    ) : (
                      <span className="badge badge-light-danger fw-bolder me-auto px-4 py-2">
                        No
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Site Status
                </label>
                <div className="col-lg-6 col-md-6 fv-row ps-0">
                  <span className="fw-bold fs-6 ms-2">
                    {machines.isActive === true ? (
                      <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                        Active
                      </span>
                    ) : (
                      <span className="badge badge-light-danger fw-bolder me-auto px-4 py-2">
                        Inactive
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Blender
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.blender?.title ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Board
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.board?.title ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Cup Holder
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.cupHolder?.title ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Created At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {Moment.utc(machines.createdAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Created By
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.createdBy?.firstName ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Retired At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.retiredAt ? Moment.utc(machines.retiredAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm") : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Retired By
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.retiredBy?.firstName ?? ""}
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Last Sanitizer Replaced At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.lastSanitizerReplacedAt ? Moment.utc(machines.lastSanitizerReplacedAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm") : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Last Sanitize At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.lastSanitizeTime ? Moment.utc(machines.lastSanitizeTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm") : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Last Filter Replaced At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.lastFilterReplacedAt ? Moment.utc(machines.lastFilterReplacedAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm") : ""}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Last Heartbeat At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {machines.lastHearbeatSyncAt ? Moment.utc(machines.lastHearbeatSyncAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm") : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6">
        <div className="card mt-9 mx-xl-4">
          <div className="card-header cursor-pointer border-bottom-0 pt-4 pb-0 mb-0">
            <div className="card-title m-0">
              <h2>Other Info.</h2>
            </div>
          </div>
          <div className="card-body w-100 p-5 pt-0 mt-0">
            <div className="table-responsive fs-6 border-0 px-5 pt-0">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fs-5 fw-bold">
                    <th className="fs-5 text-muted">Title</th>
                    <th className="fs-5 text-muted">Info</th>
                    <th className="fs-5 text-muted">Last updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    machines?.metas?.map((meta: any, key: number) => (<tr key={'meta-' + key}>
                      <td>
                        {_.capitalize(_.toLower(meta.metaKey.replaceAll('_', ' ').trim()))}
                      </td>
                      <td>
                        {meta.metaValue === 'true' ? 'Yes' : meta.metaValue}
                      </td>
                      <td>
                        {Moment(meta?.updatedAt)
                          .local()
                          .format("YYYY-MM-DD HH:mm")}
                      </td>
                    </tr>))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineOverview;
