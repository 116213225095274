
import React, { useState, useRef } from "react";
import Moment from "moment";
import { useLocation } from 'react-router-dom';
import * as _ from "lodash";
import { getBlendLogs } from '../../../../models/_blendlogs'
import { KTSVG } from "../../../../../_metronic/helpers";
import clsx from "clsx";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactSelect from "react-select";
import { getLocations } from '../../../../models/_location'
import { Option } from '../../../../../_metronic/helpers/MySelect';


const BlendOverview = () => {
  const location = useLocation()
  const [addLoading, setAddLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const [locationDB, setLocationDB] = useState([])
  const [blendLogs, setBlendLogs] = React.useState([])
  const [locationSelected, setLocationSelected] = useState(null)
  const [filterLocation, setFilterLocation] = useState("")
  const [filterBlendDuration, setFilterBlendDuration] = useState("")
  const [filterFlavor, setFilterFlavor] = useState("")
  const [filterFeedback, setFilterFeedback] = useState("")
  const [filterThickness, setFilterThickness] = useState("")
  const [filterFromDate, setFilterFromDate] = useState("")
  const [filterToDate, setFilterToDate] = useState("")
  const resetfromDate = useRef<any>(null);
  const resetTo = useRef<any>(null);
  const machineId = location.pathname.split("/").reverse()[0];


  const blendData = async (filter: any) => {
    const blendReord = await getBlendLogs(filter)
    const blendResult = blendReord.data ? blendReord.data : [];
    setBlendLogs(blendResult)
  }
  const fetchData = async () => {
    const locationRecord = await getLocations();
    const locationResults: any = locationRecord?.data ? locationRecord?.data : [];
    locationResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    setLocationDB(locationResults)
  }

  const searchData = () => {
    let filter: any = {};
    if (filterLocation.length > 0) {
      filter["location_id"] = { in: filterLocation }
    }

    filter["machine_id"] = { eq: machineId };
    if (filterThickness) {
      filter["thickness"] = { like: "%" + filterThickness + "%" };
    }
    if (filterBlendDuration) {
      filter["blend_duration"] = { like: "%" + filterBlendDuration + "%" };
    }
    if (filterFeedback) {
      filter["feedback"] = { like: "%" + filterFeedback + "%" };
    }
    if (filterFlavor) {
      filter["flavor"] = { like: "%" + filterFlavor + "%" }
    }
    if (filterFromDate !== "" || filterToDate !== "") {
      filter["blend_date"] = {};
    }
    if (filterFromDate !== "" && filterToDate === "") {
      filter["blend_date"]["gt"] = new Date(
        filterFromDate
      ).toISOString();
    } else if (
      filterFromDate === "" &&
      filterToDate !== ""
    ) {
      filter["blend_date"]["lt"] = new Date(
        filterToDate
      ).toISOString();
    } else if (
      filterFromDate !== "" &&
      filterToDate !== ""
    ) {
      filter["blend_date"]["between"] = [
        new Date(filterFromDate).toISOString(),
        new Date(filterToDate).toISOString(),
      ];
    }
    setAddLoading(true);
    blendData(filter)
    setAddLoading(false);

  }
  const removeSearch = () => {
    let filter: any = {};
    filter["machine_id"] = { eq: machineId };
    setFilterLoading(true)
    resetfromDate.current.state.inputValue = "";
    resetTo.current.state.inputValue = "";

    setLocationSelected(null)
    setFilterLocation("")
    setFilterFlavor("")
    setFilterFeedback("")
    setFilterThickness("")
    setFilterBlendDuration("")
    setFilterFeedback("")
    setFilterFromDate("")
    setFilterToDate("")
    blendData(filter)
    setFilterLoading(false)
  }

  React.useEffect(() => {
    fetchData();
    searchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>

      <div className="row">

        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="fw-bolder card-label fw-bold fs-3">Blend Logs</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total Blend : {blendLogs.length ? blendLogs.length : 0}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-6 mx-xl-4">
            <div className="card-body w-100 p-5">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#blend-filter"
                aria-expanded="false"
                aria-controls="blend-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>

              <div className="collapse" id="blend-filter" aria-labelledby="blend-filter">
                <div className="card">
                  <div className="card-body w-100 p-1 px-2">
                    <div className="row mt-6">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">From Date</label>
                        <Datetime
                          ref={resetfromDate}
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={filterFromDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFilterFromDate(dt)
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">To Date</label>
                        <Datetime
                          ref={resetTo}
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          value={filterToDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFilterToDate(dt)
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">Location Name</label>

                        <ReactSelect
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          isMulti
                          name="filterLocation"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(selected: any) => {
                            const value = selected?.map((e: any) => e.value);
                            setLocationSelected(selected)
                            setFilterLocation(value)
                          }}
                          components={{
                            Option,
                          }}
                          value={locationSelected}
                          options={Array.from(locationDB).map(
                            (value: any) => {
                              return {
                                value: value.id,
                                label: value.name,
                              };
                            }
                          )}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Thickness
                        </label>
                        <input
                          autoComplete="off"
                          className={clsx("form-control")}
                          name="filterThickness"
                          value={filterThickness}
                          onChange={(event: any) => {
                            setFilterThickness(event.target.value)
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Blend duration
                        </label>
                        <input
                          autoComplete="off"
                          className={clsx("form-control")}
                          name="filterBlendDuration"
                          value={filterBlendDuration}
                          onChange={(event: any) => {
                            setFilterBlendDuration(event.target.value)
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Flavor
                        </label>
                        <input
                          autoComplete="off"
                          className={clsx("form-control")}
                          name="filterFlavor"
                          value={filterFlavor}
                          onChange={(event: any) => {
                            setFilterFlavor(event.target.value,)
                          }}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label fs-4 fw-bold">
                          Feedback
                        </label>
                        <input
                          autoComplete="off"
                          className={clsx("form-control")}
                          name="filterFeedback"
                          value={filterFeedback}
                          onChange={(event: any) => {
                            setFilterFeedback(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <button
                          type="submit"
                          onClick={() => searchData()}
                          className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                        >
                          {addLoading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!addLoading && (
                            <span className="indicator-label">ADD FILTER</span>
                          )}
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <button
                          type="submit"
                          onClick={() => removeSearch()}
                          className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                        >
                          {filterLoading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!filterLoading && (
                            <span className="indicator-label">RESET FILTER</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5 dashboardtable">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Machine Name</th>
                      <th className="fs-5 text-muted">Location Name</th>
                      <th className="fs-5 text-muted">Blend Date</th>
                      <th className="fs-5 text-muted">Thickness</th>
                      <th className="fs-5 text-muted">Blend Duration</th>
                      <th className="fs-5 text-muted">Flavor</th>
                      <th className="fs-5 text-muted">Feedback</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blendLogs.length > 0 ? (
                      Array.from(blendLogs).map((blendLogs: any, index) => (

                        <tr key={index}>
                          <td>
                              {blendLogs.machine.name ?? ""}
                          </td>
                          <td>
                            {_.capitalize(
                              _.lowerCase(blendLogs.location.name ?? "")
                            )}
                          </td>
                          <td>
                            {blendLogs.blendDate ? Moment.utc(blendLogs.blendDate).local().format("YYYY-MM-DD") : ""}</td>
                          <td>{blendLogs.thickness ?? ""}</td>
                          <td>{parseFloat(blendLogs.blendDuration).toFixed(2)}</td>
                          <td> {blendLogs.flavor ?? ""}</td>
                          <td>
                            {blendLogs.feedback}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={11}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlendOverview;
