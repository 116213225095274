import React, {useRef, useEffect, useState} from "react";
import * as d3 from "d3";

// Define the interface for raw data
interface RawDataPoint {
  hourOfTheDay: string;
  name: string;
  totalConsumption: string;
}

// Define the interface for transformed data
interface TransformedDataPoint {
  hourOfTheDay: string;
  [key: string]: number | string; // To accommodate varying names as keys
}

interface StackedBarChartProps {
  data: RawDataPoint[];
}

const FlavorConsumptionByTimeOfDayChart: React.FC<StackedBarChartProps> = ({
  data,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{width: number; height: number}>(
    {
      width: 0,
      height: 0,
    }
  );

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentWidth = svgRef.current.parentElement?.clientWidth ?? 1500;
        setDimensions({
          width: parentWidth - 60, // Adjust for margins
          height: 400,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current || !tooltipRef.current) return;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Transform the data
    const transformedData = Array.from(
      d3.group(data, (d) => d.hourOfTheDay),
      ([hourOfTheDay, values]) => {
        const result: any = {hourOfTheDay};
        values.forEach((d) => {
          if (d.name && d.totalConsumption) {
            result[d.name] = +d.totalConsumption; // Convert to number
          }
        });
        return result;
      }
    );

    // Ensure there is data to process
    if (transformedData.length === 0) {
      console.error("No data available for rendering.");
      return;
    }

    const {width, height} = dimensions;
    const margin = {top: 40, right: 0, bottom: 70, left: 60};

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the x and y scales
    const x = d3
      .scaleBand()
      .domain(transformedData.map((d) => d.hourOfTheDay))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(transformedData, (d) =>
          d3.sum(
            Object.values(d).filter((v) => typeof v === "number") as number[]
          )
        ) ?? 0,
      ])
      .nice()
      .range([height, 0]);

    // Define the color scale using the specified colors
    const colorScale = d3
      .scaleOrdinal<string>()
      .domain(
        Object.keys(transformedData[0]).filter((k) => k !== "hourOfTheDay")
      )
      .range(data.map((d: any) => d.flavorColorCode));

    // Add the X axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle") // Center-align the text
      .style("font-size", "12px"); // Adjust font size if needed

    // Add the Y axis
    svg.append("g").call(d3.axisLeft(y));

    // Add X axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 20)
      .attr("text-anchor", "middle")
      .text("Hour of the Day")
      .style("font-size", "12px");

    // Add Y axis label
    svg
      .append("text")
      .attr("x", -(height / 2))
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Total Consumption")
      .style("font-size", "12px");

    // Add chart title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .text("Flavor Consumption by Time of Day")
      .style("font-size", "16px")
      .style("font-weight", "bold");

    // Stack data
    const stack = d3
      .stack<TransformedDataPoint>()
      .keys(colorScale.domain())
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const series = stack(transformedData);

    // Create tooltip
    const tooltip = d3
      .select(tooltipRef.current)
      .style("position", "absolute")
      .style("background", "#fff")
      .style("border", "1px solid #ddd")
      .style("padding", "5px")
      .style("border-radius", "3px")
      .style("display", "none");

    // Add bars and text labels inside the bars
    svg
      .selectAll(".layer")
      .data(series)
      .enter()
      .append("g")
      .attr("class", "layer")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.hourOfTheDay) ?? 0)
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth())
      .on("mouseover", (event, d) => {
        const key = d3.select(event.currentTarget.parentNode).datum() as any;

        tooltip
          .style("display", "inline-block")
          .html(
            `Hour: ${d.data.hourOfTheDay}<br>Category: ${key.key}<br>Value: ${
              d[1] - d[0]
            }`
          );
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      });

    // Add text labels inside the bars, without decimals
    svg
      .selectAll(".layer")
      .selectAll("text")
      .data((d: any) => d)
      .enter()
      .append("text")
      .attr("x", (d: any) => (x(d.data.hourOfTheDay) ?? 0) + x.bandwidth() / 2)
      .attr("y", (d: any) => y(d[1]) + (y(d[0]) - y(d[1])) / 2)
      .attr("text-anchor", "middle")
      .text((d: any) => Math.round(d[1] - d[0])) // Remove decimal points
      .style("fill", "white")
      .style("font-size", "10px")
      .style("font-weight", "bold");

    // Add the legend
    const legend = svg
      .selectAll(".legend")
      .data(colorScale.domain())
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        (d, i) => `translate(${width + margin.right - 150}, ${i * 20})`
      );

    legend
      .append("rect")
      .attr("x", 0)
      .attr("width", 18)
      .attr("height", 8)
      .style("fill", colorScale);

    legend
      .append("text")
      .attr("x", 22)
      .attr("y", 4)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text((d) => d);
  }, [data, dimensions]);

  return (
    <>
      <svg ref={svgRef} style={{width: "100%", height: "auto"}} />
      <div ref={tooltipRef} />
    </>
  );
};

export default FlavorConsumptionByTimeOfDayChart;
