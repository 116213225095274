import { FC, useState, Suspense, useEffect } from "react";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useLazyLoadQuery } from "react-relay";
import toast, { Toaster } from "react-hot-toast";
import { LocationHistory_getLocationQuery } from "./ViewLocation/__generated__/LocationHistory_getLocationQuery.graphql";
import { getLocationById } from "./ViewLocation/LocationHistory";
import "react-datetime/css/react-datetime.css";
import {  updateLocationFlavorPrice } from "../../models/_location";

type Props = {
  currentLocationId: string;
  handleLocationFlavorPriceClose: () => void;
  locationUpdatePriceModal: boolean;
  refreshTable?: any;
};

export const UpdatePriceLocation: FC<Props> = ({
  currentLocationId,
  locationUpdatePriceModal,
  handleLocationFlavorPriceClose,
  refreshTable,
}) => {
  const navigate = useNavigate();
  if (!currentLocationId) {
    navigate("/locations/list");
  }
  const locationRecord = useLazyLoadQuery<LocationHistory_getLocationQuery>(
    getLocationById,
    {
      id: currentLocationId ?? "",
    },
    {
      fetchPolicy: "network-only",
    }
  );
  const locations: any = locationRecord?.getLocation ?? [];
    
  if (locations == null) {
    navigate("/locations/list");
  }
  const [loading, setLoading] = useState(false);
  const flavorPrices = locations.flavorPrices ?? [];
  let ttPrice = 0;
  let bbPrice = 0;
  let ggPrice = 0;
  let ppPrice = 0;
  let sbPrice = 0;
  let mmPrice = 0;
  flavorPrices.forEach((element: any) => {
    switch (element.flavor) {
      case "TT":
        ttPrice = element.price;
        break;
      case "GG":
        ggPrice = element.price;
        break;
      case "BB":
        bbPrice = element.price;
        break;
      case "PP":
        ppPrice = element.price;
        break;
      case "SB":
        sbPrice = element.price;
        break;
      case "MM":
        mmPrice = element.price;
        break;
    }
  });
 
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Suspense>
      <Modal
        open={locationUpdatePriceModal}
        onClose={() => handleLocationFlavorPriceClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            gg_price: ggPrice,
            bb_price: bbPrice,
            tt_price: ttPrice,
            pp_price: ppPrice,
            mm_price: mmPrice,
            sb_price: sbPrice
          }}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            
            const updateData: any = {
              id: locations.id,
              flavors: [
                {
                  flavor: 'TT',
                  price: values.tt_price
                },
                {
                  flavor: 'GG',
                  price: values.gg_price
                },
                {
                  flavor: 'BB',
                  price: values.bb_price
                },
                {
                  flavor: 'PP',
                  price: values.pp_price
                },
                {
                  flavor: 'MM',
                  price: values.mm_price
                },
                {
                  flavor: 'SB',
                  price: values.sb_price
                }
              ]
            };

            const result = await updateLocationFlavorPrice(updateData);
            if (result?.data?.data?.updateLocationFlavorPrice) {
              toast.success("Location flavor price updated successfully");
              setLoading(false);
              handleLocationFlavorPriceClose();
              resetForm();
              if (typeof refreshTable !== "undefined") {
                refreshTable();
              }
            } else {
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
          }) => (
            <form
              id="kt_modal_update_location_price_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1 mt-4">
                  Update location price- {locations?.uuid ?? ""}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Flavors
                    </label>

                    <table className="table table-boarderd">
                      <thead>
                        <tr>
                          <th className="fw-bold mb-0">Flavor</th>
                          <th className="fw-bold mb-0">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tropical Vibes</td>
                          <td>
                            <input
                              type="number"
                              name="tt_price"
                              id="tt_price"
                              className="form-control"
                              value={values.tt_price}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Brain Boost</td>
                          <td>
                            <input
                              type="number"
                              name="bb_price"
                              id="bb_price"
                              className="form-control"
                              value={values.bb_price}
                              onChange={handleChange}
                            />{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>Green Energizer</td>
                          <td>
                              <input
                                type="number"
                                name="gg_price"
                                id="gg_price"
                                className="form-control"
                                value={values.gg_price}
                                onChange={handleChange}
                              />{" "}
                            </td>
                        </tr>
                        <tr>
                          <td>Peanut Protein</td>
                          <td>
                              <input
                                type="number"
                                name="pp_price"
                                id="pp_price"
                                className="form-control"
                                value={values.pp_price}
                                onChange={handleChange}
                              />{" "}
                            </td>
                        </tr>
                        <tr>
                          <td>Magical Mocha</td>
                          <td>
                              <input
                                type="number"
                                name="mm_price"
                                id="mm_price"
                                className="form-control"
                                value={values.mm_price}
                                onChange={handleChange}
                              />{" "}
                            </td>
                        </tr>
                        <tr>
                          <td>Strawberry Banana</td>
                          <td>
                              <input
                                type="number"
                                name="sb_price"
                                id="sb_price"
                                className="form-control"
                                value={values.sb_price}
                                onChange={handleChange}
                              />{" "}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && <span className="indicator-label">UPDATE</span>}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
