/**
 * @generated SignedSource<<36a12bc148af50849d0379b8da9af09d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardCard_machinesCountByStatusQuery$variables = {};
export type DashboardCard_machinesCountByStatusQuery$data = {
  readonly machinesCountByStatus: {
    readonly commerciallyDeploy: number;
    readonly deployedMachines: number;
    readonly inRepair: number;
    readonly readyToDeploy: number;
  };
};
export type DashboardCard_machinesCountByStatusQuery = {
  response: DashboardCard_machinesCountByStatusQuery$data;
  variables: DashboardCard_machinesCountByStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachinesCountByStatusObjectType",
    "kind": "LinkedField",
    "name": "machinesCountByStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deployedMachines",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commerciallyDeploy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "readyToDeploy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inRepair",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardCard_machinesCountByStatusQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardCard_machinesCountByStatusQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a33ce4a4a89f3850d8b485999ad7d369",
    "id": null,
    "metadata": {},
    "name": "DashboardCard_machinesCountByStatusQuery",
    "operationKind": "query",
    "text": "query DashboardCard_machinesCountByStatusQuery {\n  machinesCountByStatus {\n    deployedMachines\n    commerciallyDeploy\n    readyToDeploy\n    inRepair\n  }\n}\n"
  }
};
})();

(node as any).hash = "7116ea34b46a78702479e973a5901ece";

export default node;
