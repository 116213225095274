/**
 * @generated SignedSource<<ff126f7bdb7a406fc7c7e93e7dad0ab6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardCard_totalTicketsCountQuery$variables = {};
export type DashboardCard_totalTicketsCountQuery$data = {
  readonly totalTicketsCount: {
    readonly completedTicketCount: number;
    readonly inProcessTicketCount: number;
    readonly onHoldTicketCount: number;
    readonly pendingInstallationLocationCount: number;
    readonly pendingTicketCount: number;
    readonly totalTicketCount: number;
  };
};
export type DashboardCard_totalTicketsCountQuery = {
  response: DashboardCard_totalTicketsCountQuery$data;
  variables: DashboardCard_totalTicketsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TicketCountObjectType",
    "kind": "LinkedField",
    "name": "totalTicketsCount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalTicketCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inProcessTicketCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onHoldTicketCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pendingTicketCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "completedTicketCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pendingInstallationLocationCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardCard_totalTicketsCountQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardCard_totalTicketsCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8bc65af1239f40835396617ac15b378a",
    "id": null,
    "metadata": {},
    "name": "DashboardCard_totalTicketsCountQuery",
    "operationKind": "query",
    "text": "query DashboardCard_totalTicketsCountQuery {\n  totalTicketsCount {\n    totalTicketCount\n    inProcessTicketCount\n    onHoldTicketCount\n    pendingTicketCount\n    completedTicketCount\n    pendingInstallationLocationCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c0da4fa4e98ed249c212556fb55e81d";

export default node;
