import React, { FC, Suspense, useState, useEffect } from 'react'
import { getTicketListById } from './ViewJob'
import { useLazyLoadQuery } from 'react-relay';
import Modal from 'react-responsive-modal';
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik, FieldArray } from "formik";
import { updateLogJob } from "../../models/_logjob";
import clsx from "clsx";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";
import { getMarketingMaterials } from "../../models/_marketingMaterial";
import * as yup from "yup";
import { ViewJob_getLogJobQuery } from './__generated__/ViewJob_getLogJobQuery.graphql'
import { getLocations } from "../../models/_location";

type Props = {
  editJobModal: boolean
  currentJobId: string
  handleCloseJob: () => void
  refreshTable?: any

}
const validationSchema = yup.object({
  jobType: yup.string().required("JobType is required"),
  machineName: yup.string().when("jobType", {
    is: "INSTALLATION",
    then: yup.string().required("Machine name is required"),
  }),
  machineSerialNumber: yup.string().when("jobType", {
    is: "INSTALLATION",
    then: yup.string().required("Serial Number is required"),
  }),
  locationId: yup.string().required("Location is required"),
  status: yup.string().required("Status is required"),
  // dateAndTime: yup.date().required("Start time is required"),
  travelTime: yup.number().when("status", {
    is: "COMPLETED",
    then: yup.number().required("Travel time is required"),
  }),
  // closingDateAndTime: yup.string().when("status", {
  //   is: "COMPLETED",
  //   then: yup.string().required("Closing time is required"),
  // }),
});
export const EditJobModal: FC<Props> = ({
  editJobModal,
  currentJobId,
  handleCloseJob,
  refreshTable,
}) => {
  const JobRecord = useLazyLoadQuery<ViewJob_getLogJobQuery>(
    getTicketListById, {
    id: currentJobId
  }, {
    fetchPolicy: "network-only",
  }
  )

  const job: any = JobRecord?.getLogJob ?? []
  const [loading, setLoading] = useState(false);
  const [resultImages, setResultImages] = useState<any>([]);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [locationDB, setLocationDB] = useState<any>([]);
  const [materialDB, setMaterialDB] = useState([]);
  const [locationName, setLocationName] = useState("")
  const [statusDB] = useState([
    {
      id: "PENDING",
      name: "Pending",
    },
    {
      id: "ON_HOLD",
      name: "On-hold",
    },
    {
      id: "IN_PROCESS",
      name: "In-Process",
    },
    {
      id: "COMPLETED",
      name: "Completed",
    },
  ]);
  const [jobTypeDB] = useState([
    {
      id: "SAMPLE_EVENT",
      name: "Sample event",
    },
    {
      id: "SITE_VISIT",
      name: "Site visit",
    },
    {
      id: "INSTALLATION_FOLLOW_UP",
      name: "Installation Follow-up",
    },
    {
      id: "INSTALLATION",
      name: "Installation",
    },
  ]);
  const [cleanlinessDB] = useState([
    {
      id: "GREAT",
      name: "Great",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "BAD",
      name: "Bad",
    },
  ]);
  const [ratesDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: any = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      const type = selectedFilesArray[i].type;
      getBase64(selectedFilesArray[i])
        .then((result: any) => {
          setSelectedImages((prevState: any) => {
            return [...prevState, { name: name, type: type, content: result }];
          });
          setResultImages((prevState: any) => {
            return [...prevState, { name: name, content: result }];
          });
        })
    }
    event.target.value = "";
  };

  function deleteHandler(image: any) {
    setSelectedImages(
      selectedImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
    setResultImages(
      resultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  }

  const fetchdata = async () => {
    try {
      const locationRecord = await getLocations();
      const locationResults: any = locationRecord?.data ? locationRecord?.data : [];

      locationResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      let location = "";
      for (const locationObj of locationRecord.data) {
        if (locationObj.id === job.locationId) {
          location = locationObj.name;
        }
      }
      setLocationName(location);

      const materialRecord = await getMarketingMaterials();
      const materialResult: any = materialRecord ? materialRecord : [];
      setMaterialDB(materialResult);
      setLocationDB(locationResults);

    } catch (error) {
      console.log(error);
    }
  };
  let endTime = "";
  if (job.closingDateAndTime) {
    endTime = Moment.unix(job?.closingDateAndTime / 1000)
      .local()
      .format("YYYY-MM-DD HH:mm:ss");
  }
  const marketingMaterialsCurrent = job?.marketingMaterials?.map((a: any) => a.id) ?? [];
  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Suspense>
        <Modal
          open={editJobModal}
          onClose={() => handleCloseJob()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              id: job.id,
              jobType: job.jobType ?? "",
              currentLocation: locationName,
              locationId: job.locationId ?? "",
              trafficRate: job.trafficRate ?? "",
              cleanliness: job.cleanliness ?? "",
              dateAndTime: Moment.unix(job?.dateAndTime / 1000)
                .local()
                .format("YYYY-MM-DD HH:mm:ss"),
              status: job.status ?? "",
              travelTime: job?.travelTime ?? "",
              uploadImagesOrVideos: job.uploadImagesOrVideos ?? [],
              customerSatisfaction: job.customerSatisfaction ?? "",
              staffEnthusiasm: job.staffEnthusiasm ?? "",
              sanitizerStatus: job.sanitizerStatus ?? "",
              machineSerialNumber: job.machineSerialNumber ?? "",
              machineName: job.machineName ?? "",
              additionalComments: job?.additionalComments ?? "",
              currentMarketingMaterial: marketingMaterialsCurrent,
              marketingMaterials: marketingMaterialsCurrent,



              closingDateAndTime: endTime,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              let hasErrors = false;
              if (!values.dateAndTime) {
                hasErrors = true;
                toast.error("Start time is required!");
              }
              if (values.status === "COMPLETED") {
                if (!values.closingDateAndTime) {
                  hasErrors = true;
                  toast.error("End time is required!");
                }
              }
              if (hasErrors) {
                return false;
              }
              let updateData: any = {
                id: values.id,
                jobType: values.jobType,
                locationId: values.locationId,
                status: values.status,
                dateAndTime: Moment(
                  values.dateAndTime
                )
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss"),
                machineSerialNumber: values.machineSerialNumber,
                machineName: values.machineName,
                additionalComments: values.additionalComments,
                marketingMaterials: values.marketingMaterials,
              }
              if (values.customerSatisfaction) {
                updateData["customerSatisfaction"] = values.customerSatisfaction;
              }
              if (values.cleanliness) {
                updateData["cleanliness"] = values.cleanliness;
              }
              if (values.trafficRate) {
                updateData["trafficRate"] = values.trafficRate;
              }
              if (values.staffEnthusiasm) {
                updateData["staffEnthusiasm"] = values.staffEnthusiasm;
              }
              if (values.sanitizerStatus) {
                updateData["sanitizerStatus"] = values.sanitizerStatus;
              }
              if (values.closingDateAndTime) {
                updateData["closingDateAndTime"] = Moment(
                  values.closingDateAndTime
                )
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              if (values.travelTime) {
                updateData["travelTime"] = values.travelTime;
              }
              if (selectedImages.length > 0) {
                updateData["uploadImagesOrVideos"] = resultImages;
              }
              setLoading(true);

              const result = await updateLogJob(updateData);
              setLoading(false);
              if (result?.data?.data?.updateLogJob) {
                toast.success("Job update successfully");
                setLoading(false)
                handleCloseJob()
                resetForm()
                if (typeof refreshTable !== 'undefined') {
                  refreshTable()
                }
              } else {
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
              }
            }}
          >

            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) =>
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate>

                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Edit Job - {job.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 required fw-bold ps-1">
                        Job Type
                      </label>
                      {Array.from(jobTypeDB).map((value: any, id) => (
                        <div className="orm-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 mb-2 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="jobType"
                              className="form-check-input"
                              type="radio"
                              name="jobType"
                              value={value.id}
                              checked={values.jobType === value.id}
                              onChange={() => setFieldValue("jobType", value.id)}
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="jobType" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Location
                      </label>
                      <Select
                        className={clsx("react-select", {
                          "is-invalid":
                            Boolean(errors.locationId) && touched.locationId,
                        })}
                        classNamePrefix="my-react-select"
                        name="locationId"
                        placeholder={locationName}
                        // value={{
                        //   label: values.currentLocation,
                        //   value: values.locationId,
                        // }}
                        options={Array.from(locationDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("locationId", e.value);
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="locationId" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Start Time
                      </label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        closeOnSelect
                        className={clsx("date-control", {
                          "is-invalid":
                            Boolean(errors.dateAndTime) && touched.dateAndTime,
                        })}

                        value={values.dateAndTime}
                        isValidDate={(current) => {
                          let customDate = Moment().format("YYYY-MM-DD");
                          return (
                            current && current <= Moment(customDate, "YYYY-MM-DD")
                          );
                        }}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("dateAndTime", dt);
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="dateAndTime" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        Additional Comments
                      </label>
                      <textarea
                        name="additionalComments"
                        id="additionalComments"
                        className="form-control"
                        value={values.additionalComments}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <div className="form-label fs-4 mb-3 required fw-bold">
                        Status
                      </div>
                      {Array.from(statusDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-2 ms-2">
                          <label className="ms-2 fs-5" key={id}>
                            <input
                              id="status"
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={value.id}
                              checked={values.status === value.id}
                              onChange={(event: any) => {
                                setFieldValue("status", event.target.value);
                              }}
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="status" />
                      </div>
                    </div>
                    {values.jobType === "INSTALLATION" && (
                      <>
                        <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                          <label className="form-label fs-4 required fw-bold">
                            Serial Number
                          </label>
                          <input
                            className={clsx("form-control", {
                              "is-invalid":
                                Boolean(errors.machineSerialNumber) &&
                                touched.machineSerialNumber,
                            })}
                            name="machineSerialNumber"
                            id="machineSerialNumber"
                            value={values.machineSerialNumber}
                            onChange={handleChange}
                          />
                          <div className="text-danger text-align-top-center mt-2">
                            <ErrorMessage name="machineSerialNumber" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                          <label className="form-label fs-4 required fw-bold">
                            Machine Name
                          </label>
                          <input
                            className={clsx("form-control", {
                              "is-invalid":
                                Boolean(errors.machineName) &&
                                touched.machineName,
                            })}
                            name="machineName"
                            id="machineName"
                            value={values.machineName}
                            onChange={handleChange}
                          />
                          <div className="text-danger text-align-top-center mt-2">
                            <ErrorMessage name="machineName" />
                          </div>
                        </div>
                      </>
                    )}


                    {values.status === "COMPLETED" && (
                      <>
                        <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                          <label className="form-label required fs-4 fw-bold">
                            Closing Date & Time
                          </label>
                          <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm"
                            closeOnSelect
                            className={clsx("date-control", {
                              "is-invalid":
                                Boolean(errors.closingDateAndTime) &&
                                touched.closingDateAndTime,
                            })}
                            value={values.closingDateAndTime}
                            onChange={(e: any) => {
                              const dt = e["_d"];
                              setFieldValue("closingDateAndTime", dt);
                            }}
                          />
                          <div className="text-danger text-align-top-center mt-2">
                            <ErrorMessage name="closingDateAndTime" />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                          <label className="form-label fs-4 required fw-bold">
                            Travel Time (Minutes)
                          </label>
                          <input
                            className={clsx("form-control", {
                              "is-invalid":
                                Boolean(errors.travelTime) && touched.travelTime,
                            })}
                            type="number"
                            value={values.travelTime}
                            name="travelTime"
                            id="travelTime"
                            onChange={handleChange}
                          />
                          <div className="text-danger text-align-top-center ms-1 mt-2">
                            <ErrorMessage name="travelTime" />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-12 mt-2 pt-2">
                      <div className="accordion">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            data-bs-toggle="tooltip"
                            title="Click here to add marketing materials used"
                            id="marketing"
                          >
                            <button
                              className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#marketing-purpose"
                              aria-expanded="false"
                              aria-controls="marketing-purpose"
                            >
                              Marketing Materials Used
                            </button>
                          </h2>
                          <div
                            id="marketing-purpose"
                            className="accordion-collapse collapse"
                            aria-labelledby="marketing"
                          >
                            <div className="accordion-body pb-1 p-3">
                              <div className="row">
                                <FieldArray name="marketingMaterial">
                                  {(arrayHelpers) => {
                                    return (
                                      <>
                                        {Array.from(materialDB).map(
                                          (value: any, index) => {
                                            return (
                                              <div className="col-lg-6 col-md-6 col-12">
                                                <label
                                                  key={value.id}
                                                  className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                                >
                                                  <input
                                                    id="marketingMaterials"
                                                    className="form-check-input mb-2 me-1"
                                                    type="checkbox"
                                                    name={value.id}
                                                    value={value.id}
                                                    defaultChecked={values.marketingMaterials?.includes(
                                                      value.id
                                                    )}
                                                    onChange={(e) => {
                                                      const { checked, name } =
                                                        e.target;
                                                      if (checked) {
                                                        setFieldValue(
                                                          "marketingMaterials",
                                                          [
                                                            ...values.marketingMaterials,
                                                            name,
                                                          ]
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          "marketingMaterials",
                                                          values.marketingMaterials.filter(
                                                            (e: any) => e !== name
                                                          )
                                                        );
                                                      }
                                                    }}
                                                  />


                                                  <span className="ms-2 mb-1 fs-5 form-check-label">
                                                    {value.name}
                                                  </span>
                                                </label>
                                              </div>
                                            );
                                          }
                                        )}
                                      </>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <div className="btn btn-bg-light fs-4 w-100 p-3 m-0 border">
                        <label
                          className="form-label fs-4 mb-0 text-dark"
                          style={{ cursor: "pointer" }}
                        >
                          Click here to upload images & videos
                          <input
                            id="upload"
                            type="file"
                            accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                            name="uploadImageVideo"
                            className="form-control d-none"
                            onChange={onSelectFile}
                          />
                        </label>
                      </div>
                      {values.uploadImagesOrVideos.length > 0 && (
                        <div className="shadow-none p-2 pt-3 flex-wrap d-flex">
                          {values.uploadImagesOrVideos.map(
                            (image: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  className="img-box m-2 rounded-1 justify-content-center"
                                >
                                  {image["type"] === "image/png" ||
                                    image["type"] === "image/jpeg" ||
                                    image["type"] === "image/webp" ||
                                    image["type"] === "image/jpg" ? (
                                    <img
                                      src={image["url"]}
                                      key={image}
                                      width="150"
                                      alt="Upload"
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                    />
                                  ) : (
                                    <video
                                      controls
                                      src={image["url"]}
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                      width="170"
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                      {selectedImages.length > 0 && (
                        <>
                          {selectedImages &&
                            selectedImages.map((image: any, index: any) => {
                              return (
                                <div
                                  key={image}
                                  className="img-box mt-3 ms-2 justify-content-center"
                                >
                                  {image["type"] === "image/png" ||
                                    image["type"] === "image/jpeg" ||
                                    image["type"] === "image/webp" ||
                                    image["type"] === "image/jpg" ? (
                                    <img
                                      src={image["content"]}
                                      key={index}
                                      width="150"
                                      alt="Upload"
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                    />
                                  ) : (
                                    <video
                                      controls
                                      src={image["content"]}
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                      width="170"
                                    />
                                  )}
                                  <button
                                    onClick={() => deleteHandler(image)}
                                    className="close-btn img-btn text-gray-700 text-hover-gray-800"
                                  >
                                    <i className="fa fa-close fs-5 pt-1" />
                                  </button>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-8">
                    <div className="separator separator-dashed mt-3"></div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                        Customer Satisfaction
                      </label>
                      {Array.from(ratesDB).map((value: any, index) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="customerSatisfaction"
                              value={value.id}
                              checked={values.customerSatisfaction === value.id}
                              onChange={() =>
                                setFieldValue("customerSatisfaction", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                        Cleanliness
                      </label>
                      {Array.from(cleanlinessDB).map((value: any, index) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="cleanliness"
                              value={value.id}
                              checked={values.cleanliness === value.id}
                              onChange={() =>
                                setFieldValue("cleanliness", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                        Traffic Rate
                      </label>
                      {Array.from(ratesDB).map((value: any, index) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="trafficRate"
                              value={value.id}
                              checked={values.trafficRate === value.id}
                              onChange={() =>
                                setFieldValue("trafficRate", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                        Staff Enthusiasm
                      </label>
                      {Array.from(ratesDB).map((value: any, index) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="staffEnthusiasm"
                              value={value.id}
                              checked={values.staffEnthusiasm === value.id}
                              onChange={() =>
                                setFieldValue("staffEnthusiasm", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 mt-2 pt-2">
                      <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                        Status Of Sanitizer
                      </label>
                      {Array.from(ratesDB).map((value: any, index) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={index}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="sanitizerStatus"
                              value={value.id}
                              checked={values.sanitizerStatus === value.id}
                              onChange={() =>
                                setFieldValue("sanitizerStatus", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>

                  </div>

                </div>
                <div className="modal-footer mt-6 p-0 justify-content-start">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">UPDATE</span>
                    )}
                  </button>
                </div>
              </form>}
          </Formik>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </div>
  )
}

export default EditJobModal
