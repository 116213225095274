import React, { useCallback, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";
import { getLocation } from "../../../../models/_logjob";
import { Link } from "react-router-dom";

const JobsOverview = (props: { logJobs: any }) => {
  const { logJobs } = props;
  const [locationName, setLocationName] = React.useState("");

  const fetchData = useCallback(async () => {
    for (const logjob of logJobs) {
      const locationRecord = await getLocation();
      const locationData = locationRecord.data ?? []
      let location = "";
      for (const locationObj of locationData) {
        if (locationObj.id === logjob.locationId) {
          location = locationObj.name;
        }
      }
      setLocationName(location);
    }
  }, [setLocationName, logJobs]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [fetchData]);

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Log Jobs</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total jobs : {logJobs.length ? logJobs.length : 0}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Job ID</th>
                      <th className="fs-5 text-muted">Location</th>
                      <th className="fs-5 text-muted">Job Type</th>
                      <th className="fs-5 text-muted">Status</th>
                      <th className="fs-5 text-muted">Start Time</th>
                      <th className="fs-5 text-muted">Created By</th>
                      <th className="fs-5 text-muted">Comments</th>
                      <th className="fs-5 text-muted">End Time</th>
                      <th className="fs-5 text-muted">Machine Name</th>
                      <th className="fs-5 text-muted">Serial Number</th>
                      <th className="fs-5 text-muted">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logJobs.length > 0 ? (
                      Array.from(logJobs).map((logJob: any, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Link
                                data-bs-toggle="tooltip"
                                title="Click here to view job"
                                to={`/job/list/viewjob/${logJob.id}`}
                              >
                                {logJob.uuid ?? ""}
                              </Link>
                            </td>
                            <td>{locationName}</td>
                            <td>
                              {_.capitalize(_.lowerCase(logJob.jobType ?? ""))}
                            </td>
                            <td>
                              {_.capitalize(_.lowerCase(logJob.status ?? ""))}
                            </td>
                            <td>
                              {logJob.dateAndTime ? Moment.unix(logJob.dateAndTime / 1000)
                                .local()
                                .format("YYYY-MM-DD HH:mm") : ""}
                            </td>
                            <td>{logJob.createdBy?.firstName ?? ""}</td>
                            <td>{logJob.additionalComments ?? ""}</td>
                            <td>
                              {logJob.closingDateAndTime
                                ? Moment.unix(logJob.closingDateAndTime / 1000)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm")
                                : ""}
                            </td>
                            <td>{logJob.machineName ?? ""}</td>
                            <td>{logJob.machineSerialNumber ?? ""}</td>
                            <td>
                              {Moment.utc(logJob.createdAt)
                                .local()
                                .format("YYYY-MM-DD HH:mm")}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td
                            align="center"
                            className="text-center align-align-items-center justify-content-center"
                            colSpan={13}
                          >
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsOverview;
