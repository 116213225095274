/**
 * @generated SignedSource<<431b907ef3f663090b9a5bf23008079c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardTable_getLocationsWithMachineCountsQuery$variables = {};
export type DashboardTable_getLocationsWithMachineCountsQuery$data = {
  readonly getLocationsWithMachineCounts: ReadonlyArray<{
    readonly createdAt: any | null;
    readonly createdBy: {
      readonly firstName: string;
      readonly id: string;
    } | null;
    readonly customer: {
      readonly id: string;
      readonly name: string;
      readonly uuid: string | null;
    } | null;
    readonly customerId: string;
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
};
export type DashboardTable_getLocationsWithMachineCountsQuery = {
  response: DashboardTable_getLocationsWithMachineCountsQuery$data;
  variables: DashboardTable_getLocationsWithMachineCountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationsWithMachineCounts",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerObjectType",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserObjectType",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardTable_getLocationsWithMachineCountsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardTable_getLocationsWithMachineCountsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "06a94ce97c3bfe9719fc6484a8a76da0",
    "id": null,
    "metadata": {},
    "name": "DashboardTable_getLocationsWithMachineCountsQuery",
    "operationKind": "query",
    "text": "query DashboardTable_getLocationsWithMachineCountsQuery {\n  getLocationsWithMachineCounts {\n    id\n    uuid\n    customerId\n    customer {\n      id\n      uuid\n      name\n    }\n    name\n    createdAt\n    createdBy {\n      id\n      firstName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5377dfe3e3b95ffa0911759473f1b18f";

export default node;
