/**
 * @generated SignedSource<<bc0c4246087f1301cfb1b8654ace1322>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardCard_getCountsQuery$variables = {};
export type DashboardCard_getCountsQuery$data = {
  readonly getCounts: {
    readonly totalCustomerCount: number;
    readonly totalLocationCount: number;
    readonly totalMachineCount: number;
  };
};
export type DashboardCard_getCountsQuery = {
  response: DashboardCard_getCountsQuery$data;
  variables: DashboardCard_getCountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DashboardObjectType",
    "kind": "LinkedField",
    "name": "getCounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCustomerCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalLocationCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalMachineCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardCard_getCountsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardCard_getCountsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "19844543fd53110b9b3d130cfd7d4e1c",
    "id": null,
    "metadata": {},
    "name": "DashboardCard_getCountsQuery",
    "operationKind": "query",
    "text": "query DashboardCard_getCountsQuery {\n  getCounts {\n    totalCustomerCount\n    totalLocationCount\n    totalMachineCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "68b6353e6a999aa96e24c3785c9964bf";

export default node;
