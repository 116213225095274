

import ReactApexChart from "react-apexcharts";
import React from "react";
import { ResponsiveContainer } from "recharts";

const OperationsChart = (props: any) => {
  const { operationResult } = props;
  const data =operationResult.map((e:any) =>(e.count) ) ?? []
   const name=operationResult.map((e:any) =>(e.name) ) ?? []
const series =[{
  data:data
}]
var options :any= {
 
  chart: {
  type: 'bar',
  height: 380
},
plotOptions: {
  bar: {
    barHeight: '100%',
    distributed: true,
    horizontal: true,
    dataLabels: {
      position: 'bottom'
    },
  }
},
colors: ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
  '#f48024', '#69d2e7'
],
dataLabels: {
  enabled: true,
  textAnchor: 'start',
  style: {
    colors: ['#fff']
  },
  formatter: function (val:any, opt:any) {
    return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
  },
  offsetX: 0,
  dropShadow: {
    enabled: true
  }
},
stroke: {
  width: 1,
  colors: ['#fff']
},
xaxis: {
  categories:name,
  labels: {
    style: {
      colors: 'var(--kt-dark)'
    }
  }
},
yaxis: {
  labels: {
    show: false
  }
},

tooltip: {
  theme: 'light',
  x: {
    show: false
  },
  y: {
    title: {
      formatter: function () {
        return ''
      }
    }
  }
}
};
  return (
    <>
      <div id="chart">
      <ResponsiveContainer 
            width={"100%"}
            height={400}
            className="text-center"
          >
    <ReactApexChart options={options}    series={series} type="bar" height={350} />
    </ResponsiveContainer>
   </div>
      
    </>
  );
};
export default OperationsChart;