import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { AnalyticDashboard_getQuickSightDashboardURLQuery } from "./__generated__/AnalyticDashboard_getQuickSightDashboardURLQuery.graphql";

export const getDashboardUrl = graphql`
  query AnalyticDashboard_getQuickSightDashboardURLQuery {
    getQuickSightDashboardURL
  }
`;

export const AnalyticDashboard = () => {
  const [dashboard, setDashboard] = useState("");
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState('750px')

  function getCookie(cname: any) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  const handleResize = () => {
    //contentWindow?.document
    const iframeHeightNew = iFrameRef?.current?.contentWindow?.document.scrollingElement?.scrollHeight;
    setIframeHeight(iframeHeightNew +' px')
    // const contentHeight = Math.max(
    //   body.clientHeight,
    //   body.offsetHeight,
    //   body.scrollHeight,
    //   documentElement.clientHeight,
    //   documentElement.offsetHeight,
    //   documentElement.scrollHeight
    // );
    // if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
  };
  
  const onLoad = () => {
    // $("#dashboard").addClass('table-spinner');
    iFrameRef?.current?.contentWindow?.addEventListener('resize', handleResize);
    handleResize();
    // $("#dashboard").removeClass('table-spinner');
  }

  const dashboardUrl = useLazyLoadQuery<AnalyticDashboard_getQuickSightDashboardURLQuery>(
    getDashboardUrl,
    {}
  );

  const fetchData = async () => {
    try {
      let url = getCookie("dashboard_url");
      
      if (url !== "" && url !== undefined && url !== null) {
        setDashboard(url);
      } else {
         setDashboard(dashboardUrl?.getQuickSightDashboardURL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <iframe id="dashboard" title="Dashboard" src={dashboard} className="w-100 dashboard-frame" height={iframeHeight} ref={iFrameRef} onLoad={onLoad}></iframe>
    </div>
  );
};
